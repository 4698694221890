
import React from 'react'
import { enable } from '../../utils/Icons'
import { SlttHelp2 } from '../../utils/SLTTHelp2'
import './TRLSettingsIcon.css'

interface TRLSettingsIconProps {
    onClick: () => void
    tooltip: string
    enabled: boolean
}

export const TRLSettingsIcon = ({ onClick, tooltip, enabled }: TRLSettingsIconProps) => {
    return (
        <SlttHelp2 id="translation-resource-library-authoring.html#thumbnail" tooltip={tooltip} place="bottom" video="aTODO">
            <span className={enable(`sl-trl-thumbnail-button sl-fa-button fas fa-folder-tree`, enabled)} data-toggle="tooltip" onClick={e => enabled && onClick && onClick()}>
            </span>
        </SlttHelp2>
    )
}

