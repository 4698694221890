import React, { FC, ReactNode, useState } from "react"
import ReactTooltip from "react-tooltip"
import Player from "./Player"

import { videoTooltipIds } from "./videoTooltipIds"

/*
 * videoTooltipId has the form '<SL name> <id>|<video url>'
 * e.g. "IS/ASL a2|SLTTLoc/220707_214913/221229_222855/221229_222856-1"
 */
function parseId(videoTooltipId: string) {
    let parts = videoTooltipId.split('|')
    let pieces = parts[0].split(' ')
    return { language: pieces[0], video: pieces[1], videoId: parts[1] }
}

interface IVideoTooltip {
    id: string,
    setVideoTooltipUrl: (videoTooltipUrl: string) => void,
}

const VideoTooltip: FC<IVideoTooltip> = ({ id, setVideoTooltipUrl }) => 
{
    let { language, videoId } = parseId(id)

    return (
        <button className="video-tooltip-button" 
                onClick={() => { setVideoTooltipUrl(videoId) }}>
            {language}
        </button>
    )

}

interface IVideoTooltips {
    video: string,
    setVideoTooltipUrl: (videoTooltipUrl: string) => void,
}

const VideoTooltips: FC<IVideoTooltips> = ({ video, setVideoTooltipUrl }) => 
{
    let ids = videoTooltipIds.filter(v => parseId(v).video === video)
    if (!ids.length) {
        return null
    }
    return (
        <span>
            {ids.map(id => (<VideoTooltip {...{ id, key: id, setVideoTooltipUrl }} />))}
        </span>
    )
}

/**
 * In order to get the link to the help system to work when SlttHelpTooltip
 * was used in a <MenuItem> I had to change the <a> to call this function
 * on click. The normal click handler was disabled (somehow!?) by the
 * MenuItem.
 */
const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

interface ISLTTHelpTooltip2 {
    tooltip: string,
    id: string,
    video: string, // e.g. 'a2'
    setVideoTooltipUrl: (videoTooltipUrl: string) => void,
}

const SLTTHelpTooltip2: FC<ISLTTHelpTooltip2> = ({ tooltip, id, video, setVideoTooltipUrl }) => 
{
    return (
        <ReactTooltip id={id}
                backgroundColor="gray"
                effect="solid"
                delayShow={1500}
                delayHide={500}
                clickable={true} >
            {tooltip}
            {id.includes('.html') && <a onClick={(e) => openInNewTab(`https://s3.amazonaws.com/sltt-help/${id}`)} target="_blank">
                <span className={'sl-help-icon fas fa-question-circle'} />
            </a>}
            <VideoTooltips {...{ video, setVideoTooltipUrl }} />
        </ReactTooltip>
    )
}

interface IVideoTooltipPlayer {
    videoTooltipUrl: string,
    setVideoTooltipUrl: (videoTooltipUrl: string) => void,
}

const VideoTooltipPlayer : FC<IVideoTooltipPlayer > = ({ videoTooltipUrl, setVideoTooltipUrl, }) => 
{
    return (
        <div>
            <Player
                className="video-tooltip-player"
                videoUrl={videoTooltipUrl}
                onEnded={() => setVideoTooltipUrl('')} />
        </div>
    )
}

interface ISLTTHelp2 {
    tooltip: string,
    id: string,
        // This serverse two purpose.
        // It uniquely identifies the element that we are applying the tooltip to
        // so that the control can tie the tooltip to the element.
        // If the id contains '.html' it is also a url to the related Help file topic.

    children: ReactNode,
    place: string, // 'bottom', 'top'
    video: string, // This is the 'a1'... part from the passage name in SLTTLoc which
        // ties this element to the SL video tooltips
}

export const SlttHelp2: FC<ISLTTHelp2> = ({ tooltip, id, children, place, video }) =>
{
    let [videoTooltipUrl, setVideoTooltipUrl] = useState('')
    if (!tooltip) {
        return null
    }

    let _id = id ? `details/${id}` : ''
    return (
        <span data-tip data-for={_id} data-place={place ?? ''}>
            { children }
            {!videoTooltipUrl  && <SLTTHelpTooltip2 {...{ id: _id, tooltip, video, setVideoTooltipUrl } } />}
            {videoTooltipUrl && <VideoTooltipPlayer {...{ videoTooltipUrl, setVideoTooltipUrl }} />}
        </span>
    )
}

