import React from 'react';
import { t } from 'ttag';
import { CancelButton, EditButton, OKButton } from './Buttons';
import { SlttHelp2 } from './SLTTHelp2';
import './ToggleEditor.css'
import { displayError } from './Errors'

export interface IToggleEditor {
    allowEditing: boolean;
    hideEditorControls?: boolean,
    isEditing: boolean;
    setIsEditing: (editing: boolean) => void;
    title: string;
    iconClass: string;
    enableClickOk?: boolean;
    handleClickOk: () => void;
    handleCancel: () => void;
    children: any;
    help?: {
        id: string,
        tooltip: string,
        video: string,
    };
}
export function ToggleEditor(props: IToggleEditor) {
    const {
        allowEditing,
        hideEditorControls,
        isEditing,
        setIsEditing,
        title,
        iconClass,
        enableClickOk = true,
        handleClickOk,
        handleCancel,
        help
    } = props;
    return (
        <div className="toggle-editor-container">
            <div title={title} className={`toggle-editor editing-${isEditing && !hideEditorControls}`}>
                <span className={iconClass} />
                {props.children}
                {!hideEditorControls && isEditing && <div className="toggle-editor-buttons">
                    <OKButton
                        className="ok-button"
                        buttonClassName=""
                        enabled={enableClickOk}
                        tooltip={t`Confirm`}
                        onClick={async () => {
                            try {
                                await handleClickOk()
                                setIsEditing(false)
                            } catch (error) {
                                displayError(error)
                            }
                        }} />
                    <CancelButton
                        enabled={true}
                        onClick={() => { setIsEditing(false); handleCancel(); }}
                        className='cancel-button'
                        tooltip={t`Cancel`} />
                </div>}
                {!hideEditorControls && !isEditing && <EditButton
                    enabled={allowEditing}
                    onClick={() => setIsEditing(true)}
                    className='toggle-editor-edit-button'
                    tooltip={t`Edit`} />}
            </div>
            {help && <div className="toggle-editor-help-button">
                <SlttHelp2 {...help} place="top">
                    <a href={`https://s3.amazonaws.com/details/${help.id}`} target="_blank">
                        <span className="far fa-question-circle"/>
                    </a>
                </SlttHelp2>
            </div>}
        </div>
    );
}


ToggleEditorConfig.defaultProps = {
    initialStates: { isEditing: false },
    hideEditorControls: false,
}

export interface IToggleEditorOptions {
    hideEditorControls: boolean,
    initialStates: IInitialToggleEditorStates,
    value: string,
    onChangedValue: (value: string) => void,
}

export const ToggleEditorChangeHandlerContext = React.createContext<IToggleEditorOptions>(
    { ...ToggleEditorConfig.defaultProps, value: '', onChangedValue: () => {}}
)

interface IInitialToggleEditorStates {
    isEditing: boolean,
}

interface IToggleEditorChangeHandler extends IToggleEditorOptions {
    children: any,
    initialStates: IInitialToggleEditorStates,
    hideEditorControls: boolean,
    value: string,
    onChangedValue: (value: string) => void,
}

export function ToggleEditorConfig(props: IToggleEditorChangeHandler) {    
    const { initialStates, hideEditorControls, value, onChangedValue } = props
    return <ToggleEditorChangeHandlerContext.Provider value={
        { 
            hideEditorControls,
            initialStates,
            value,
            onChangedValue,
        }
    }>{props.children}</ToggleEditorChangeHandlerContext.Provider>;
}
