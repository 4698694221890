import { openDB, deleteDB, IDBPDatabase } from 'idb'

import { VideoCacheRecord, VideoBlob } from './VideoCacheRecord'
import { VideoCacheUploader } from './VideoCacheUploader'
import { VideoCacheDownloader, IVideoDownloadQuery } from './VideoCacheDownloader'
import { _LevelupDB } from './_LevelupDB'
import { systemError } from '../components/utils/Errors'
import { t } from 'ttag'
import { disconnectFromLANStorage, getAuthorizedStorageProjects, isSlttAppStorageEnabled, listVideoCacheRecordFiles, retrieveAllBlobIds, retrieveVideoCacheRecords, storeBlob, storeVideoCacheRecord } from './SlttAppStorage'

import _debug from "debug";import { delay } from '../components/utils/delay'
 let log = _debug('sltt:VideoCache')

// flag to allow disabling implicit downloads when debugging
let disableImplicit = localStorage.getItem('disableImplicit')


// VideoCache acts as a singleton cache to store all local videos.
// This includes videos that have been downloaded from S3
// and videos waiting to be uploaded to S3.



export class VideoCache {
    static VIDEOBLOBS = 'videoBlobs'
    static CACHEDVIDEOS = 'cachedVideos'

    static capacity = 50   // max capacity in Gig
    static _db:IDBPDatabase<unknown>
    static videoCacheUploader = new VideoCacheUploader(systemError)
    static videoCacheDownloader = new VideoCacheDownloader(systemError)

    static async initialize() {
        if (VideoCache._db) {
            await VideoCache.syncCache()
            return
        }

        await VideoCache.checkForCacheResetRequest()

        VideoCache._db = await openDB('VideoCache', 1, {
            upgrade(db) {
                db.createObjectStore(VideoCache.VIDEOBLOBS, {
                    keyPath: '_id',
                    autoIncrement: true,
                })
                db.createObjectStore(VideoCache.CACHEDVIDEOS, {
                    keyPath: '_id',
                    autoIncrement: true,
                })
            },
        });

        await VideoCache.syncCache();

        (window as any).VideoCache = VideoCache

        //await VideoCache.deleteAll()
    }

    /**
     * In general:
     * run VideoCache.getAllVcrs() to get all vcrs and then for each vcr
     * do VideoCacheRecord.updateCache(vcr)
     * 
     * When isSlttAppStorageEnabled() store vcrs to disk (or load them from disk when vcrs.length === 0)
     */
    private static async syncCache() {
        const vcrs = await VideoCache.getAllVcrs()
        if (vcrs.length === 0 && isSlttAppStorageEnabled()) {
            // TODO: instead of checking vcrs.length === 0, we could re-work this so 
            // we check if vcrs for current project are 0, if so, load from disk per-project
            // That would improve load times for users with many videos across projects AND
            // we wouldn't need to have inside knowledge about the filename structure
            // for getting project name like `filename.split('__')[0]`
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            // try to load vcrs from disk
            log('loading vcrs from disk...')
            const vcrFilenames = await listVideoCacheRecordFiles({ project: '' /* all projects */ }, 'VideoCache.initialize')
            for (const vcrFilename of vcrFilenames.filter(filename => authorizedStorageProjects.has(filename.split('__')[0]))) {
                const vcrDocs = await retrieveVideoCacheRecords({ filename: vcrFilename }, 'VideoCache.initialize')
                for (const vcrDoc of Object.values(vcrDocs!)) {
                    const vcr = new VideoCacheRecord(vcrDoc)
                    await vcr.saveToDB(undefined, true)
                }
            }
        } else {
            isSlttAppStorageEnabled() && log('saving vcrs to disk...')
            for (let vcr of vcrs) {
                VideoCacheRecord.updateCache(vcr)
                if (isSlttAppStorageEnabled()) {
                    const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
                    if (authorizedStorageProjects.has(vcr.projectName())) {
                        try {
                            // easiest way to make sure vcrs are up to date with the cache
                            await storeVideoCacheRecord({ videoCacheRecord: vcr, batchMaxSize: 1000, batchMaxTime: 1000 }, 'VideoCache.initialize')
                        } catch (error) {
                            log('error saving vcr to disk', error)
                            disconnectFromLANStorage()
                        }
                    }
                }
            }
            if (isSlttAppStorageEnabled()) {
                log('saving missing blobs to disk...')
                // find any blobs that are in the browser cache but not in the sltt app storage
                const browserCachedBlobIds = await VideoCache.getAllVideoBlobIds()
                const allStoredBlobIds = await retrieveAllBlobIds('VideoCache.initialize')
                const allStoredBlobIdsSet = new Set(allStoredBlobIds)
                const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
                const missingBlobIds = browserCachedBlobIds.filter(blobId => !allStoredBlobIdsSet.has(blobId) && authorizedStorageProjects.has(blobId.split('/')[0]))
                log('saving missing blobs to disk...', { missingBlobIds, allStoredBlobIds, browserCachedBlobIds })
                // now save missing blobs to sltt app storage
                // this could take some time depending on how long its been disconnected from the storage
                try {
                    const vbs = await VideoCache.getRecordsForKeys(missingBlobIds)
                    for (const vb of vbs) {
                        await storeBlob({ blobId: vb._id, blob: vb.blob }, 'VideoCache.initialize')
                    }
                } catch (error) {
                    log('error saving blobs to disk', error)
                    disconnectFromLANStorage()
                }
            }
        }
    }

    static async checkForCacheResetRequest() {
        if (localStorage.getItem('cacheResetRequest') !== 'YES') return

        localStorage.setItem('cacheResetRequest', '')

        try {
            await deleteDB('VideoCache')
            alert('VideoCache reset DONE')
        } 
        catch(error) {
            alert('VideoCache reset FAILED')
        }
    }

    // User this when user has explicitly requeted to display a video.
    // It will be scheduled to download with high priority.
    // You can call this repeated with a short delay to get updated information
    // about the download status of the video ... and when the download completes
    // a blob containing the video.
    static async queryVideoDownload(videoUrl: string): Promise<IVideoDownloadQuery> {
        return await this.videoCacheDownloader.queryVideoDownload(videoUrl)
    }

    static async getVideoDownload(videoUrl: string): Promise<Blob> {
        log('getVideoDownload', videoUrl)

        while (true) {
            let result = await this.videoCacheDownloader.queryVideoDownload(videoUrl)
            if (result.blob) return result.blob
            log(result.message)
            await delay(1000)
        }
    }

    // Return a sorted list of scheduled downloads for debugging purposes
    static queryScheduledDownloads() {
        return this.videoCacheDownloader.queryScheduledDownloads()
    }

    static async queryProgress(videoUrl: string) {
        return await this.videoCacheDownloader.getProgress(videoUrl)
    }

    // Use this when the user has done something that makes it reasonably likely
    // they are going to want to access this video in the near future.
    // Video will be scheduled for downloading but at a low priority.
    // Returns true if we know that the requested video has already been downloaded.
    static async implicitVideoDownload(videoUrl: string) {
        if (disableImplicit) return false
        
        return await this.videoCacheDownloader.implicitVideoDownload(videoUrl)
    }

    /**
     * Return status of upload for this video.
     * Empty string means video did not need uploading or uploading complete.
     */
    static async queryVideoUpload(_id: string): Promise<string> {
        let vcr = await VideoCacheRecord.get(_id)
        
        let { uploadeds, error } = vcr

        if (uploadeds.length === 0) return ''
        
        let totalUploaded = uploadeds.filter(Boolean).length
        if (totalUploaded === uploadeds.length) return ''

        if (error) return error

        let message = `Uploaded ${totalUploaded} of ${uploadeds.length} ...`
        if (totalUploaded === 0) message = 'Waiting to upload ...'

        return message
    }

    static async accept(_id: string, creationDate: string) {
        let vcr = await VideoCacheRecord.get(_id)
        
        // Every -id should end in -n to tell us how many S3 items
        // for this video, if not we cannot download ig
        let seqNum = vcr.seqNum(_id)
        if (seqNum <= 0) {
            await vcr.setInvalidSeqNumError(_id)
            return
        }

        if (vcr.uploadeds.length) {
            if (!vcr.uploaded) {
                VideoCache.videoCacheUploader.postMessage({ func: 'upload', _id: vcr._id })
            }
            return
        }

        // If this video was created recently but not downloaded yet,
        // schedule it to be downloaded at a low priority.
        if (!vcr.downloaded) {
            let dt = new Date()
            dt.setDate(dt.getDate() - 7) // 7 days before today
            if (new Date(creationDate) >= dt) {
                VideoCache.implicitVideoDownload(_id)
            }

            return
        }
    }

    /**
     * @return {true} iff there is a record present in the cache for this id.
     *    WARNING the precense of a cache record does not mean that the item
     *    has finished uploading or downloding.
     */
    static async isPresent(_id: string) {
        let vcr = await VideoCacheRecord.get(_id)
        return vcr
    }

    // If this video has already been downloaded, return it as a blob.
    // Otherwise return null.
    static async getVideoBlob(_id: string): Promise<Blob | null> {
        log(`getVideoBlob ${_id}`)
        let vcr = await VideoCacheRecord.get(_id)

        // Update the access date. Least recently used unlocked blobs are
        // deleted when necessary to make room for more recently accessed blobs.
        await vcr.touch()

        if(!vcr.hasBeenCached) return null

        return await vcr.getVideoBlob()
    }

    /*
     *    This is useful from the console to download a video you want to inspect.
     *    For example to get the video for the 2nd video in the currently selected passage
     * 
     *        let url = _.rt.passage.videos[1].url
     *        window.VideoCache.downloadVideoBlob(url).catch(console.log)
     */
    static async downloadVideoBlob(_id: string) {
        log(`downloadVideoBlob ${_id}`)
        let vcr = await VideoCacheRecord.get(_id)
        if (!vcr.hasBeenCached) {
            log('not found in cache')
            return
        }

        let blob = await vcr.getVideoBlob()

        let href = window.URL.createObjectURL(blob!)
        let link = document.createElement('a')
        link.setAttribute('href', href)
        link.setAttribute('download', `temp.mp4`)
        link.click()
    }

    /**
     * Request video. Move it to front of download queue if it has not already been downloaded.
     * @return - true iff already downloaded.
     */ 
    // static async requestVideo(_id: string): Promise<boolean> {
    //     log(`requestVideoDownload ${_id}`)

    //     let vcr = new VideoCacheRecord({ _id })
    //     await vcr.loadFromDB('VideoCache requestVideo')
    //     if (vcr.uploadeds.length === 0) {
    //         await vcr.setupDownload()
    //     }

    //     if (vcr.hasBeenCached) {
    //         return true // video already present in cache
    //     }

    //     return false
    // }

    static async copyFileToVideoCache(file: Blob, baseUrl: string, creationDate?: string, requestUpload?: boolean) {
        if (!creationDate) {
            creationDate = _LevelupDB.getDate()
        }

        let uploadSingleFile = false
        if (uploadSingleFile) {
            return await VideoCache.copySingleFileToVideoCache(file, baseUrl, creationDate, requestUpload)
        } else {
            return await VideoCache.copyMultipartFileToVideoCache(file, baseUrl, creationDate, requestUpload)
        }
    }

    private static async copySingleFileToVideoCache(file: Blob, baseUrl: string, creationDate?: string, requestUpload?: boolean) {
        let _id = `${baseUrl}-1`
        log('copyFileToVideoCache', requestUpload, file.size, _id)

        let vcr = await VideoCacheRecord.get(_id)

        // Project images may have been previously uploaded. Start over.
        // Other uploads contain unique names so the following line does nothing.
        vcr.uploadeds = []

        vcr.size = file.size
        await vcr.addBlob(file)

        let uploadSuccess = await VideoCache.didUploadSucceed(vcr._id)
        if (!uploadSuccess) {
            throw Error(t`Could not read file. If copying from Dropbox or Google Drive try making a local copy first.`)
        }

        // For videos and images which are referenced in Project the upload is requested
        // when the referencing DB record is accepted.
        // For project images however there is no DB record in the local DB.
        // For those entries we set requestUpload to trigger the upload.
        if (requestUpload) {
            VideoCache.videoCacheUploader.postMessage({ func: 'upload', _id: vcr._id })
        }

        log('copyFileToVideoCache DONE')
        return _id
    }

    private static async copyMultipartFileToVideoCache(file: Blob, baseUrl: string, creationDate?: string, requestUpload?: boolean) {
        const blockSize = 4 * 1024 * 1024
        let blobsCount = Math.floor(((file.size-1) / blockSize) + 1)
        let _id = `${baseUrl}-${blobsCount}`
        log('copyFileToVideoCache', requestUpload, file.size, _id)

        let offset = 0
        let seqNum = 1
        let vcr = await VideoCacheRecord.get(_id)

        // Project images may have been previously uploaded. Start over.
        // Other uploads contain unique names so the following line does nothing.
        vcr.uploadeds = []

        vcr.size = file.size

        const userMessageGoogleDriveOrDropbox = t`Could not read file. If copying from Dropbox or Google Drive try making a local copy first.`

        while (offset < file.size) {
            let endOffset = offset + blockSize
            if (endOffset > file.size) endOffset = file.size

            let blob = file.slice(offset, endOffset, file.type)
            // TODO: possibly regenerate the blob here instead inside VideoBlob.saveToDB()
            try {
                await vcr.addBlob(blob)
            } catch (error) {
                throw Error(userMessageGoogleDriveOrDropbox)
            }

            offset += blockSize
            seqNum = seqNum + 1
        }

        await vcr.saveToDB()

        let uploadSuccess = await VideoCache.didUploadSucceed(vcr._id)
        if (!uploadSuccess) {
            throw Error(userMessageGoogleDriveOrDropbox)
        }

        // For videos and images which are referenced in Project the upload is requested
        // when the referencing DB record is accepted.
        // For project images however there is no DB record in the local DB.
        // For those entries we set requestUpload to trigger the upload.
        if (requestUpload) {
            VideoCache.videoCacheUploader.postMessage({ func: 'upload', _id: vcr._id })
        }

        log('copyFileToVideoCache DONE')
        return _id
    }

    // Verify all blobs for a video cache record are actually stored in the cache.
    // Sometimes copying from dropbox does odd things that we need to catch.
    private static async didUploadSucceed(_id: string) {
        let vcr = await VideoCacheRecord.get(_id)
        for (let i=0; i<vcr.uploadeds.length; ++i) {
            try {
                let blobId = vcr.videoBlobId(i)
                let videoBlob = new VideoBlob(blobId)
                await videoBlob.loadFromDB(true)
            } catch (error) {
                return false
            }
        }

        return true
    }

    static async getAllVcrs() {
        let docs = await VideoCache._db.getAll(VideoCache.CACHEDVIDEOS)
        return docs.map(doc => new VideoCacheRecord(doc))
    }

    // UTILITY methods for unit tests
    
    static async getAllVideoBlobs() {
        let docs = await VideoCache._db.getAll(VideoCache.VIDEOBLOBS)
        return docs
    }

    static async getAllVideoBlobIds() {
        const keys = await VideoCache._db.getAllKeys(VideoCache.VIDEOBLOBS)
        return keys.map(key => key.toString())
    }

    static async getRecordsForKeys(keys: string[]): Promise<{ _id: string, blob: Blob }[]> {
        const tx = VideoCache._db.transaction(VideoCache.VIDEOBLOBS, 'readonly')
        const store = tx.objectStore(VideoCache.VIDEOBLOBS)
        const promises = keys.map(key => store.get(key))
        const records = await Promise.all(promises)
        await tx.done
        return records
    }

    static dump(match?: string) {
        VideoCache.getAllVcrs()
            .then(vcrs => {
                for (let vcr of vcrs) {
                    if (match && !vcr._id.includes(match)) continue
                    console.log(JSON.stringify(vcr,null,4))
                }

                return VideoCache.getAllVideoBlobs()
            })
            .then(vbs => {
                for (let vb of vbs) {
                    if (match && !vb._id.includes(match)) continue
                    console.log(vb._id, vb.blob ? '' : '!!!')
                }
            })
    }

    static async deleteAll() {
        let vcrs = await VideoCache.getAllVcrs()
        for (let vcr of vcrs) {
            log(`delete vcr ${vcr._id}`)
            await VideoCache._db.delete(VideoCache.CACHEDVIDEOS, vcr._id)
        }

        let vbs = await VideoCache.getAllVideoBlobs()
        for (let vb of vbs) {
            log(`delete VideoBlob ${vb._id}`)
            await VideoCache._db.delete(VideoCache.VIDEOBLOBS, vb._id)
        }
    }

    static async deleteCache() {
        try {
            await deleteDB(VideoCache.CACHEDVIDEOS)
        } catch (err) {
            log('deletecache error', err)
        }

        try {
            await deleteDB(VideoCache.VIDEOBLOBS)
        } catch (err) {
            log('deletecache error', err)
        }
    }

    static stressTest() {
        VideoCache.videoCacheDownloader.stressTest()
            .catch(err => log(err))
    }
}

const _window = window as any
_window.videoCacheStressTest = VideoCache.stressTest
_window.VideoCache = VideoCache

// console.log(await window.VideoCache.videoCacheDownloader.getProgress('TESTnm/200829_201205/210519_194355/210519_194356-9'))