
import React, { Component } from 'react'
import { observable, makeObservable, computed } from 'mobx';
import { observer, /* inject */ } from 'mobx-react'
import { CanvasPath } from "react-sketch-canvas"

import { Root } from '../../models3/Root'
import { PassageVideo, PassageVideoReference } from '../../models3/ProjectModels'
import { VideoToolbar } from './VideoToolbar'
import VideoPositionBar from './VideoPositionBar'
import ThinVideoPositionBar from './ThinVideoPositionBar'
import VideoMessage from './VideoMessage'
import NoteBar from '../notes/NoteBar'
import { displayError, systemError } from '../utils/Errors'
import "./Video.css"
import GlossBar from '../glosses/GlossBar'
import RootVideoPlayer from './RootVideoPlayer'
import VideoPositionBarControls from './VideoPositionBarControls'
import { RefRange } from '../../scrRefs/RefRange'
import { VerseReferenceEditor } from './VerseReferenceEditor'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { t } from 'ttag'
import VideoDropTarget from '../passages/VideoDropTarget'
import { prepNotification } from '../utils/PrepNotification'
import { IOnRecordingDone, ResumableVideoRecorder } from '../notes/ResumableVideoRecorder'
import { fmt } from '../utils/Fmt'
import TRLPassageSettings from '../TRL/Authoring/TRLPassageSettings'
import { VideoSketchDisplay, VideoSketcher } from './VideoSketcher'

import _debug from "debug"; let log = _debug('sltt:VideoMain') 

const htmlElementVisible = (el: HTMLElement) => el.offsetHeight > 0 && el.offsetWidth > 0

function getAbsoluteYPosition(el: HTMLElement | null) {
    let y = 0
    while (el) {
        y += (el.offsetTop - el.scrollTop + el.clientTop)
        el = el.offsetParent as (HTMLElement | null)
    }
    return y
}

function positionOfExistingElements(videoMainElement: HTMLElement, compareDraftVideoMainElement: HTMLElement) {
    let videoMainElementVisible = htmlElementVisible(videoMainElement)
    let compareDraftVideoMainElementVisible = htmlElementVisible(compareDraftVideoMainElement)
    if (videoMainElementVisible && compareDraftVideoMainElementVisible) {
        return positionOfVisibleElements(videoMainElement, compareDraftVideoMainElement)
    }
    return 0
}

function positionOfVisibleElements(videoMain: HTMLElement, compareDraftVideoMain: HTMLElement) {
    let vmParentPos = getAbsoluteYPosition(videoMain.offsetParent as (HTMLElement | null))
    let cdvmPos = getAbsoluteYPosition(compareDraftVideoMain)
    const BORDER_AND_PADDING_OFFSET = 6 // Video main has 5px padding + 1px border
    return cdvmPos - vmParentPos - BORDER_AND_PADDING_OFFSET
}

interface IVideoMain {
    rt: Root,
    onVideoEnded?: () => void,
    videoHasLoaded?: () => void,
    videoHasChanged?: () => void,
    startPlaying?: () => void,
    stopPlaying?: () => void,
    setShowDetachedPlayer?: (value: boolean) => void,
}

class VideoMain extends Component<IVideoMain> {
    private recordListener: any
    @observable videoBeingRecorded: PassageVideo | null = null
    @observable videoAreaWidth = 640
    @observable videoAreaHeight = 360 
    @observable videoMainHeightOffset = 0
    @observable editingReference = false
    @observable reference?: PassageVideoReference
    @observable currentReferences: RefRange[] = []
    @observable referenceTime = 0
    @observable _isInTRLPassageSettingsMode = false
    @computed get isInTRLPassageSettingsMode () {
        return this.props.rt.isTRLProject && this._isInTRLPassageSettingsMode
    }

    recorderComponent: any
    intervalId?: NodeJS.Timeout
    mainVideoWidthIntervalId?: NodeJS.Timeout

    segmentIndex = -1   // segmentIndex for patch being edited, -1 if none

    onRecordingDone: IOnRecordingDone | null = null

    constructor(props: IVideoMain) {
        super(props)

        makeObservable(this);

        //extendObservable(this, {})

        this.record = this.record.bind(this)
        this.stop = this.stop.bind(this)
        this._record = this._record.bind(this)
        this._stopRecording = this._stopRecording.bind(this)
        this._stop = this._stop.bind(this)
        this.setNoteBarWidth = this.setNoteBarWidth.bind(this)
        this.setPositionOfVideoMain = this.setPositionOfVideoMain.bind(this)
        this.onMainVideoRecordingDone = this.onMainVideoRecordingDone.bind(this)
        this.setContainerHeight = this.setContainerHeight.bind(this)
        this.keydown = this.keydown.bind(this)
        this.setReference = this.setReference.bind(this)
        this.openTRLPassageSettings = this.openTRLPassageSettings.bind(this)
        this.setIsInTRLPassagSettingsMode = this.setIsInTRLPassagSettingsMode.bind(this)
    }

    componentDidMount() {
        this.setNoteBarWidth()
        this.intervalId = setInterval(this.setNoteBarWidth, 100)  // Temporary, use ResizeObserver once Typescript adds type declarations for it
        this.mainVideoWidthIntervalId = setInterval(this.setPositionOfVideoMain, 100)
        this.props.rt.addListener('record', this.record)
        this.props.rt.addListener('stop', this.stop)
        window.addEventListener('keydown', this.keydown)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keydown)
        this.props.rt.removeListener('record', this.record)
        this.props.rt.removeListener('stop', this.stop)
        this.intervalId && clearInterval(this.intervalId)
        this.mainVideoWidthIntervalId && clearInterval(this.mainVideoWidthIntervalId)
        this.intervalId = undefined
        this.mainVideoWidthIntervalId = undefined
    }

    setNoteBarWidth() {
        const { useMobileLayout, useNarrowWidthLayout } = this.props.rt
        if (useMobileLayout || useNarrowWidthLayout) { return }
        
        let videoArea = document.querySelectorAll("[data-id='main-video']")
        for (let element of videoArea) {
            let _element = element as HTMLElement
            let isVisible = htmlElementVisible(_element)
            if (isVisible) {
                this.videoAreaWidth = _element.offsetWidth
            }
        }
    }

    setPositionOfVideoMain() {
        // Don't set the height offset for mobile devices
        const { useMobileLayout, useNarrowWidthLayout } = this.props.rt
        if (useMobileLayout || useNarrowWidthLayout) { return }

        let videoMain = document.querySelectorAll("[data-id='video-main']")
        let compareDraftVideoMain = document.querySelectorAll("[data-id='compare-draft-video-main']")
        if (videoMain.length && compareDraftVideoMain.length) {
            this.videoMainHeightOffset = positionOfExistingElements(videoMain[0] as HTMLElement, compareDraftVideoMain[0] as HTMLElement)
        } else {
            this.videoMainHeightOffset = 0
        }
    }

    _stopRecording() {
        log('_stopRecording')
        this.props.rt.recording = false
        this.videoBeingRecorded = null
    }

    // This is than handler for the VideoToobar record button click
    _record() {
        let { rt } = this.props
        let { name, portion, passage, iAmTranslator, recording } = rt

        if (recording) {
            log(`record ignored`)
            return    // ignore if already recording
        }
        if (!portion || !passage || !iAmTranslator) return

        let videoBeingRecorded = passage.createVideo(name)
        // this will trigger a 'record' event on root which will be handled by this.record()
        rt.record(name, videoBeingRecorded, this.onMainVideoRecordingDone)
    }

    _stop() {
        this.props.rt.stop()
    }
    
    // Handle 'record' event that was sent by root.record(...).
    // This code is shared by the record main video and record patch paths.
    // The resulting data is directed to the correct location by onRecordingDone
    // which is set differently for various paths.
    record(projectName: string, 
        videoBeingRecorded: PassageVideo, 
        onRecordingDone: IOnRecordingDone
    ) {
        let { recording } = this.props.rt
        log('record', fmt({
            prevVideoBeingRecorded: !!this.videoBeingRecorded,
            url: videoBeingRecorded.url,
            recording,
        }))

        if (recording) {
            log(`record ignored`)
            return    // ignore if already recording
        }
        
        this.onRecordingDone = onRecordingDone
        this.videoBeingRecorded = videoBeingRecorded
        this.props.rt.recording = true
    }

    stop() {
        /**
         * Sigh, we get here because:
         *    SegmentsEditor called rt.stop()
         *    Which triggere an 'stop' event
         *    This component has an event listener for 'stop' which comes here
         * 
         * Why? We wanted to trigger the record patch event from the segments editor.
         * We thot users would be more comfortable if the recording happened here
         * in the same location where they record non-patch videos.
         * 
         * Considerably more round about than I am very comfortable with.
         * Not sure if there is simpler way to implement idea.
         * Not sure if idea of always recording in the same screen space is necessary.
         */
        let { rt } = this.props
        let { recording, playing } = rt
        log('stop', fmt({recording, playing}))

        if (recording) {
            this._stopRecording()
            return
        }
    }
    
    async onMainVideoRecordingDone(err: any, blobsCount?: number, url?: string, duration?: number) {
        let video = this.videoBeingRecorded
        log('onMainVideoRecordingDone', 
            fmt({ video, err, blobsCount, url, duration}))

        this._stopRecording()

        if (!video) {
            err = t`Something went wrong. No video recorded.`
        }

        if (err) {
            displayError(err)
            return
        }
        
        video!.url = url! + '-' + blobsCount
        
        video!.duration = duration!

        await this.addVideo(video!)
    }
 
    async addVideo(video: PassageVideo) {
        let { rt } = this.props
        let _video = await rt.passage!.addVideoWithDefaultSegment(video!)
        
        // We set passageVideo to null first in order to ensure that
        // VideoMain will update. Otherwise there is a race condition
        // where DBAcceptor partially set up the passageVideo and triggers
        // a VideoMain render before the passageVideo has all the info
        // to correctly display
        await rt.setPassageVideo(null)
        await rt.setPassageVideo(_video)
        prepNotification({ text: `New Note Video Recorded`, noteRoot: rt, isVideoRecording: true })
    }

    setContainerHeight(height: number) {
        this.videoAreaHeight = height
    }

    setReference(reference?: PassageVideoReference) {
        this.currentReferences = reference?.references || []
        this.referenceTime = reference?.time || 0
        this.reference = reference
    }

    openTRLPassageSettings() {
        this.setIsInTRLPassagSettingsMode(true)
    }

    setIsInTRLPassagSettingsMode(value: boolean) {
        this._isInTRLPassageSettingsMode = value
    }

    render() {
        let { rt, setShowDetachedPlayer } = this.props
        let { passage, passageSegment, passageVideo, recording, timelineZoom, currentTime, useMobileLayout, verseReference, 
            setLexicalLink, project } = rt
        const plan = project.plans[0]

        const editingVideoSketch = rt.videoSketchData.editingCurrentSegment(rt.passage, rt.passageSegment)
        let { _record, _stop, videoAreaWidth, videoMainHeightOffset,
            videoAreaHeight, setContainerHeight } = this
        
        if (!recording) { this.recorderComponent = null }

        let videoPosition = rt
        let noteBarDisplay = rt
        let videoPositionControls = rt
        let glossBarDisplay = rt
        let w = videoAreaWidth

        let message = <div>{/* translator: important */ t`Drop video here.`}</div>
        let dropTargetView = <DropTargetViewLarge message={message} />

        let compressingVideo = !!passage?.videoBeingCompressed
        let passageThumbnailVideo = passage?.thumbnailVideos.find(() => true) // finds first or undefined

        if (recording && !!this.videoBeingRecorded /* avoid crash: TypeError: Cannot read properties of null (reading 'url') */) {
            return (
                    <ResumableVideoRecorder
                        className="main-video-recorder"
                        projectName={project.name}
                        cancel={this._stopRecording}
                        url={this.videoBeingRecorded.url}
                        save={this.onRecordingDone!}
                    />
            )
        }

        if (this.isInTRLPassageSettingsMode) {
            return (
                <TRLPassageSettings
                    key={passage?._id} /* reset component if passage switches */
                    rt={rt}
                    passageThumbnailVideo={passageThumbnailVideo}
                    setIsInTRLPassagSettingsMode={this.setIsInTRLPassagSettingsMode}
                />
            )
        }

        return (
            <div className="video-main" style={{ marginTop: videoMainHeightOffset }} data-id='video-main'>
                <VideoToolbar
                    rt={rt}
                    playAllVideos={() => rt.playAll()}
                    playCurrentVideo={() => rt.play()}
                    pause={() => rt.pause()}
                    record={_record}
                    stopRecording={_stop}
                    setShowDetachedPlayer={setShowDetachedPlayer}
                    openTRLPassageSettings={this.openTRLPassageSettings}
                />
                
                <VideoDropTarget rt={rt} passage={passage!} videoIsPatch={false} ondone={() => { }} dropTargetView={dropTargetView}>
                    <div className='video-area' data-id='main-video' style={{ height: videoAreaHeight }}>
                        {!compressingVideo && passage &&
                            <>
                                {editingVideoSketch && <VideoSketcher
                                    data={rt.videoSketchData}
                                    width={this.videoAreaWidth}
                                    height={this.videoAreaHeight} />}
                                {!editingVideoSketch && <VideoSketchDisplay
                                    rt={rt}
                                    sketchPaths={passageSegment?.actualSegmentSketchPaths(passage) || []}
                                    width={this.videoAreaWidth}
                                    height={this.videoAreaHeight} />}
                                <RootVideoPlayer
                                    rt={rt}
                                    setContainerHeight={setContainerHeight}
                                    showSegmentLabels={!useMobileLayout}
                                    initialTime={currentTime} />
                            </>}

                        { (compressingVideo || !passageVideo) && <VideoMessage rt={rt} /> }
                    </div>
                </VideoDropTarget>

                { passageVideo && (
                    <div>
                        <VideoPositionBarControls {...{ videoPositionControls, videoPosition, plan }} />
                        <VideoPositionBar {...{videoPosition}} />
                        { verseReference && <VerseReferenceEditor {...{videoPosition}} />}
                        <ThinVideoPositionBar {...{ videoPosition, timelineZoom }} />
                        <NoteBar {...{ noteBarDisplay, w }} />
                        <GlossBar {...{ rt, glossBarDisplay, w, setLexicalLink }} />
                    </div>
                )}
            </div>
        )
    }

    keydown(e: KeyboardEvent) {
        let { rt } = this.props
        let { videoPlaybackKeydownEnabled, editingSegment, passage, gtsp } = rt

        // Ignore keydown handlers if this event meets certain conditions
        let element = (e.target && e.target.toString()) || ''
        let el = e.target as Element
        let shouldReject = element.includes('HTMLInputElement')
                        || element.includes('HTMLTextAreaElement')
                        || el.getAttribute && el.getAttribute('contenteditable') === 'true'
                        || !videoPlaybackKeydownEnabled
                        || passage?.videoBeingCompressed
        
        if (shouldReject && !gtsp.searchIsOpen) {
            log('keydown rejected non-global')
            return
        }

        e.stopPropagation()

        log(`keydown code=${e.code}`,e)

        if (rt.recording) {
            return // let ResumableVideoRecorder handle keys
        }

        if (e.code === 'Backslash' || e.code === 'Slash') {
            e.preventDefault()
            rt.createPassageGloss()
                .catch(displayError)
            return
        }

        let adjustCurrentTime = function(delta: number) {
            e.preventDefault()
            rt.adjustCurrentTime(delta)
        }

        if (e.code === 'ArrowLeft' && e.altKey) {
            adjustCurrentTime(-10.0)
            return
        }

        if (e.code === 'ArrowLeft' && e.shiftKey) {
            adjustCurrentTime(-1.0)
            return 
        }

        /**
         * On Windows the meta key is the windows key.
         * On Mac, the meta key is the command key.
         * AFAICT this means that our shortcut for moving 1 frame
         * has never worked on Windows prior to add e.ctrlKey before.
         */
        if (e.code === 'ArrowLeft' && (e.metaKey || e.ctrlKey)) {
            adjustCurrentTime(-.05)
            return 
        }

        if (e.code === 'ArrowRight' && e.altKey) {
            adjustCurrentTime(10.0)
            return 
        }

        if (e.code === 'ArrowRight' && e.shiftKey) {
            adjustCurrentTime(1.0)
            return 
        }

        if (e.code === 'ArrowRight' && (e.metaKey || e.ctrlKey)) {
            adjustCurrentTime(.05)
            return 
        }

        if (e.code === 'Escape' && gtsp.searchIsOpen) {
            e.preventDefault()
            gtsp.searchIsOpen = false
            return
        }

        if (e.code === 'Tab' && e.shiftKey && gtsp.searchIsOpen) {
            e.preventDefault()
            gtsp.previousItem(rt).catch(displayError)
            return
        }

        if (e.code === 'Tab' && !e.shiftKey && gtsp.searchIsOpen) {
            e.preventDefault()
            gtsp.nextItem(rt).catch(displayError)
            return
        }

        if (e.code === 'Enter' && gtsp.searchIsOpen) {
            if (gtsp.itemNumber < 0 && gtsp.glossIds.length > 0) gtsp.itemNumber = 0
            gtsp.playGloss(rt).catch(displayError)
            return
        }

        if (e.code === 'Space') {
            if (rt.playing) {
                rt.pause()
            } else if (!editingSegment) {
                // Do default play operation (same as clicking play button)
                rt.play(undefined, undefined, undefined)
            }

            e.preventDefault()
            return
        }

        if (e.code === 'Escape') {
            rt.stop()
        }
    }

}

export default observer(VideoMain)