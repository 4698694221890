import React, { FunctionComponent } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { t } from 'ttag'
import { observer } from 'mobx-react'
import _debug from 'debug'

import { GenericIcon, MembersIcon, ReferencesIcon, UploadIcon, UsersCogIcon } from "../utils/Icons";
import MembersEditor from "./members/MembersEditor";
import { Root } from "../../models3/Root";
import ProjectReferences from "./references/ProjectReferences";

//import UploadReport from './UploadReport'
// import DebugRecord from './DebugRecord'
import DebugScheduledDownloads from '../translation/DebugScheduledDownloads'
import UploadReport from '../translation/UploadReport'
import VideoCacheResetRequest from '../translation/VideoCacheResetRequest'
import RefetchLocalDatabase from "../utils/RefetchLocalDatabase";
import { AppRoot } from "../../models3/AppRoot";
import { ErrorBoundary } from '../utils/Errors'
import { systemError } from "../utils/Errors"
import TeamPreferences from "./TeamPreferences";
import ProjectPreferences from "./ProjectPreferences";
import LoadingMessage from "../utils/InitializationMessage";
import SendDebugInfo from "../translation/SendDebugInfo";
import { LocalTeamStorageSettings } from './LocalTeamStorageSettings'
import { getHasElectronContext } from '../../models3/SlttAppStorage'
import { useFlags } from 'launchdarkly-react-client-sdk'

const log = _debug('sltt:ProjectSettings') 

interface IProjectSettings {
    rt: Root,
    appRoot: AppRoot,
}

const ProjectSettings: FunctionComponent<IProjectSettings> = observer(({ rt, appRoot }) => {
    let { initialized, loadingMessage, iAmAdmin } = rt
    const flags = useFlags()
    const enableLocalTeamStorage = Boolean(getHasElectronContext() && (flags?.localTeamStorage || flags?.localTeamStorage === undefined /* offline */))

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    return (
        <Tabs>
            <TabList>
                <Tab key='members'>
                    <MembersIcon className='project-settings-icon' tooltip={t`Members`} />
                </Tab>
                <Tab key='references'>
                    <ReferencesIcon className='project-settings-icon' tooltip={t`References`} />
                </Tab>
                <Tab key='upload-report'>
                    <UploadIcon className='project-settings-icon' tooltip={t`Upload report`} />
                </Tab>
                <Tab key='debug'>
                    {t`Debug`}
                </Tab>
                {iAmAdmin && (
                    <Tab key='team-preferences'>
                        <UsersCogIcon className='project-settings-icon' tooltip={t`Team preferences`} />
                    </Tab>
                )}
                <Tab key='project-preferences'>
                    <GenericIcon iconName="fa-flag" className='project-settings-icon' tooltip={t`Project preferences`} />
                </Tab>
                {enableLocalTeamStorage && <Tab key='local-team-storage'>
                    <GenericIcon iconName="fa-network-wired" className='project-settings-icon' tooltip={t`Local Team Storage Settings`} />
                </Tab>}
            </TabList>
            <TabPanel key='members'>
                <ErrorBoundary>
                    <MembersEditor {...{ rt, appRoot }} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key='references'>
                <ErrorBoundary>
                    <ProjectReferences rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key='upload-report'>
                <ErrorBoundary>
                    <UploadReport rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key='debug'>
                <ErrorBoundary>
                    {/* <UploadReport rt={rt} /> */}
                    {/* <DebugRecord /> */}
                    <DebugScheduledDownloads rt={rt} />
                    <div style={{height: '1em'}} />
                    <VideoCacheResetRequest />
                    <div style={{ height: '1em' }} />
                    <RefetchLocalDatabase appRoot={appRoot} />
                    <div style={{ height: '1em' }} />
                    <SendDebugInfo />
                </ErrorBoundary>
            </TabPanel>
            {iAmAdmin && (
                <TabPanel key='team-preferences'>
                    <ErrorBoundary>
                        <TeamPreferences {...{ rt }} />
                    </ErrorBoundary>
                </TabPanel>
            )}
            <TabPanel key='project-preferences'>
                <ErrorBoundary>
                    <ProjectPreferences {...{ rt }} />
                </ErrorBoundary>
            </TabPanel>
            {enableLocalTeamStorage && (<TabPanel key='local-team-storage-settings'>
                <ErrorBoundary>
                    <LocalTeamStorageSettings {...{ rt }} />
                </ErrorBoundary>
            </TabPanel>)}
        </Tabs>
    ) 
})

export default ProjectSettings