import { makeObservable, observable } from 'mobx'
import { t } from 'ttag'
import { DiskUsage } from './hostFolder.d'
import { MIN_DISK_SPACE_MB } from './connections.d'


export const tInaccessible = t`Not accessible!!`

export function formatLowStorageSpaceMessage(diskUsage: DiskUsage | undefined) {
    return t`Insufficient storage space.` + ' ' + t`Need:` + ` ${MIN_DISK_SPACE_MB} MB. ` + t`Have:` + ` ${formatStorageSpaceMessage(diskUsage, 'MB')}`
}

export function formatStorageSpaceMessage(diskUsage: DiskUsage | undefined, unit: 'GB' | 'MB' = 'GB') {
    const tAvailable = t`available`
    const available = diskUsage ? diskUsage.available : 0
    const total = diskUsage ? diskUsage.total : 0
    const availableWithUnit = unit === 'GB' ? (available / 1024 / 1024 / 1024).toFixed(2) : (available / 1024 / 1024).toFixed(2)
    const totalWithUnit = unit === 'GB' ? (total / 1024 / 1024 / 1024).toFixed(2) : (total / 1024 / 1024).toFixed(2)
    const tTotal = t`total`
    const storageSpace = diskUsage ? `${availableWithUnit} (${tAvailable}) / ${totalWithUnit} (${tTotal}) ${unit}` : tInaccessible
    return storageSpace
}

class LocalTeamStorageData {

    @observable allowLocalTeamStorage: boolean = false
    @observable myServerDiskUsage: DiskUsage | undefined = undefined

    constructor() {
        makeObservable(this)
    }
}

// create singleton instance
const localTeamStorageData = new LocalTeamStorageData()
export { localTeamStorageData }
