import { useContext } from 'react'
import { DBObject } from '../../models3/ProjectModels'
import { RootContext } from '../app/RootContext'

/** Return true iff object was created by the current user or the current user is an admin.
 * For some objects (Portions and Passages currently) this check extends to sub-objects.
 */
export function useUserCanDelete(dbObject: DBObject | null) {
    const rt = useContext(RootContext)
    if (!dbObject) return false
    
    const { iAmAdmin, iAmConsultant, username } = rt!
    return iAmAdmin || (iAmConsultant && dbObject.iAmCreator(username))
}
