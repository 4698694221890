import React from 'react'
import ReactTooltip from "react-tooltip"
import { sat } from '../app/slttAvtt'

import { SlttHelp } from './Buttons'
import './Icons.css'

import { SlttHelp2 } from './SLTTHelp2'
import { Theme } from './LocalStorage'

export function enable(cns, enabled) { return enabled ? cns : cns + ' sl-button-disabled' }

// Horizontal black line bisected by a vertical light blue line
// Refers to a segment of a video

export const PassageSegmentsIcon = function ({ id, enabledColor, nonEnabledColor, tooltip, place, onClick, enabled, style, className }) {
    let verticalColor, horizontalColor
    if (enabled) {
        verticalColor = 'lightblue'
        horizontalColor = enabledColor
    } else {
        verticalColor = 'lightgrey'
        horizontalColor = nonEnabledColor
    }

    return (
        <SlttHelp2 id={id} tooltip={tooltip} place={place} video="a4" >
            {/* <span className={className} onClick={() => onClick && enabled && onClick()} > */}
                <svg style={style}
                        onClick={() => onClick && enabled && onClick()}
                        className={enable(`${className} sl-create-passage-segment-svg`, enabled)}
                        viewBox="0 0 24 25"
                        xmlns="http://www.w3.org/2000/svg">
                    <g> 
                        <line x1="0" y1="13" x2="8" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="16" y1="13" x2="24" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="12" y1="0" x2="12" y2="25" stroke={verticalColor} strokeWidth="4" />
                    </g>
                </svg>
            {/* </span> */}
        </SlttHelp2>
    )
}

export const PassageVideoReferenceIcon = function ({ className, enabledColor, nonEnabledColor, style, onClick, tooltip, enabled }) {
    let lineColor
    if (enabled) {
        lineColor = enabledColor
    } else {
        lineColor = nonEnabledColor
    }
    return (
        <SlttHelp2 id="verses.html#addverses" tooltip={tooltip} place="bottom" video="a6">
            <span className={`${className}`} data-toggle="tooltip" onClick={e => enabled && onClick && onClick(e)}>
                <svg
                    className={enable(`sl-create-passage-video-reference-svg`, enabled)} 
                    style={style}
                    width="24"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <line x1="0" y1="13" x2="24" y2="13" stroke={lineColor} strokeWidth="2" />
                        <line x1="9" y1="15" x2="12" y2="22" stroke={lineColor} strokeWidth="2" />
                        <line x1="12" y1="22" x2="15" y2="15" stroke={lineColor} strokeWidth="2" />
                    </g>
                </svg>
            </span>
        </SlttHelp2>
    )
}

export const PassageGlossIcon = function ({ tooltip, enabledColor, nonEnabledColor, onClick, enabled, style, className }) {
    let verticalColor, horizontalColor
    if (enabled) {
        verticalColor = 'lightblue'
        horizontalColor =  enabledColor
    } else {
        verticalColor = 'lightgrey'
        horizontalColor =  nonEnabledColor
    }

    return (
        <SlttHelp2 id="glosses.html" tooltip={tooltip} place="bottom" video="a5">
            <span className={className} onClick={() => onClick && enabled && onClick()} >
                <svg style={style}
                    className={enable('sl-create-passage-gloss-svg', enabled)}
                    width="24"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <line x1="0" y1="13" x2="8" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="16" y1="13" x2="24" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="12" y1="0" x2="12" y2="4" stroke={verticalColor} strokeWidth="4" />
                        <line x1="12" y1="9" x2="12" y2="16" stroke={verticalColor} strokeWidth="4" />
                        <line x1="12" y1="21" x2="12" y2="25" stroke={verticalColor} strokeWidth="4" />
                    </g>
                </svg>
            </span>
        </SlttHelp2>
    )
}

export const HardDriveIcon = function ({ id, tooltip, className }) {
    return (
        <>
            <span className={className}>
                <div className="icon-container" 
                    data-for={`getContent ${id}`} 
                    data-tip={tooltip} 
                    data-multiline={true}
                    place="bottom"
                    style={{ width: '27px', height: '27px' }}
                >
                <svg
                    className={"storage-limit-status-svg"}
                    fill="#FF0000" height="27px" width="27px"
                    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 502.664 502.664"><g id="SVGRepo_bgCarrier" strokeWidth="0">
                    </g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <g> <g> <g>
                        <path d="M70.364,0v502.664H432.3V0H70.364z M243.966,457.732H109.536V316.185l46.981,43.53 c-15.509,6.795-26.381,22.239-26.381,40.251c0,24.202,19.673,43.897,43.94,43.897s43.918-19.673,43.918-43.897 c0-1.208-0.259-2.33-0.367-3.538c0.043-0.043,0.086-0.065,0.129-0.108l26.208,0.453 C243.966,396.773,243.966,457.732,243.966,457.732z M198.537,396.557c0.086,1.165,0.626,2.222,0.626,3.408 c0,13.848-11.238,25.065-25.087,25.065c-13.892,0-25.13-11.238-25.13-25.065c0-13.935,11.238-25.13,25.13-25.13 c2.243,0,4.228,0.733,6.32,1.273c39.755-30.415,114.778-86.52,116.267-84.903C297.936,292.672,233.223,360.728,198.537,396.557z M272.87,339.308c47.779-52.201,42.645-58.392,39.237-62.145c-8.499-9.405-8.499-9.405-86.089,48.577l-13.331,10.181 c-63.677-16.998-110.723-74.915-110.723-143.985c0-82.422,66.826-149.248,149.162-149.248 c82.465,0,149.227,66.848,149.227,149.248C400.354,266.895,345.003,328.782,272.87,339.308z"></path>
                        <path d="M251.127,147.544c-24.461,0-44.328,19.91-44.328,44.393c0,24.504,19.888,44.306,44.328,44.306 c24.591,0,44.393-19.824,44.393-44.306C295.498,167.476,275.653,147.566,251.127,147.544z"></path>
                    </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g>
                </svg>
                </div>
                <ReactTooltip className="hard-drive-icon-tooltip" id={`getContent ${id}`} multiline={true} effect="solid" place="right" />
            </span>
        </>
    )
}

export const ImageReferencesIcon = function ({ className, tooltip }) {
    return (
        <SlttHelp id="images.html" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button far fa-image`} />
        </SlttHelp>
    )
}

export const BiblicalSignsIcon = function ({ className, tooltip }) {
    // The far (outline icon) is not available in the free version of Font Awesome.
    // Must user fas solid icon.
    return (
        <SlttHelp id="signs.html" tooltip={tooltip} place="top">
            <span className={`${className} fas fa-book-open`} />
        </SlttHelp>
    )
}

export const WarningIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-exclamation-triangle`}
            data-toggle="tooltip"
            title={tooltip} />
    )   
}

export const SolidCheckCircle = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-check-circle`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CheckIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-check`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const LoadingIcon = function ({ className }) {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-spinner fa-spin`} />
    )
}

export const ReferencesIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-book`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CopyrightIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-copyright`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ShareIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-users`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const TitleIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fa fa-fw fa-tag`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const TextIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-list`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const QuoteIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-quote-left`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ApproveIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-thumbs-up`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}
export const MembersIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-user`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const DisapproveIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-thumbs-down`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ArrowsIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-arrows-alt`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PencilIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-pencil-alt`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const RectangleIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-square-full`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CloneIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-clone`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const GoBackIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-angle-double-left`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const GoForwardIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-angle-double-right`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const EditIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button fas fa-edit`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export const UploadIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-cloud-upload-alt`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ConsultantIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fa fa-fw fa-user-check`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const GotoReferenceIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fa fa-fw fa-arrow-circle-right`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CogIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-cog`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const HashtagIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-hashtag`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const UsersCogIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-users-cog`}
            data-toggle="tooltip"
            title={tooltip}
        />

    )
}

export const CircleIcon = function ({ className, style, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-circle`}
            style={style}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ThinCircleIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-circle`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CheckCircle = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-check-circle`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const SquareIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button far fa-square`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ForwardIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-forward`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CompareDraftIcon = function ({ className, tooltip }) {
    return (
        <span>
            <SquareIcon className={`${className} compare-draft-left-icon`} tooltip={tooltip} />
            <SquareIcon className={`${className} compare-draft-right-icon`} tooltip={tooltip} />
        </span>
    )
}

export const DbsPlayIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-film`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const DesktopIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button fas fa-desktop`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

// iconName, e.g. fa-flag
export const GenericIcon = ({ iconName, className, tooltip }) => (
    <span
        className={`${className} sl-fa-button fas ${iconName}`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export const CommentIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button fas fa-comment-alt`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export const DocumentIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button far fa-file-alt`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export const DownloadFullVideoIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button fas fa-download`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export const ClipboardIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button far fa-clipboard`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export const EllipsisIcon = ({ className, tooltip }) => (
    <span
        className={`${className} sl-fa-button fas fa-ellipsis-v`}
        data-toggle="tooltip"
        title={tooltip}
    />
)

export function TrashIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-trash-alt`}
            data-id="trash-button"
            data-toggle="tooltip"
            alt={tooltip}
            title={tooltip}
        />
    )
}

export function UnremoveIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} fas fa-trash-arrow-up`}
            data-id="untrash-button"
            data-toggle="tooltip"
            alt={tooltip}
            title={tooltip}
        />
    )
}

export function UndoIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-rotate-left`}
            data-id="trash-button"
            data-toggle="tooltip"
            alt={tooltip}
            title={tooltip}
        />
    )
}

export function KeyIcon({ className, tooltip }) {
    return (
        <SlttHelp id="signs.html" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button fas fa-bible`} />
        </SlttHelp>
    )
}

export function HelpLink({ className, tooltip }) {
    let helpBucketName = sat('sltt-help', 'avtt-help')
    return (
        <a href={`https://s3.amazonaws.com/${helpBucketName}/index.html`} target="_blank">
            <span
                className={`${className} fas fa-question-circle`}
                data-toggle="tooltip"
                title={tooltip}
            />
        </a>
    )
}

export function CopyIcon({ className, tooltip }) {
    return (  
        <span
            className={`${className} sl-fa-button far fa-copy`}
            data-toggle='tooltip'
            title={tooltip}
        />
    )
}

export function PasteIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-paste`}
            data-toggle='tooltip'
            title={tooltip}
        />
    )
}

export function SearchIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} fa fa-search`}
            data-toggle='tooltip'
            title={tooltip}
        />
    )
}

export const EditDifficultyIcon = CogIcon

export const PassageDocumentIcon = ({ className, tooltip }) => (
    <SlttHelp2 id="passages.html#passagedocuments" tooltip={tooltip} place="top">
        <DocumentIcon className={className} />
    </SlttHelp2>
)

export const TRLPanelIcon = ({ className, tooltip }) => (
    <SlttHelp id="translation-resource-library.html#browse" tooltip={tooltip} place="top">
        <TRLIcon className={className} tooltip="" />
    </SlttHelp>
)

export function StarIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-star`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export function HollowStarIcon({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button far fa-star`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const TRLIcon = ({ className, tooltip }) => (
    <span className={`${className} fa-solid fa-folder-tree`}
        data-toggle="tooltip"
        title={tooltip}
    ></span>
)

export const LinkIcon = ({ className, tooltip }) => (
    <span className={`${className} fa-solid fa-link`}
        data-toggle="tooltip"
        title={tooltip}
    ></span>
)



