import { useState, useEffect, useRef } from 'react'
import { getHasElectronContext, probeLANStorageConnections, connectProjectToLANStorage, disconnectFromLANStorage, getProjectLastConnection, addStorageProject, removeStorageProject } from '../../models3/SlttAppStorage'
import { stringify as safeStableStringify } from 'safe-stable-stringify'
import { t } from 'ttag'
import { observer } from 'mobx-react'
import _debug from 'debug'
import { userError, displaySuccess, displayWarning } from '../utils/Errors'
import './LocalTeamStorageButton.css'
import ReactTooltip from 'react-tooltip'
import { AppRoot } from '../../models3/AppRoot'
import { throttle } from 'underscore'
import { ConnectionInfo, MIN_DISK_SPACE_MB } from '../../models3/lanStorage/connections.d'
import { formatLowStorageSpaceMessage, localTeamStorageData, tInaccessible } from '../../models3/lanStorage/LocalTeamStorage'
import { DiskUsage } from '../../models3/lanStorage/hostFolder.d'

const log = _debug('sltt:LocalTeamStorageButton')

const DEFAULT_LAN_CONNECTION_URL = localStorage.defaultLanConnectionUrl ||
    'file://192.168.8.1/sltt-local-team-storage/sltt-app/lan'

type LocalTeamStorageButtonProps = {
    appRoot: AppRoot
    project: string
    iAmAdmin: boolean
    userEmail: string
    projectAdmins: string[]
    projects: string[]
}

type Connection = {
    accessible: boolean
    connectionInfo: ConnectionInfo
    networkName: string
}

type State = {
    currentProjectIfAdded: string
    isAvailable: boolean
    isConnected: boolean
    tooltipJsx: JSX.Element | null
    tooltipVisible: boolean
    proxyConnections: Connection[]
    proxyConnection: Connection | undefined
    connectionServerId: string | undefined
    connectionInfo: ConnectionInfo | undefined
    networkName: string | undefined
    currentProject: string
}

export const LocalTeamStorageButton = observer(({ appRoot, project, iAmAdmin, userEmail, projectAdmins, projects }: LocalTeamStorageButtonProps) => {
    const isFetching = useRef(false)
    const debounceMessageRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const [connections, setConnections] = useState<Connection[]>([])
    const [state, setState] = useState<State>({
        currentProjectIfAdded: '',
        isAvailable: false,
        isConnected: false,
        tooltipJsx: null,
        tooltipVisible: true,
        proxyConnections: [],
        proxyConnection: undefined,
        connectionServerId: getProjectLastConnection(project)?.serverId,
        connectionInfo: undefined,
        networkName: undefined,
        currentProject: project
    })

    // useEffect(() => {
    //     log('state.proxyConnection', safeStableStringify(state.proxyConnection))
    // }, [state.proxyConnection])

    const updateState = (updates: Partial<State>) => {
        setState(prevState => ({ ...prevState, ...updates }))
    }

    const updateDraft = (draft: State, updates: Partial<State>) => {
        Object.assign(draft, updates)
    }

    /** provide a clone of the state as a draft, and after the changes update the state all at once */
    const stateHelper = (fn: (draft: State) => void) => {
        const draft = { ...state }
        try {
            fn(draft)
        } catch (error) {
            log('### stateHelper error', error)
        } finally {
            setState(draft)
        }
    }

    const isLowStorage = state.connectionInfo?.diskUsage && state.connectionInfo.diskUsage.available <= (MIN_DISK_SPACE_MB * 1024 * 1024)
    const isDeviceDisconnected = state.connectionInfo && state.connectionInfo.diskUsage === undefined
    const MSG_DEVICE_DISCONNECTED = t`Cannot connect: host computer storage device is disconnected`

    useEffect(() => {
        return () => {
            // Clear the timer on component unmount
            if (debounceMessageRef.current) {
                clearTimeout(debounceMessageRef.current)
            }
        }
    }, [])

    const maybeDisplayMessage = (fnDisplayMessage: (message: JSX.Element) => void, message: string, network: string, computerName: string, diskUsage?: DiskUsage | null, throttleMs: number = 500) => {
        if (debounceMessageRef.current) {
            clearTimeout(debounceMessageRef.current)
        }
        // Set a new timer
        debounceMessageRef.current = setTimeout(async () => {
            fnDisplayMessage(makeMesssageJsx(message, network, computerName, diskUsage))
        }, throttleMs)
    }

    const makeMesssageJsx = (message: string, network: string, computerName: string, diskUsage?: DiskUsage | null): JSX.Element => {
        const inaccessibleDisk = diskUsage === null
        const availableMb = ((diskUsage?.available ?? Number.POSITIVE_INFINITY) <= (MIN_DISK_SPACE_MB * 1024 * 1024)) ? 
            (diskUsage?.available! / 1024 / 1024).toFixed(1) : Number.NaN
        const availableGb = Number.isNaN(availableMb) && diskUsage?.available ? (diskUsage.available / 1024 / 1024 / 1024).toFixed(2) : Number.NaN
        return <div>
            <div>{message}</div>
            {!Number.isNaN(availableMb) && <div>{t`Storage`}:&nbsp;<b>{availableMb} MB {t`(too full)`}</b>&nbsp;&nbsp;<i className="far fa-fw fa-folder"></i></div>}
            {!Number.isNaN(availableGb) && <div>{t`Storage`}:&nbsp;<b>{availableGb} GB</b>&nbsp;&nbsp;<i className="far fa-fw fa-folder"></i></div>}
            {inaccessibleDisk && <div>{t`Storage`}:&nbsp;<b>{tInaccessible}</b>&nbsp;&nbsp;<i className="far fa-fw fa-folder"></i></div>}
            {computerName && <div>{t`Computer`}:&nbsp;<b>{computerName}</b>&nbsp;&nbsp;<i className="fa fa-solid fa-laptop"></i></div>}
            <div>{t`Network`}:&nbsp;<b>{network}</b>&nbsp;&nbsp;<i className="fa fa-solid fa-network-wired fa-network-wired" /></div>
        </div>
    }

    const makeTooltipJsx = (lines: string[]): JSX.Element => {
        const iProxy = state.proxyConnections.findIndex(c => c.connectionInfo.serverId === state.proxyConnection?.connectionInfo.serverId) + 1
        const cProxies = state.proxyConnections.length
        const cHosted = state.connectionInfo?.projects.length
        const cMineHosted = projects.filter(p => state.connectionInfo?.projects.includes(p)).length
        const percentRelevant = (cMineHosted / projects.length * 100).toFixed(1)
        const availableGb = (state.connectionInfo?.diskUsage?.available || 0) / 1024 / 1024 / 1024
        const totalGb = (state.connectionInfo?.diskUsage?.total || 0) / 1024 / 1024 / 1024
        const tAvailable = t`available`
        const tTotal = t`total`
        const tInaccessible = t`Not accessible!!`
        const storageUsage = state.connectionInfo?.diskUsage ? `${availableGb.toFixed(2)} (${tAvailable}) / ${totalGb.toFixed(2)} (${tTotal}) GB` : 
            tInaccessible
        return <div>
            {lines.map((line, i) => <div key={i}>{line}</div>)}
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}> [{iProxy}/{cProxies}]</div>
            <div><b>{t`Project hosted?`}&nbsp;</b> {state.currentProjectIfAdded ? t`Yes`: t`No`} {`(${t`Total`}: ${cHosted})`}</div>
            <div><b>{t`Relevance`}:&nbsp;</b> {`${cMineHosted}/${projects.length}`} {`(${percentRelevant}%)`}</div>
            <div><b>{t`Mine?`}&nbsp;</b> {state.connectionInfo?.isMyServer ? t`Yes` : t`No`}</div>
            <div><b>{t`User`}:&nbsp;</b> {state.connectionInfo?.user}</div>
            <div><b>{t`Peers`}:&nbsp;</b> {state.connectionInfo?.peers}</div>
            <div><b>{t`Clients`}:&nbsp;</b> {state.connectionInfo?.clients} </div>
            <div><b>{t`Storage`}:&nbsp;</b> {storageUsage}&nbsp;&nbsp;<i className="far fa-fw fa-folder"></i></div>
            <div><b>{t`Computer`}:&nbsp;</b> {state.connectionInfo?.computerName}&nbsp;&nbsp;<i className="fa fa-solid fa-laptop"></i></div>
            <div><b>{t`Network`}:&nbsp;</b> {state.networkName}&nbsp;&nbsp;<i className="fa fa-solid fa-network-wired fa-network-wired"></i></div>
        </div>
    }

    const { allowLocalTeamStorage } = localTeamStorageData
    const {
        currentProject, connectionServerId, networkName, connectionInfo, 
        currentProjectIfAdded, isAvailable, isConnected, proxyConnection
    } = state
    const stateDeps = {
        currentProject, connectionServerId, networkName, connectionInfo, 
        currentProjectIfAdded, isAvailable, isConnected, proxyConnection
    }
    const stateDepsList = [
        currentProject, connectionServerId, networkName, connectionInfo, 
        currentProjectIfAdded, isAvailable, isConnected, proxyConnection
    ]
    const prevDeps = useRef({
        appRoot, project, iAmAdmin, userEmail, projectAdmins, allowLocalTeamStorage, connections,
        ...stateDeps
    })

    /** to determine which dependencies triggered a the updateConnectionsState useEffect */
    const logAndUpdateStateDepChanges = () => {
        const changes: Partial<typeof prevDeps.current>  = { connections: [] }
        if (appRoot !== prevDeps.current.appRoot) {
            changes.appRoot = appRoot
        }
        if (project !== prevDeps.current.project) {
            changes.project = project
        }
        if (iAmAdmin !== prevDeps.current.iAmAdmin) {
            changes.iAmAdmin = iAmAdmin
        }
        if (userEmail !== prevDeps.current.userEmail) {
            changes.userEmail = userEmail
        }
        if (projectAdmins !== prevDeps.current.projectAdmins) {
            changes.projectAdmins = projectAdmins
        }
        if (localTeamStorageData.allowLocalTeamStorage !== prevDeps.current.allowLocalTeamStorage) {
            changes.allowLocalTeamStorage = localTeamStorageData.allowLocalTeamStorage
        }
        if (connections !== prevDeps.current.connections) {
            changes.connections = connections
        }
        if (stateDeps.currentProject !== prevDeps.current.currentProject) {
            changes.currentProject = currentProject
        }
        if (stateDeps.connectionServerId !== prevDeps.current.connectionServerId) {
            changes.connectionServerId = connectionServerId
        }
        if (stateDeps.networkName !== prevDeps.current.networkName) {
            changes.networkName = networkName
        }
        if (stateDeps.connectionInfo !== prevDeps.current.connectionInfo) {
            changes.connectionInfo = connectionInfo
        }
        if (stateDeps.currentProjectIfAdded !== prevDeps.current.currentProjectIfAdded) {
            changes.currentProjectIfAdded = currentProjectIfAdded
        }
        if (stateDeps.isAvailable !== prevDeps.current.isAvailable) {
            changes.isAvailable = isAvailable
        }
        if (stateDeps.isConnected !== prevDeps.current.isConnected) {
            changes.isConnected = isConnected
        }
        // if (tooltipJsx !== prevDeps.current.tooltipJsx) {
        //     changes.tooltipJsx = tooltipJsx
        // }
        if (stateDeps.proxyConnection !== prevDeps.current.proxyConnection) {
            changes.proxyConnection = proxyConnection
        }
        if (Object.keys(changes).length > 0) {
            log('stateDep changes', changes)
        }
        prevDeps.current = {
            appRoot, project, iAmAdmin, userEmail, projectAdmins, allowLocalTeamStorage, connections,
            ...stateDeps
        }
    }

    useEffect(() => {
        if (!getHasElectronContext()) {
            return
        }
        if (safeStableStringify(prevDeps.current.connections) === safeStableStringify(connections)) {
            // only update if connections have changed
            return
        }
        logAndUpdateStateDepChanges()
        updateConnectionsState()
    }, [appRoot, project, iAmAdmin, userEmail, projectAdmins, allowLocalTeamStorage, connections,
        ...stateDepsList])

    const updateConnectionsState = async () => {
        stateHelper((draftState) => {
            log('stateDeps', {
                appRoot, project, iAmAdmin, userEmail, projectAdmins, connections,
                ...stateDeps,
            })
            const MSG_LOCAL_TEAM_STORAGE_IS_NO_LONGER_AVAILABLE = t`Local team storage no longer available`
    
            updateDraft(draftState, { currentProject: project })
            const projectConnection = getProjectLastConnection(project)
            const potentialConnection = projectConnection
            const myServerConnection = connections.find(c => c.connectionInfo.isMyServer)
            const newProxyConnections = connections.filter(c => c.connectionInfo.canProxy)
            const existingProxyConnection = projectConnection ? newProxyConnections.find(c => c.connectionInfo.serverId === projectConnection?.serverId) : undefined
            const prevProxyConnection = newProxyConnections.find(c => c.connectionInfo.serverId === proxyConnection?.connectionInfo.serverId)
            const defaultProxyConnection = newProxyConnections.length > 0 ? (
                    proxyConnection ? prevProxyConnection ?? newProxyConnections[0] : newProxyConnections[0]
             ) : undefined
            const updatedConnection = [existingProxyConnection, defaultProxyConnection, connections.find(
                c => c.connectionInfo.serverId === potentialConnection?.serverId
            )].find(c => c)
            if (myServerConnection) {
                // log('myServerConnection', safeStableStringify({myServerConnection, connections}))
                localTeamStorageData.myServerDiskUsage = myServerConnection.connectionInfo.diskUsage
            } else {
                // log('myServerConnection', safeStableStringify({myServerConnection, connections}))
                localTeamStorageData.myServerDiskUsage = undefined
            }
    
            const userDidSwitchProxy = prevProxyConnection && (prevProxyConnection.connectionInfo.serverId === connectionServerId)
            const updatedConnectionServerId = updatedConnection?.connectionInfo.serverId || ''
            const updatedConnectionInfo = updatedConnection?.connectionInfo
            const updatedComputerName = updatedConnection?.connectionInfo.computerName || ''
            const updatedNetworkName = updatedConnection?.networkName || ''
            const updatedDiskUsage = updatedConnection?.connectionInfo.diskUsage
            const isConnectionAccessibleUpdated = Boolean(updatedConnection?.accessible)
            const updatedConnectionProjects = updatedConnection?.connectionInfo.projects || []
            const isNetworkNameLost = updatedConnectionInfo && networkName && networkName.length > 0 && updatedNetworkName.length === 0
            const computerName = connectionInfo?.computerName || ''
            const diskUsage = connectionInfo?.diskUsage
            const isSameServerId = updatedConnectionServerId === connectionServerId
            if (!isSameServerId) {
                if (isConnected) {
                    // don't switch connection without user's click
                    disconnectFromLANStorage(project)
                    updateDraft(draftState, {
                        isConnected: false,
                        currentProjectIfAdded: '',
                    })
                    if (!userDidSwitchProxy) {
                        maybeDisplayMessage(userError, MSG_LOCAL_TEAM_STORAGE_IS_NO_LONGER_AVAILABLE, updatedNetworkName, computerName)
                    }
                }
                updateDraft(draftState, { connectionServerId: updatedConnectionServerId })
            }
            if (safeStableStringify(updatedConnectionInfo) !== safeStableStringify(connectionInfo)) {
                updateDraft(draftState, { connectionInfo: updatedConnectionInfo })
            }
            if (!existingProxyConnection) {
                updateDraft(draftState, { proxyConnection: defaultProxyConnection })
            }
            // setProxyUrl(defaultProxyConnection?.url || '')
            updateDraft(draftState, { proxyConnections: newProxyConnections })
            if (updatedConnectionInfo && updatedNetworkName === '' && networkName === undefined) {
                maybeDisplayMessage(userError, t`No network found. Try connecting to Wifi?`, updatedNetworkName, '')
            }
            if (updatedNetworkName !== networkName) {
                updateDraft(draftState, { networkName: updatedNetworkName })
            }
            if (isNetworkNameLost) {
                maybeDisplayMessage(userError, t`Network connection lost: ` + networkName, updatedNetworkName, '')
            }
            const isUrlAvailable = !isNetworkNameLost && isConnectionAccessibleUpdated && isSameServerId
            const isNowConnected = !!projectConnection && isSameServerId
            updateDraft(draftState, { isAvailable: isUrlAvailable })
            if (!isUrlAvailable) {
                if (isAvailable /* used to be available */) {
                    if (isSameServerId && (project === currentProject) && (isConnected || isNowConnected)) {
                        if (diskUsage && !updatedDiskUsage) {
                            maybeDisplayMessage(userError, t`Host computer lost access to its storage device`, updatedNetworkName, computerName)
                        } else if (diskUsage && updatedDiskUsage && updatedDiskUsage.available <= (MIN_DISK_SPACE_MB * 1024 * 1024)) {
                            maybeDisplayMessage(userError, t`Host computer storage space is too low`, updatedNetworkName, computerName, updatedDiskUsage)
                        } else {
                            // if was available, then we lost connection. make a toast
                            maybeDisplayMessage(userError, t`Local team storage connection lost`, updatedNetworkName, computerName)
                        }
                        disconnectFromLANStorage(project)
                    } else {
                        if (project !== currentProject){
                            maybeDisplayMessage(displayWarning, t`Switching project resulted in lost connection to local team storage`, updatedNetworkName, computerName)
                        } else {
                            // if was available, then we lost connection. make a toast
                            if (!userDidSwitchProxy) {
                                maybeDisplayMessage(userError, MSG_LOCAL_TEAM_STORAGE_IS_NO_LONGER_AVAILABLE, updatedNetworkName, computerName)
                            }
                        }
                    }
                } 
                updateDraft(draftState, {
                    isConnected: false,
                    currentProjectIfAdded: '',
                })
                return
            }
    
            const MSG_LOCAL_TEAM_STORAGE_AVAILABLE = t`Local team storage available`
            const hasCurrentProject = updatedConnectionProjects.includes(project)
            updateDraft(draftState, { currentProjectIfAdded: hasCurrentProject ? project : '' })
            if (!hasCurrentProject) {
                updateDraft(draftState, { isConnected: false })
                if (userDidSwitchProxy) {
                    // user switched proxy so avoid other messages
                } else if (currentProjectIfAdded === project) {
                    maybeDisplayMessage(userError, t`An admin disabled local team storage for this project`, updatedNetworkName, updatedComputerName)
                    disconnectFromLANStorage(project)
                }
                else if (!isAvailable || project !== currentProject) {
                    maybeDisplayMessage(displaySuccess, MSG_LOCAL_TEAM_STORAGE_AVAILABLE, updatedNetworkName, updatedComputerName, updatedDiskUsage)
                }
                return
            } else {
                updateDraft(draftState, { isConnected: isNowConnected })
                if (!currentProjectIfAdded && !isNowConnected && !userDidSwitchProxy) {
                    maybeDisplayMessage(displaySuccess, t`An admin enabled local team storage for this project`, updatedNetworkName, updatedComputerName, updatedDiskUsage)
                }
                if (isNowConnected) {
                    if (!isConnected) {
                        maybeDisplayMessage(displaySuccess, t`Now using local team storage for this project`, updatedNetworkName, updatedComputerName, updatedDiskUsage)
                    }
                } else if (!isAvailable && !userDidSwitchProxy) {
                    maybeDisplayMessage(displaySuccess, MSG_LOCAL_TEAM_STORAGE_AVAILABLE, updatedNetworkName, updatedComputerName, updatedDiskUsage)
                }
            }
        })
    }


    const fetchConnections = async (ignoreIfAlreadyFetching = false) => {
        if (!ignoreIfAlreadyFetching && isFetching.current) {
            return
        }
        isFetching.current = true
        const start = Date.now()
        try {
            await probeLANStorageConnections({ username: userEmail }, 'LocalTeamStorageButton').then((newConnections) => {
                if (safeStableStringify(newConnections) !== safeStableStringify(connections)) {
                    setConnections(newConnections ?? [])
                }
            })
        } finally {
            isFetching.current = false
        }
        const end = Date.now()
        log('fetchConnections took', end - start, 'ms')
    }

    const throttledFetchConnections = throttle(fetchConnections, 10000, { leading: true })

    useEffect(() => {
        // update Tooltip state
        if (!state.isAvailable && state.proxyConnections.length === 0) {
            return
        }

        // log('tooltip state', { 
        //     currentProjectIfAdded, isAvailable, isConnected, networkName, connectionInfo,
        //     connectionServerId, currentProject, project, iAmAdmin, projectAdmins, proxyConnection
        // })

        const toolTipUseLocalStorage = t`Click to use local team storage`
        if (!state.currentProjectIfAdded) {
            if (!state.connectionInfo?.diskUsage) {
                updateState({ tooltipJsx: makeTooltipJsx([t`Cannot connect: host computer storage device is disconnected`]) })
                return
            }
            if ((state.connectionInfo?.diskUsage?.available / 1024 / 1024) <= MIN_DISK_SPACE_MB) {
                updateState({ tooltipJsx: makeTooltipJsx([t`Cannot connect: host computer storage device is too full`]) })
                return
            }
            if (iAmAdmin) {
                updateState({ tooltipJsx: makeTooltipJsx([toolTipUseLocalStorage, t`(And to enable local team storage for team)`]) })
            } else {
                updateState({ tooltipJsx: makeTooltipJsx([t`An admin must first click to enable local team storage for team:` + ' ' + projectAdmins.join(', ')]) })
            }
            return
        }

        if (state.isConnected) {
            updateState({ tooltipJsx: makeTooltipJsx([t`Click to stop using local team storage`]) })
            return
        }

        if (isDeviceDisconnected) {
            updateState({ tooltipJsx: makeTooltipJsx([MSG_DEVICE_DISCONNECTED]) })
            return
        }

        if (isLowStorage) {
            updateState({ tooltipJsx: makeTooltipJsx([formatLowStorageSpaceMessage(state.connectionInfo?.diskUsage)]) })
            return
        }

        if (iAmAdmin) {
            // is iAmAdmin and has current project
            const disableMessage =
                t`Or hold <SHIFT> key then click to disable project local team storage for team`
            updateState({ tooltipJsx: makeTooltipJsx([toolTipUseLocalStorage, disableMessage]) })
        } else {
            // non-admin
            updateState({ tooltipJsx: makeTooltipJsx([toolTipUseLocalStorage]) })
        }

    }, [state.currentProjectIfAdded, state.isAvailable, state.isConnected, state.networkName, state.connectionInfo,
        state.connectionServerId, state.currentProject, project, iAmAdmin, projectAdmins, state.proxyConnection])
        // update Tooltip state

    useEffect(() => {
        // Temporarily hide and show the tooltip to force re-render
        updateState({ tooltipVisible: false })
        const timeoutId = setTimeout(() => updateState({ tooltipVisible: true }), 1)
        return () => clearTimeout(timeoutId)
    }, [state.tooltipJsx])


    useEffect(() => {
        if (!getHasElectronContext()) {
            return
        }
        throttledFetchConnections() // Initial fetch
        const intervalId = setInterval(throttledFetchConnections, 10000) // Fetch every 10 seconds
        return () => {
            // Cleanup interval on component unmount
            clearInterval(intervalId)
            throttledFetchConnections.cancel()
        }
    }, [userEmail, localTeamStorageData.allowLocalTeamStorage])

    if (!getHasElectronContext()) {
        return null
    }

    if (!state.isAvailable && state.proxyConnections.length === 0) {
        return null
    }

    const nextProxyConnection = () => {
        if (state.proxyConnections.length < 2) {
            return
        }
        const nextIndex = (state.proxyConnections.findIndex(c => c.connectionInfo.serverId === state.connectionServerId) + 1) % state.proxyConnections.length
        const nextProxy = state.proxyConnections[nextIndex]
        console.debug('nextProxyConnection', nextIndex, nextProxy)
        updateState({
            proxyConnection: nextProxy,
            connectionInfo: nextProxy.connectionInfo,
            connectionServerId: nextProxy.connectionInfo.serverId,
        })
        displaySuccess(makeMesssageJsx(t`Switched proxy connection`, nextProxy.networkName, nextProxy.connectionInfo.computerName, nextProxy.connectionInfo.diskUsage ?? null), false)
    }

    const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const computerName = state.connectionInfo?.computerName || ''
        if (!state.currentProjectIfAdded) {
            if (isDeviceDisconnected) {
                maybeDisplayMessage(userError, MSG_DEVICE_DISCONNECTED, state.networkName || '', computerName, undefined, 0)
                return
            }
            if (isLowStorage) {
                maybeDisplayMessage(userError, formatLowStorageSpaceMessage(state.connectionInfo?.diskUsage), state.networkName || '', computerName, undefined, 0)
                return
            }
            
            if (iAmAdmin) {
                // connect
                try {
                    await addStorageProject({ project, adminEmail: userEmail }, 'LocalTeamStorageButton')
                    await fetchConnections(true) // get latest with projects
                    await connectProjectToLANStorage({ project, serverId: state.connectionServerId || '' })
                    updateState({
                        currentProjectIfAdded: project,
                        isConnected: true,
                    })
                    maybeDisplayMessage(displaySuccess, t`Local team storage enabled for team`, state.networkName || '', computerName, undefined, 0)
                    appRoot.reinitializeProjects() /* (async) with VideoCache.initialize() */
                } catch (error) {
                    maybeDisplayMessage(userError, t`Error enabling local team storage for team`, state.networkName || '', computerName, undefined, 0)
                }
            }
            return
        }
        if (state.isConnected) {
            disconnectFromLANStorage(project)
            updateState({ isConnected: false })
            return
        }
        // disconnected and enabled for project
        if (iAmAdmin && event.shiftKey) {
            await removeStorageProject({ project, adminEmail: userEmail }, 'LocalTeamStorageButton')
            maybeDisplayMessage(displaySuccess, t`Disabled local team storage for team`, state.networkName || '', computerName, undefined, 0)
            updateState({
                isConnected: false,
                currentProjectIfAdded: '',
            })
            return
        }
        try {
            // connect
            await connectProjectToLANStorage({ project, serverId: state.connectionServerId || '' })
            updateState({
                isConnected: true,
            })
            appRoot.reinitializeProjects() /* (async) with VideoCache.initialize() */
        } catch (error) {
            maybeDisplayMessage(userError, t`Error using local team storage for team`, state.networkName || '', computerName, undefined, 0)
        }
    }

    const ttId = 'local-team-storage-icon-id'

    return (
        <div
            data-for={ttId}
            data-tip={state.tooltipJsx}
            data-multiline={true}
            data-place="right"
            style={{ display: 'flex', flexDirection: 'row' }}
        >
            {!state.isConnected && state.proxyConnections.length > 1 && <div className="local-team-storage-connections">
                <span
                    onClick={nextProxyConnection}
                >
                    <i className="fa fa-solid fa fa-fw fa-solid fa-chevron-right" />
                </span>
            </div>}
            <div>
                <span className={`local-team-storage-icon local-team-storage-icon-${(!isDeviceDisconnected && !isLowStorage && (iAmAdmin || state.currentProjectIfAdded)) ? 'enabled' : 'disabled'}`}
                    onClick={onClick}
                >
                    <i className={`fa fa-solid fa-network-wired fa-network-wired`} />
                    {!state.isConnected && <span className="local-team-storage-icon-disconnected">
                        <i className={`fa fa-solid fa-slash fa-rotate-90`} />
                    </span>}
                </span>
                {state.tooltipVisible && (
                <ReactTooltip
                    id={ttId}
                    backgroundColor="gray"
                    effect="solid"
                    overridePosition={(
                        { left, top },
                        currentEvent, currentTarget, node
                    ) => {
                        const d = document.documentElement
                        left = Math.min(d.clientWidth - (node?.clientWidth || 0), left)
                        top = Math.min(d.clientHeight - (node?.clientHeight || 0), top)
                        left = Math.max(0, left)
                        top = Math.max(0, top)
                        return { top, left }
                    }}
                /* NOTE: `multiline` requires that we don't embed tooltip as ReactTooltip child */
                >
                    {state.tooltipJsx}
                </ReactTooltip>
                )}
            </div>
        </div>
    )
})
