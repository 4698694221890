import { FC, useEffect, useRef, useState } from 'react'
import { t } from 'ttag'
import { observer } from 'mobx-react'

import { Root } from '../../models3/Root'
import './ProjectSettings.css'
import { Switch } from '../utils/Switch'
import { setAllowHosting, canWriteToFolder, loadHostFolder, saveHostFolder, getAllowHosting } from '../../models3/SlttAppStorage'
import { ToggleEditor } from '../utils/ToggleEditor'
import AutowidthInput from '../utils/AutowidthInput'
import { displaySuccess } from '../utils/Errors'
import { formatLowStorageSpaceMessage, formatStorageSpaceMessage, localTeamStorageData, tInaccessible } from '../../models3/lanStorage/LocalTeamStorage'
import { DiskUsage, HOST_FOLDER_ERROR_CODE_ERROR_ACCESSING_FOLDER, HOST_FOLDER_ERROR_CODE_EXTENSION_IS_NOT_ALLOWED_IN_FOLDER, HOST_FOLDER_ERROR_CODE_FULL_DRIVE_PATH_REQUIRED, HOST_FOLDER_ERROR_CODE_PATH_EXISTS_BUT_NOT_DIRECTORY, HOST_FOLDER_ERROR_CODE_UNKNOWN_ERROR, HOST_FOLDER_ERROR_CODE_WRITE_PERMISSION_ERROR } from '../../models3/lanStorage/hostFolder.d'
import { MIN_DISK_SPACE_MB } from '../../models3/lanStorage/connections.d'

interface ILocalTeamStorageSettings {
    rt: Root,
}

export const LocalTeamStorageSettings: FC<ILocalTeamStorageSettings> = observer(({}) => {
    const [choice, setChoice] = useState(false)

    useEffect(() => {
        getAllowHosting('LocalTeamStorageSettings').then((response) => {
            setChoice(response.allowHosting)
        })
    }, [])

    const handleToggle = async () => {
        const newChoice = !choice
        localTeamStorageData.allowLocalTeamStorage = newChoice
        setChoice(newChoice)
        await setAllowHosting({ allowHosting: newChoice}, 'LocalTeamStorageSettings')
    }

    return (
        <div className="local-team-storage">
            <h3>{t`Local Team Storage Settings`}</h3>
            <div className="container-fluid">
                <label className="host-local-team-storage-label">
                    <div className="host-local-team-storage-switch-container">
                        <Switch
                            className=""
                            value={choice}
                            setValue={handleToggle}
                            enabled={true}
                            tooltip={''}
                        />
                        <span className="host-local-team-storage-label-text">{t`Allow admin to host local team storage for any project using my computer's storage`}</span>
                    </div>
                </label>
                {choice && (
                    <StorageLocationEditor hideEditorControls={false} />
                )}
            </div>
        </div>
    )
})

const convertErrorCodeToMessage = (errorCode: string) => {
    switch (errorCode) {
        case HOST_FOLDER_ERROR_CODE_EXTENSION_IS_NOT_ALLOWED_IN_FOLDER:
            return t`Extension is not allowed in folder path:`
        case HOST_FOLDER_ERROR_CODE_FULL_DRIVE_PATH_REQUIRED:
            return t`Full drive path required.`
        case HOST_FOLDER_ERROR_CODE_PATH_EXISTS_BUT_NOT_DIRECTORY:
            return t`Path exists but is not a directory.`
        case HOST_FOLDER_ERROR_CODE_WRITE_PERMISSION_ERROR:
            return t`Write permission error:`
        case HOST_FOLDER_ERROR_CODE_ERROR_ACCESSING_FOLDER:
            return t`Error accessing folder:`
        case HOST_FOLDER_ERROR_CODE_UNKNOWN_ERROR:
        default:
            return t`Unknown error:`
    }
}

interface IStorageLocationEditor {
    hideEditorControls: boolean
}
export const StorageLocationEditor: FC<IStorageLocationEditor> = observer(({
    hideEditorControls,
}
) => {
    const [storageLocation, setStorageLocation] = useState('')
    const [editing, setEditing] = useState(false)
    const refTotalSpace = useRef<number>(0)

    enum InputStatus {
        inactive = 'inactive',
        warning = 'warning',
        error = 'error',
        success = 'success'
    }
    const [inputStatus, setInputStatus] = useState<InputStatus>(InputStatus.inactive)
    const inputElement = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // load the storage location from the server
        loadHostFolder('StorageLocationEditor').then(async (response) => {
            await setStorageLocationAndStatus(response.hostFolder || response.defaultFolder)
            // if folder is empty then set to editing mode
            if (!response.hostFolder) {
                setEditing(true)
            }
        }).catch((error) => {
            setInputStatus(InputStatus.warning)
            setValidationMessage(error.message)
            setEditing(true)
        })
    }, [])

    useEffect(() => {
        if (editing && inputElement.current) {
            inputElement.current.focus()
        }
    }, [editing])

    const [validationMessage, setValidationMessage] = useState('')

    const debounceTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    useEffect(() => {
        return () => {
            // Clear the timer on component unmount
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current)
            }
        }
    }, [])

    const setDiskUsageStatus = (diskUsage: DiskUsage | undefined) => {
        if (!diskUsage) {
            setInputStatus(InputStatus.error)
            setValidationMessage(tInaccessible)
            return
        } else if (diskUsage.available <= (MIN_DISK_SPACE_MB * 1024 * 1024)) {
            setInputStatus(InputStatus.warning)
            setValidationMessage(formatLowStorageSpaceMessage(diskUsage))
            return
        }
        setInputStatus(InputStatus.success)
        const storageSpace = formatStorageSpaceMessage(diskUsage)
        setValidationMessage(storageSpace)
    }

    useEffect(() => {
        if (storageLocation && !editing) {
            const { myServerDiskUsage: diskUsage } = localTeamStorageData
            if (refTotalSpace.current === 0) {
                refTotalSpace.current = (diskUsage?.total ?? 0)
            }
            if (refTotalSpace.current === (diskUsage?.total ?? 0)) {
                // only update the status if the total matches what we expect
                setDiskUsageStatus(diskUsage)
            }
        }
    }, [storageLocation, editing, localTeamStorageData.myServerDiskUsage])

    const updateStorageLocationStatus = async (folder: string) => {
        const response = await canWriteToFolder({ folderPath: folder.trim() }, 'LocalTeamStorageSettings')
        if (response.errorCode) {
            const errorMessage = convertErrorCodeToMessage(response.errorCode)
            const { errorInfo } = response
            const message = `${errorMessage} ${errorInfo}`
            setInputStatus(InputStatus.error)
            setValidationMessage(message)
        } else {
            const { diskUsage } = response
            refTotalSpace.current = (diskUsage?.total ?? 0)
            setDiskUsageStatus(diskUsage)
        }
    }

    const handleStorageLocationChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputStatus(InputStatus.inactive)
        setValidationMessage(t`Processing...`)
        const folder = event.target.value
        setStorageLocation(folder)
        // Clear the previous timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current)
        }
        // Set a new timer
        debounceTimerRef.current = setTimeout(async () => {
            await updateStorageLocationStatus(folder)
        }, 1000)
    }

    const readOnly = !editing

    const setStorageLocationAndStatus = async (folder: string) => {
        setStorageLocation(folder)
        await updateStorageLocationStatus(folder)
    }

    async function handleClickOk() {
        console.log('confirmStorageLocation', storageLocation)
        try {
            setValidationMessage(t`Processing...`)
            const response = await saveHostFolder({ hostFolder: storageLocation }, 'LocalTeamStorageSettings')
            await setStorageLocationAndStatus(response.finalHostFolder)
            displaySuccess(t`Storage location saved.`)
        } catch (error) {
            setInputStatus(InputStatus.warning)
            throw error
        }
    }

    async function handleCancel() {
        console.log('cancelChanges')
        const response = await loadHostFolder('LocalTeamStorageSettings')
        await setStorageLocationAndStatus(response.hostFolder || response.defaultFolder)
        if (!response.hostFolder) {
            setEditing(true)
        }
    }

    return <div className="host-storage-location-editor">
        <ToggleEditor
            allowEditing={true}
            isEditing={editing}
            setIsEditing={setEditing}
            hideEditorControls={hideEditorControls}
            title={t`Storage Location`}
            iconClass="far fa-fw fa-folder"
            enableClickOk={[InputStatus.success, InputStatus.warning].includes(inputStatus)}
            handleClickOk={handleClickOk}
            handleCancel={handleCancel}
            help={undefined}
        >
        <div className="host-local-team-storage-location-container">
            <div>
                <AutowidthInput
                    ref={inputElement}
                    className={`host-local-team-storage-location-input text-${inputStatus}`}
                    placeholderIsMinWidth={true}
                    placeholder={''}
                    value={storageLocation}
                    disabled={readOnly}
                    onChange={handleStorageLocationChange} />
            </div>
        </div>
    </ToggleEditor>
    {
        validationMessage && (
            <div className={`validation-message message-${inputStatus}`}>
                {validationMessage}
            </div>
        )
    }</div>
})

