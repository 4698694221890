// VideoToolbar allows controlling playing and recording by means of actions
// and variable of rt (Videort)

import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { confirmAlert } from '../utils/ConfirmAlert'
import _ from 'underscore'
import { t } from 'ttag'
import _debug from 'debug'
import assert from 'assert'

import routePrefix from '../app/routePrefix'
import { displayError, displayInfo } from '../utils/Errors'
import { Root } from '../../models3/Root'
import {
    RecordButton, StopButton, CreateNoteButton, TrashButton, PlayButton, PauseButton,
    EditButton
} from '../utils/Buttons'
import { PassageSegmentsIcon, PassageGlossIcon, PassageVideoReferenceIcon, DownloadFullVideoIcon, DesktopIcon, ClipboardIcon, EllipsisIcon } from '../utils/Icons'
import PassageVideoSelector from '../passages/PassageVideoSelector'
import { PassageVideoLabelEditor } from '../passages/PassageVideoLabelEditor'
import { Passage, PassageNote, PassageVideo } from '../../models3/ProjectModels'
import { UnresolvedTextNotification } from '../notifications/Notifications'
import { createVerseReference } from './VerseReferenceEditor'
import VideoCompressor from '../utils/VideoCompressor'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { completemulti, createmulti } from '../../models3/API'
import { ELECTRON_BASE_URL, getOriginOrBaseUrl } from '../utils/LocationHistory'
import { fmt } from '../utils/Fmt'
import { SlttHelp2 } from '../utils/SLTTHelp2'
import { TRLSettingsIcon } from '../TRL/Authoring/TRLSettingsIcon'
import { Theme } from '../utils/LocalStorage'
import { getAllVisibleGlosses } from '../glosses/VisibleGlosses'
import { useUserCanDelete } from '../utils/useUserCanDelete'

const log = _debug('sltt:VideoToolbar')

interface IVideoToolbar {
    rt: Root,
    playAllVideos: () => void,
    playCurrentVideo: () => void,
    pause: () => void,
    record: () => void,
    stopRecording: () => void,
    setShowDetachedPlayer?: (value: boolean) => void,
    openTRLPassageSettings: () => void,
}

export const VideoToolbar: FC<IVideoToolbar> = observer(
    ({ rt, playAllVideos, playCurrentVideo, pause, record, stopRecording, setShowDetachedPlayer, openTRLPassageSettings }) => {
        let { passage, passageVideo, passageSegment, playing, recording,
            hardNotificationCutoff, iAmAdmin, iAmInterpreter, iAmTranslator, iAmConsultant,
            canViewConsultantOnlyFeatures, editingSegment, useMobileLayout,
            dateFormatter, canPlayThrough, project, isTRLProject, segmentTabEditingInProgress } = rt
        let { plans } = project

        const [editingVideoLabel, setEditingVideoLabel] = useState(false)
        const userCanDeleteVideo = useUserCanDelete(passageVideo)

        let selectionPresent = rt.selectionPresent()

        let playShown = !playing && !recording
        let recordShown = !playing && !recording
        let pauseShown = playing
        let stopShown = recording

        let compressingVideo = passage?.videoBeingCompressed || false
        let enabled = !segmentTabEditingInProgress && !compressingVideo

        let playEnabled = passageVideo && canPlayThrough && !recording && enabled

        let recordEnabled = passage && !playing && !recording && iAmTranslator && enabled
        let createNoteEnabled = passageVideo && !playing && !recording && iAmInterpreter && enabled

        let createGlossEnabled = passageVideo && !recording && iAmInterpreter && enabled
        let createSegmentEnabled = !playing && createGlossEnabled && passageSegment && enabled
        let verseReferenceEnabled = passageVideo && !playing && enabled && iAmConsultant
        let verseLabelEnabled = passageVideo && !playing && enabled && iAmInterpreter
        let trlEnabled = !!passageVideo && enabled
        let trlTooltip = !passageVideo && enabled ?
            t`Please add a video in order to create a thumbnail from it for the Translation Resource Library.` :
            t`Update passage Translation Resource Library settings`

        let dropdownVideos = passage ? (rt.iAmAdmin ? passage.videos : passage.videosNotDeleted).filter(v => !v.isPatch) : []

        let currentVisibleVideo = passageVideo || (dropdownVideos.length > 0 && dropdownVideos[dropdownVideos.length - 1]) || undefined
        let cutoff = hardNotificationCutoff()
        let activeVideos = dropdownVideos.filter(v => !v.removed)
        let unresolvedNote: PassageNote | null = null
        if (activeVideos.length) {
            unresolvedNote = activeVideos[activeVideos.length - 1].mostRecentUnresolvedNote(passage!, cutoff, canViewConsultantOnlyFeatures)
        }

        let unresolvedTextNotificationDisplayed = !!passage && unresolvedNote && !!passageVideo
        let unresolvedTextNotificationEnabled = unresolvedTextNotificationDisplayed && enabled
        let displayPassageVideoSelector = !!passage && dropdownVideos.length > 0
        let changeMainVideoEnabled = displayPassageVideoSelector && enabled

        // If I try to move the following to a .css file it stops working.
        // I have no idea why.
        let passageSegmentIconStyle = {
            cursor: 'pointer',
            verticalAlign: 'middle',
        }

        const isMostRecentVideo = (video: PassageVideo, videos: PassageVideo[]) => _.sortBy(videos, '').reverse()[0]._id === video._id

        const onSelectVideo = async (video: PassageVideo) => {
            if (video.removed) {
                await undeleteVideo(video)
            } else {
                await makeSelection(video)
            }
        }

        const goToNote = async (note: PassageNote) => {
            let { passage } = rt
            if (!passage) {
                return
            }
            let video = passage.findVideo(note._id) || null
            video = video?.baseVideo(passage) || video
            await rt.setPassage(passage)
            await rt.setPassageVideo(video)
            rt.setNote(note)
        }

        const makeSelection = async (passageVideo: PassageVideo) => {
            let { passage } = rt
            if (!passage) return

            try {
                await rt.setPassageVideo(passageVideo)
            } catch (error) {
                displayError(error)
            }
        }

        const undeleteVideo = async (passageVideo: PassageVideo) => {
            let { passage, iAmAdmin } = rt
            if (!passage || !iAmAdmin) return

            try {
                await passage.undeleteVideo(passageVideo)
                await makeSelection(passageVideo)
            } catch (error) {
                displayError(error)
            }
        }

        const confirmDeletion = (doDeletion: () => void) => {
            confirmAlert({
                title: /* translator: important */ t`Delete Video!?`,
                message: /* translator: important */ t`There is no easy way to get this video back if you delete it!`,
                confirmLabel: /* translator: important */ t`Delete video!`,
                cancelLabel: /* translator: important */ t`Keep video.`,
                onConfirm: doDeletion,
                onCancel: () => { },
            })
        }

        const deleteVideo = async () => {
            let { passage, passageVideo } = rt
            if (!passage || !passageVideo) {
                displayError(t`System Error` + ': Status change failed')
                return
            }

            confirmDeletion(() => {
                if (!passageVideo) {
                    displayError(t`System Error` + ': Status change failed')
                    return
                }

                passage!.removeVideo(passageVideo._id)
                    .then(() => {
                        let i = passage!.videosNotDeleted.length
                        rt.setPassageVideo(i > 0 ? passage!.videosNotDeleted[i - 1] : null)
                    })
                    .catch(displayError)
            })
        }

        const createPassageSegment = async () => {
            const { passage, passageVideo, passageSegment, currentTime } = rt
            if (!passage || !passageVideo || !passageSegment) return


            if (passageSegment.isPatched) {
                const patchVideo = passageSegment.patchVideo(passage)
                assert(patchVideo, 'createPassageSegment: patchVideo should not be undefined')
                const position = patchVideo!.timeToPosition(passage, currentTime)
                
                // Try to split patch.
                // If position is too close to the start or end of the patch, do nothing.
                await patchVideo.splitPatch(position, passage)

                // reset the current video and time to force the video for the new
                // patched segment to be loaded
                rt.setPassageVideo(passageVideo)
                rt.resetCurrentTime(currentTime + 0.01)
            } else {
                const position = passageVideo.timeToPosition(passage, rt.currentTime)
                assert(position !== undefined, 'createPassageSegment: position === undefined')
                await passageVideo.addSegment(position)
            }
            
            rt.resetCurrentTime(rt.currentTime) // will force current segment to be set
        }

        const createPassageGloss = () => {
            rt.createPassageGloss()
                .catch(displayError)
        }

        const openEditor = (pv: PassageVideo) => {
            setEditingVideoLabel(true)
        }
        const handleEditClick = (e: React.MouseEvent<HTMLInputElement>) => {
            if (currentVisibleVideo) {
                openEditor(currentVisibleVideo)
            }
            setEditingVideoLabel(true)
            e.stopPropagation()
        }
        return (
            <div className="video-toolbar">
                <div className="video-toolbar-left">
                    {playShown &&
                        <SlttHelp2 id="playbutton"
                            tooltip={selectionPresent ? /* translator: important */ t`Play selection.` : /* translator: important */ t`Play.`}
                            place="bottom"
                            video="a1">
                            <PlayButton
                                enabled={playEnabled}
                                selectionPresent={selectionPresent}
                                tooltip=""
                                className="main-video-play-button"
                                onClick={async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                    if (e.shiftKey) {
                                        playAllVideos()
                                    } else {
                                        playCurrentVideo()
                                    }
                                }} />
                        </SlttHelp2>
                    }

                    {pauseShown &&
                        <PauseButton
                            className='main-video-pause-button'
                            enabled={true}
                            tooltip={/* translator: important */ t`Pause.`}
                            onClick={pause} />
                    }

                    {recordShown &&
                        <SlttHelp2 id="passages.html#record"
                            tooltip={/* translator: important */ t`(Re)record passage. Click square Stop icon to end.`}
                            place="bottom"
                            video="a2">
                            <RecordButton
                                tooltip=""
                                selectionPresent={false}
                                enabled={recordEnabled}
                                className="sl-record-button sl-record-video-button"
                                onClick={record} />
                        </SlttHelp2>
                    }

                    {stopShown &&
                        <StopButton
                            className='main-video-stop-button'
                            enabled={true}
                            tooltip={/* translator: important */ t`Stop recording.`}
                            onClick={stopRecording} />
                    }

                    <CreateNoteButton
                        enabled={createNoteEnabled}
                        onClick={() => {
                            if (passage && passageVideo) {
                                let notePosition = passageVideo.timeToPosition(passage, rt.currentTime)
                                if (notePosition < .05) {
                                    displayInfo(/* translator: important */ t`Cannot create note at time 0.0`)
                                    return
                                }

                                let video = passageVideo.timeToVideo(passage, rt.currentTime)

                                let selectionStartPosition: number | undefined
                                let selectionEndPosition: number | undefined
                                if (rt.selectionPresent()) {
                                    selectionStartPosition = passageVideo.timeToPosition(passage, rt.selectionStartTime - .8)
                                    selectionEndPosition = passageVideo.timeToPosition(passage, rt.selectionEndTime + .8)
                                    notePosition = (selectionStartPosition + selectionEndPosition!) / 2
                                }

                                let note = video.createNote.bind(video)(notePosition, selectionStartPosition, selectionEndPosition)

                                // don't use rt.setNote, it changes rt.currentTime which we don't want
                                rt.note = note
                            }
                        }} />

                    {!useMobileLayout && (
                        <>
                            <PassageSegmentsIcon
                                className={'sl-create-passage-segment'}
                                style={passageSegmentIconStyle}
                                tooltip={/* translator: important */ t`Create new segment in this video`}
                                place="bottom"
                                enabledColor={`${Theme.getColor('black', 'white')}`}
                                nonEnabledColor={`${Theme.getColor('grey', 'lightgrey')}`}
                                id="segments.html#segmentscreate"
                                enabled={createSegmentEnabled}
                                onClick={() => createPassageSegment().catch(displayError)} />
                            <PassageGlossIcon
                                className={
                                    'sl-create-passage-gloss' +
                                    (selectionPresent ? ' sl-create-passage-gloss-from-selection' : '')
                                }
                                style={passageSegmentIconStyle}
                                tooltip={/* translator: important */ t`Create new gloss in this video. Shortcut: \\`}
                                enabled={createGlossEnabled}
                                enabledColor={`${Theme.getColor('black', 'white')}`}
                                nonEnabledColor={`${Theme.getColor('grey', 'lightgrey')}`}
                                onClick={createPassageGloss} />
                            <PassageVideoReferenceIcon
                                className='sl-create-passage-reference'
                                style={passageSegmentIconStyle}
                                enabledColor={`${Theme.getColor('black', 'white')}`}
                                nonEnabledColor={`${Theme.getColor('grey', 'lightgrey')}`}
                                onClick={() => createVerseReference(rt)}
                                tooltip={/* translator: important */ t`Create new verse reference`}
                                enabled={verseReferenceEnabled}
                            />
                            {isTRLProject && <TRLSettingsIcon
                                onClick={openTRLPassageSettings}
                                tooltip={trlTooltip}
                                enabled={trlEnabled}
                            />}
                        </>
                    )}
                </div>

                <div className="video-toolbar-right">
                    {userCanDeleteVideo && !useMobileLayout &&
                        <SlttHelp2 id="deletevideo"
                            tooltip={/* translator: important */ t`Delete video`}
                            place="bottom"
                            video="a7">
                            <TrashButton
                                enabled={enabled}
                                buttonClassName=''
                                className="delete-video-button"
                                onClick={deleteVideo}
                                tooltip={''} />
                        </SlttHelp2>
                    }
                    {!editingVideoLabel && displayPassageVideoSelector && (
                        <PassageVideoSelector
                            enabled={changeMainVideoEnabled}
                            videos={dropdownVideos}
                            currentVisibleVideo={currentVisibleVideo!}
                            onSelect={onSelectVideo}
                            dateFormatter={dateFormatter}
                            passageVideoNotification={rt}
                            plan={plans.length ? plans[0] : undefined}
                        />
                    )}
                    {editingVideoLabel && displayPassageVideoSelector &&
                        <PassageVideoLabelEditor
                            enabled={changeMainVideoEnabled}
                            videos={dropdownVideos}  
                            currentVisibleVideo={currentVisibleVideo!}
                            dateFormatter={dateFormatter}
                            plan={plans.length ? plans[0] : undefined}
                            setEditingVideoLabel={setEditingVideoLabel}
                        />
                    }
                    {!editingVideoLabel && (
                        <div className="video-toolbar-button-class">
                            {displayPassageVideoSelector && <EditButton
                                enabled={verseLabelEnabled}
                                onClick={handleEditClick}
                                className='passage-video-label-edit-button'
                                tooltip={t`Edit Label for this Video`}
                            />}
                            {unresolvedTextNotificationDisplayed && !useMobileLayout &&
                                <UnresolvedTextNotification
                                    className='video-toolbar-notification sl-fa-button'
                                    tooltip={/* translator: important */ t`Unresolved notes exist`}
                                    onClick={async e => {
                                        if (unresolvedTextNotificationEnabled && unresolvedNote) {
                                            goToNote(unresolvedNote)
                                        }
                                    }}
                                />
                            }
                            <RareFunctionsDropdown {...{ rt, setShowDetachedPlayer }} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
)

type RareFunctionsDropdownProps = {
    rt: Root,
    setShowDetachedPlayer?: (value: boolean) => void,
}

async function checkIfServerRunning(setProgressMessage: (message: string) => void) {
    setProgressMessage(t`Initializing...`)

    let isServerRunning = await VideoCompressor.checkIfServerRunning()
    if (isServerRunning) return true

    setProgressMessage('')
    return false
}

async function downloadVideo(rt: Root, passage: Passage) {
    if (!passage) { return }
    const setProgressMessage = (message: string) => passage.compressionProgressMessage = message

    let { currentVideos } = rt
    let { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = rt.project
    try {
        if (!(await checkIfServerRunning(setProgressMessage))) { return }

        let compressor = new VideoCompressor(compressedVideoQuality, compressedVideoResolution, maxVideoSizeMB, setProgressMessage)
        let concatenatedData = await compressor.concatenateVideos(currentVideos, passage, -1, -1)

        // Download resulting file
        let href = window.URL.createObjectURL(concatenatedData)
        let link = document.createElement('a')
        link.setAttribute('href', href)
        link.setAttribute('download', `${passage.name}.mp4`)
        link.click()
    } catch (error) {
        log(error)
        setProgressMessage('')
        displayError(t`Could not download video.`)
    }
}

async function downloadGlosses(passage: Passage | null, passageVideo: PassageVideo | null) {
    if (!passage || !passageVideo) { return }

    const glosses = getAllVisibleGlosses(passage, passageVideo)
    const _glosses = { glosses: glosses.map(g => ({ gloss: g.text, position: g.position } )) }
    const data = new Blob([JSON.stringify(_glosses)], { type: 'application/json' })
    
    // Download resulting file
    let href = window.URL.createObjectURL(data)
    let link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute('download', `${passage.name}.glosses.json`)
    link.click()
}

async function uploadFile(file: File, _id: string, type: string, setProgressMessage: (message: string) => void) {
    const FILE_CHUNK_SIZE = 6000000 // using 6_000_000 complicates the babel presets required
    let count = Math.ceil(file.size / FILE_CHUNK_SIZE)

    // Create a multi part upload
    let { uploadId, urls } = await createmulti(_id, type, count, true)
    let pieces = urls[0].split('/')
    let baseUrl = pieces.slice(0, 3).join('/')
    log('uploadFile/createmulti', fmt({ _id, type, count, uploadId, urls, baseUrl }))

    // delete axios.defaults.headers.put['Content-Type']

    const parts = []

    let errorCount = 0

    for (let index = 0; index < urls.length;) {
        const start = index * FILE_CHUNK_SIZE
        const end = (index + 1) * FILE_CHUNK_SIZE
        const blob = index < urls.length
            ? file.slice(start, end)
            : file.slice(start)

        let options = {
            method: 'PUT',
            body: blob,
        }

        try {
            log(`uploadFile/uploading[${index}]...`, fmt({ errorCount, url: urls[index] }))
            setProgressMessage('Uploading ... ' + `${index + 1}/${urls.length} ${'**********'.slice(0, errorCount)}`)

            let response = await fetch(urls[index], options)

            // Headers include extra double quotes at start and end.
            // Slice these off.
            let ETag = response.headers.get('ETag')?.slice(1, -1)
            log('uploadFile/response', fmt({ index, ETag }))
            if (!ETag) throw Error('uploadFile/No ETag returned')

            ++index
            errorCount = 0
            parts.push({
                ETag,
                PartNumber: index
            })
        } catch (error) {
            log('###uploadFile error', error)
            errorCount += 1
            if (errorCount > 10) {
                throw Error('Upload failed. Too many errors.')
            }
            continue
        }
    }

    let stage = baseUrl.includes('-prd') ? 'prd' : 'dev'
    let viewerUrl = `https://sltt-hosting-${stage}.s3.amazonaws.com/viewer.html?${_id}`

    // Complete the multipart upload operation
    try {
        log('uploadFile/uploading DONE', fmt({ parts }))
        let shortUrl = await completemulti(_id, uploadId, parts, viewerUrl)
        log('uploadFile/completemulti DONE')
        return shortUrl
    } catch (error) {
        throw Error('Could not complete upload.')
    }
}

async function createLinkToSelection(rt: Root, passage: Passage) {
    let { currentVideos, selectionStartTime, selectionEndTime } = rt
    if (!passage) { return }
    if (selectionStartTime < 0 || selectionEndTime < 0) return

    const setProgressMessage = (message: string) => passage.compressionProgressMessage = message

    let { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = rt.project
    try {
        if (!(await checkIfServerRunning(setProgressMessage))) { return }

        let compressor = new VideoCompressor(compressedVideoQuality, compressedVideoResolution, maxVideoSizeMB, setProgressMessage)
        let concatenatedData = await compressor.concatenateVideos(currentVideos, passage, selectionStartTime, selectionEndTime)

        const now = Date.now()

        let _id = `${rt.project.name}/public/${now}.mp4`
        let shortUrl = await uploadFile(concatenatedData, _id, 'video/mp4', setProgressMessage)

        await navigator.clipboard.writeText(shortUrl)
        displayInfo(`Link (${shortUrl}) copied to clipboard. Anyone can view this link.`)
    } catch (error) {
        log(error)
        displayError((error as Error).message)
        setProgressMessage('')
        return
    }

    setProgressMessage('')
}

async function createLinkToVideo(rt: Root) {
    let { passageVideo, currentTime, project } = rt
    if (!passageVideo) {
        return
    }
    const origin = getOriginOrBaseUrl()
    // TODO: eventually we'll want to implement a custom protocol in sltt-app (e.g. `sltt-app://`) so 
    // links can be opened in the app. For now, we'll just use the web version.
    const finalOrigin = origin === ELECTRON_BASE_URL ? 'https://sltt-bible.net' : origin
    let text = `${finalOrigin}${routePrefix}/#/index.html?project=${project.name}&id=${passageVideo._id}&time=${currentTime}`
    try {
        await navigator.clipboard.writeText(text)
        displayInfo(t`Link copied to clipboard. Only your team can view this link.`)
    } catch (error) {
        displayError(error, 'Failed to copy!')
    }
}

const RareFunctionsDropdown: FC<RareFunctionsDropdownProps> = ({ rt, setShowDetachedPlayer }) => {
    let { passage, canPlayThrough, useMobileLayout } = rt

    let compressingVideo = passage?.videoBeingCompressed || false
    let compressorAvailable = !useMobileLayout && canPlayThrough && !compressingVideo

    return (
        <Dropdown className='video-toolbar-hamburger-menu' id='video-toolbar-hamburger-menu' pullRight>
            <Dropdown.Toggle className='video-toolbar-hamburger-menu-toggle' noCaret>
                <EllipsisIcon className='main-video-see-more-icon' tooltip={t`See more options`} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {compressorAvailable && (
                    <MenuItem
                        className='video-toolbar-hamburger-menu-item'
                        onClick={() => downloadVideo(rt, passage!)}
                    >
                        <>
                            <DownloadFullVideoIcon
                                className='sl-download-full-video-icon fa-fw'
                                tooltip=''
                            />
                            {t`Download video`}
                        </>
                    </MenuItem>
                )}
                {setShowDetachedPlayer && !compressingVideo && (
                    <MenuItem
                        className='video-toolbar-hamburger-menu-item'
                        onClick={() => setShowDetachedPlayer?.(true)}
                    >
                        <>
                            <DesktopIcon
                                className='detach-icon fa-fw'
                                tooltip=''
                            />
                            {t`Detach video player`}
                        </>
                    </MenuItem>
                )}
                <MenuItem
                    className='video-toolbar-hamburger-menu-item'
                    onClick={() => createLinkToVideo(rt)}
                >
                    <>
                        <ClipboardIcon
                            className='main-video-clipboard-icon fa-fw'
                            tooltip=''
                        />
                        {t`Copy link for video (team members only can view)`}
                    </>
                </MenuItem>
                {compressorAvailable && (
                    <MenuItem
                        disabled={!rt.selectionPresent()}
                        className='video-toolbar-hamburger-menu-item'
                        onClick={() => createLinkToSelection(rt, passage!)} >
                        <>
                            <ClipboardIcon
                                className='main-video-clipboard-icon fa-fw'
                                tooltip=''
                            />
                            {t`Copy link for video selection (anyone can view)`}
                        </>
                    </MenuItem>
                )}
                <MenuItem
                    className='video-toolbar-hamburger-menu-item'
                    onClick={() => downloadGlosses(passage, rt.passageVideo)}
                >
                    <>
                        <DownloadFullVideoIcon
                            className='sl-download-full-video-icon fa-fw'
                            tooltip=''
                        />
                        {t`Download glosses`}
                    </>
                </MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    )
}
