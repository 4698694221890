import { stringify as safeStableStringify } from 'safe-stable-stringify'
import { t } from 'ttag'
import _debug from 'debug'
import { IDBModDoc } from './DBTypes'
import { ElectronAPI } from './ElectronAPI'
import { DOCS_API_GET_LOCAL_SPOTS, DOCS_API_GET_REMOTE_SPOTS, DOCS_API_GET_STORED_LOCAL_CLIENT_IDS, DOCS_API_RETRIEVE_LOCAL_CLIENT_DOCS, DOCS_API_RETRIEVE_REMOTE_DOCS, DOCS_API_SAVE_LOCAL_SPOTS, DOCS_API_SAVE_REMOTE_SPOTS, DOCS_API_STORE_LOCAL_DOCS, DOCS_API_STORE_REMOTE_DOCS, GetLocalSpotsArgs, GetLocalSpotsResponse, GetRemoteSpotsArgs, GetRemoteSpotsResponse, GetStoredLocalClientIdsArgs, GetStoredLocalClientIdsResponse, RetrieveLocalClientDocsArgs, RetrieveLocalClientDocsResponse, RetrieveRemoteDocsArgs, RetrieveRemoteDocsResponse, SaveLocalSpotsArgs, SaveLocalSpotsResponse, SaveRemoteSpotsArgs, SaveRemoteSpotsResponse, StoreLocalDocsArgs, StoreLocalDocsResponse, StoreRemoteDocsArgs, StoreRemoteDocsResponse } from './lanStorage/docs.d'
import { ListVcrFilesArgs, ListVcrFilesResponse, RetrieveVcrsArgs, RetrieveVcrsResponse, StoreVcrArgs, StoreVcrResponse, VIDEO_CACHE_RECORDS_API_LIST_VCR_FILES, VIDEO_CACHE_RECORDS_API_RETRIEVE_VCRS, VIDEO_CACHE_RECORDS_API_STORE_VCR } from './lanStorage/vcrs.d'
import { generate4DigitHex } from './utils/hashUtils'
import { CLIENTS_API_REGISTER_CLIENT_USER, RegisterClientUserArgs, RegisterClientUserResponse } from './lanStorage/clients.d'
import { BLOBS_API_RETRIEVE_ALL_BLOB_IDS, BLOBS_API_RETRIEVE_BLOB, BLOBS_API_STORE_BLOB, RetrieveAllBlobIdsArgs, RetrieveAllBlobIdsResponse, RetrieveBlobArgs, RetrieveBlobResponse, StoreBlobArgs, StoreBlobResponse } from './lanStorage/blobs.d'
import { AddStorageProjectArgs, AddStorageProjectResponse, ConnectArgs, CONNECTIONS_API_ADD_STORAGE_PROJECT, CONNECTIONS_API_CONNECT, CONNECTIONS_API_GET_STORAGE_PROJECTS, CONNECTIONS_API_PROBE, CONNECTIONS_API_REMOVE_STORAGE_PROJECT, CONNECTIONS_API_START_UDP, ConnectResponse, GetStorageProjectsArgs, GetStorageProjectsResponse, ProbeConnectionsArgs, ProbeConnectionsResponse, RemoveStorageProjectArgs, RemoveStorageProjectResponse, StartUdpArgs, StartUdpResponse } from './lanStorage/connections.d'
import { logError, userError } from '../components/utils/Errors'
import { IProjectEntity } from './ProjectModels'
import { CanWriteToFolderArgs, CanWriteToFolderResponse, GetAllowHostingArgs, GetAllowHostingResponse, HOST_FOLDER_API_CAN_WRITE_TO_FOLDER, HOST_FOLDER_API_GET_ALLOW_HOSTING, HOST_FOLDER_API_LOAD_HOST_FOLDER, HOST_FOLDER_API_SAVE_HOST_FOLDER, HOST_FOLDER_API_SET_ALLOW_HOSTING, LoadHostFolderResponse, SaveHostFolderArgs, SaveHostFolderResponse, SetAllowHostingArgs, SetAllowHostingResponse } from './lanStorage/hostFolder.d'

const log = _debug('sltt:SlttAppStorage')

type WindowInSlttApp = Window & typeof globalThis & { electron: ElectronAPI }
const windowInSlttApp = window as WindowInSlttApp

const API_BASE_LOCALHOST_URL = 'http://localhost:45177'
let proxyUrl = ''

export function setProxyUrl(url: string) {
    console.log('setProxyUrl', url)
    proxyUrl = url
}

async function postToApi<TArgs extends Record<string, any>>(endpoint: string, body: TArgs, apiBaseUrl: string = proxyUrl || API_BASE_LOCALHOST_URL) {
    const formData = new FormData()

    const bodySize = JSON.stringify(body).length
    log(`postToApi: POST ${apiBaseUrl}/${endpoint} - ${bodySize} bytes`)
    let bodyEncoded: any = JSON.stringify(body)
    let serverId = ''
    if (apiBaseUrl === proxyUrl) {
        serverId = lookupServerIdForUrl(apiBaseUrl)
        log('postToApi: serverId', serverId)
        if (!serverId) {
            throw new Error(`No serverId found for url: "${apiBaseUrl}" in "${localStorage.teamStorageProjectLastConnectionMap}" for endpoint: "${endpoint}"`)
        }
    }
    const proxyHeader = serverId ? { 'x-sltt-app-storage-server-id': serverId } : {}
    const contentTypeHeader = !('blob' in body) ? { 'Content-Type': 'application/json' } : {}
    const headers: Object | null = { headers: { ...contentTypeHeader, ...proxyHeader } }
    if ('blob' in body) {
        // Append each key-value pair from the body to the FormData object
        for (const key in body) {
            if (body.hasOwnProperty(key)) {
                formData.append(key, body[key])
            }
        }
        bodyEncoded = formData
    }

    const url = `${apiBaseUrl}/${endpoint}`
    try {
        const response = await fetch(url, {
            method: 'POST',
            ...headers,
            body: bodyEncoded
        })
        if (!response.ok) {
            // TODO: when to do handleLostConnectionError()?
            throw new Error(`Failed to post to ${endpoint}: ${response.statusText}: ${await response.text()}`)
        }
        const contentType = response.headers.get('Content-Type')
        if (contentType?.includes('application/octet-stream')) {
            return response.arrayBuffer()
        }
        return response.json()
    } catch (error) {
        log('postToApi error', error)
        // if error (e.g. 'Failed to fetch') assume the connection is lost
        handleLostConnectionError(error, endpoint, url)
    }
}

export function getClientId() {
    if (!localStorage.slttStorageClientId) {
        localStorage.slttStorageClientId = generate4DigitHex()
    }
    return localStorage.slttStorageClientId
}

type BasicConnection = {
    serverId: string
    url: string
}

export function getProjectLastConnection(project: string = getCurrentProject()): BasicConnection {
    const projectUrlMap = JSON.parse(localStorage.teamStorageProjectLastConnectionMap || '{}')
    return projectUrlMap[project]
}

function lookupServerIdForUrl(url: string) {
    const projectUrlMap = JSON.parse(localStorage.teamStorageProjectLastConnectionMap || '{}')
    for (const project in projectUrlMap) {
        if (projectUrlMap[project].url === url) {
            return projectUrlMap[project].serverId
        }
    }
    return null
}

function setProjectActiveConnection({ serverId, url, project = getCurrentProject() }: { serverId: string, url: string, project: string }) {
    const projectUrlMap = JSON.parse(localStorage.teamStorageProjectLastConnectionMap || '{}')
    projectUrlMap[project] = { serverId, url }
    localStorage.teamStorageProjectLastConnectionMap = JSON.stringify(projectUrlMap)
}

function removeProjectActiveConnectionUrl({ project = getCurrentProject() }: { project: string } = { project: getCurrentProject() }) {
    const projectUrlMap = JSON.parse(localStorage.teamStorageProjectLastConnectionMap || '{}')
    delete projectUrlMap[project]
    localStorage.teamStorageProjectLastConnectionMap = JSON.stringify(projectUrlMap)
}

let _isSlttAppStorageEnabled: boolean = false

export const getHasElectronContext = () => !!windowInSlttApp.electron
const getHasValidLANStorageConnectionUrl = (connectionUrl?: string) => (connectionUrl ? connectionUrl?.length >= 3 : false)

let _currentProject = ''
export const setCurrentProject = (project: string, logContext: string) => {
    log(`setCurrentProject (${logContext})`, project)
    _currentProject = project
}

export const getCurrentProject = () => {
    if (!_currentProject) {
        throw new Error('Current project not set')
    }
    return _currentProject
}

/**
 * NOTE: BEFORE calling this function, must call setCurrentProject() so getCurrentProject() does not throw an error
 * @returns {boolean} True if the current context is electron and the project has a valid and active LAN storage connection url
 */
export const isSlttAppStorageEnabled = () => {
    const hasElectronContext = getHasElectronContext()
    const projectActiveConnection = getProjectLastConnection(getCurrentProject())
    const hasValidLANStorageConnectionUrl = getHasValidLANStorageConnectionUrl(projectActiveConnection?.url)
    const newIsSlttAppStorageEnabled = hasElectronContext && hasValidLANStorageConnectionUrl
    if (hasElectronContext && _isSlttAppStorageEnabled !== newIsSlttAppStorageEnabled) {
        log('isSlttAppStorageEnabled', { hasElectronContext, projectActiveConnection, _isSlttAppStorageEnabled, newIsSlttAppStorageEnabled })
        _isSlttAppStorageEnabled = newIsSlttAppStorageEnabled
    }
    return _isSlttAppStorageEnabled
}

export const setAllowHosting = async ({ allowHosting }: Omit<SetAllowHostingArgs, 'clientId'>, logContext: string): Promise<SetAllowHostingResponse> => {
    if (!getHasElectronContext()) throw new Error(`setAllowHosting should be called from an electron context in ${logContext}`)
    const args: SetAllowHostingArgs = { clientId: getClientId(), allowHosting }
    const response: SetAllowHostingResponse = await postToApi(HOST_FOLDER_API_SET_ALLOW_HOSTING, args, API_BASE_LOCALHOST_URL)
    log(`${logContext} ${HOST_FOLDER_API_SET_ALLOW_HOSTING} response`, response)
    return response
}

export const getAllowHosting = async (logContext: string): Promise<GetAllowHostingResponse> => {
    if (!getHasElectronContext()) throw new Error(`getAllowHosting should be called from an electron context in ${logContext}`)
    const args: GetAllowHostingArgs = { clientId: getClientId() }
    const response: GetAllowHostingResponse = await postToApi(HOST_FOLDER_API_GET_ALLOW_HOSTING, args, API_BASE_LOCALHOST_URL)
    log(`${logContext} ${HOST_FOLDER_API_GET_ALLOW_HOSTING} response`, response)
    return response
}

export const canWriteToFolder = async ({ folderPath }: Omit<CanWriteToFolderArgs, 'clientId'>, logContext: string): Promise<CanWriteToFolderResponse> => {
    if (!getHasElectronContext()) throw new Error('canWriteToFolder should be called from an electron context')
    const args: CanWriteToFolderArgs = { folderPath }
    const response: CanWriteToFolderResponse = await postToApi(HOST_FOLDER_API_CAN_WRITE_TO_FOLDER, args, API_BASE_LOCALHOST_URL)
    log(`${logContext} ${HOST_FOLDER_API_CAN_WRITE_TO_FOLDER}`, response)
    return response
}

export const loadHostFolder = async (logContext: string): Promise<LoadHostFolderResponse> => {
    if (!getHasElectronContext()) throw new Error('loadHostFolder should be called from an electron context')
    const response: LoadHostFolderResponse = await postToApi(HOST_FOLDER_API_LOAD_HOST_FOLDER, {}, API_BASE_LOCALHOST_URL)
    log(`${logContext} ${HOST_FOLDER_API_LOAD_HOST_FOLDER}`, response)
    return response
}

export const saveHostFolder = async ({ hostFolder }: Omit<SaveHostFolderArgs, 'clientId'>, logContext: string): Promise<SaveHostFolderResponse> => {
    if (!getHasElectronContext()) throw new Error('saveHostFolder should be called from an electron context')
    const args: SaveHostFolderArgs = { clientId: getClientId(), hostFolder }
    const response: SaveHostFolderResponse = await postToApi(HOST_FOLDER_API_SAVE_HOST_FOLDER, args, API_BASE_LOCALHOST_URL)
    log(`${logContext} ${HOST_FOLDER_API_SAVE_HOST_FOLDER}`, response)
    return response
}

export const getStorageProjects = async (logContext: string): Promise<GetStorageProjectsResponse> => {
    if (!getHasElectronContext()) throw new Error('getStorageProjects should be called from an electron context')
    const args: GetStorageProjectsArgs = { clientId: getClientId() }
    const response: GetStorageProjectsResponse = await postToApi(CONNECTIONS_API_GET_STORAGE_PROJECTS, args)
    log(`${logContext} ${CONNECTIONS_API_GET_STORAGE_PROJECTS} response`, response)
    return response ?? []
}

export const addStorageProject = async ({ project, adminEmail }: Omit<AddStorageProjectArgs, 'clientId'>, logContext: string): Promise<AddStorageProjectResponse> => {
    if (!getHasElectronContext()) throw new Error('addStorageProject should be called from an electron context')
    const args: AddStorageProjectArgs = { clientId: getClientId(), project, adminEmail }
    const response: AddStorageProjectResponse = await postToApi(CONNECTIONS_API_ADD_STORAGE_PROJECT, args)
    log(`${logContext} ${CONNECTIONS_API_ADD_STORAGE_PROJECT} response`, response)
    return response ?? { ok: false }
}

export const removeStorageProject = async ({ project, adminEmail }: Omit<RemoveStorageProjectArgs, 'clientId'>, logContext: string): Promise<RemoveStorageProjectResponse> => {
    if (!getHasElectronContext()) throw new Error('removeStorageProject should be called from an electron context')
    const args: RemoveStorageProjectArgs = { clientId: getClientId(), project, adminEmail }
    const response = await postToApi(CONNECTIONS_API_REMOVE_STORAGE_PROJECT, args)
    log(`${logContext} ${CONNECTIONS_API_REMOVE_STORAGE_PROJECT} response`, response)
    return response ?? { ok: false }
}

export const getAuthorizedStorageProjects = async () => {
    const storageProjects = await getStorageProjects('getAuthorizedStorageProjects')
    const authorizedProjects = localStorage.projects ? (JSON.parse(localStorage.projects)?.projects as IProjectEntity[])?.map((p) => p.project) : []
    const authorizedProjectsSet = new Set(authorizedProjects)
    const authorizedStorageProjects = storageProjects.filter((p) => authorizedProjectsSet.has(p))
    return authorizedStorageProjects
}

export const startUdp = async (logContext: string) => {
    if (!getHasElectronContext()) throw new Error('startUdp should be called from an electron context')
    const clientId = getClientId()
    const args: StartUdpArgs = { clientId }
    const response: StartUdpResponse = await postToApi(CONNECTIONS_API_START_UDP, args, API_BASE_LOCALHOST_URL)
    log(`${logContext} ${CONNECTIONS_API_START_UDP} response`, response)
    return response
}

export const probeLANStorageConnections = async({ username }: Omit<ProbeConnectionsArgs, 'clientId'>, logContext: string): Promise<ProbeConnectionsResponse> => {
    if (!getHasElectronContext()) throw new Error(`probeLANStorageConnections should be called from an electron context in ${logContext}`)
    
    const clientId = getClientId()
    const args: ProbeConnectionsArgs = { clientId, username }
    const response: ProbeConnectionsResponse = await postToApi(CONNECTIONS_API_PROBE, args, API_BASE_LOCALHOST_URL)
    log(`probeLANStorageConnections ${CONNECTIONS_API_PROBE} response`, response)
    log(`${logContext} [${getCurrentProject()}] getProjectLastConnection()`, getProjectLastConnection())
    return response ?? []
}

export const connectProjectToLANStorage = async ({ project = getCurrentProject(), serverId = getProjectLastConnection()?.serverId }: Omit<ConnectArgs, 'clientId'> = { project: getCurrentProject(), serverId: getProjectLastConnection()?.serverId }): Promise<ConnectResponse> => {
    if (!getHasElectronContext()) throw new Error('connectToLANStorage should be called from an electron context')
    if (!project) throw new Error('project is required to connectToLANStorage')
    if (!serverId) throw new Error('serverId is required to connectToLANStorage')

    const projectActiveConnectionOrig = getProjectLastConnection(project)
    log('connectToLANStorage', { project, serverId })
    const clientId = getClientId()
    const args: ConnectArgs = { clientId, serverId, project }
    const response: ConnectResponse = await postToApi(CONNECTIONS_API_CONNECT, args, API_BASE_LOCALHOST_URL)
    log(`connectToLANStorage ${CONNECTIONS_API_CONNECT} response`, response)
    const { connectionUrl } = response
    const projectActiveConnectionUpdated = { project, serverId, url: connectionUrl }
    if (safeStableStringify(projectActiveConnectionUpdated) !== safeStableStringify(projectActiveConnectionOrig)) {
        log(`[${getCurrentProject()}] projectActiveConnectionUrlOrig (${safeStableStringify(projectActiveConnectionOrig)}) projectActiveConnectionUpdated (${safeStableStringify(projectActiveConnectionUpdated)})`, projectActiveConnectionUpdated)
    }
    setProjectActiveConnection({ serverId, project, url: connectionUrl })
    if (projectActiveConnectionUpdated.url.startsWith('http') && projectActiveConnectionUpdated.url !== proxyUrl) {
        setProxyUrl(projectActiveConnectionUpdated.url)
    }
    return response
}

export const disconnectFromLANStorage = (project: string = getCurrentProject()) => {
    if (!getHasElectronContext()) throw new Error('disconnectFromLANStorage should be called from an electron context')
    removeProjectActiveConnectionUrl({ project })
    setProxyUrl('')
    //     windowInSlttApp.electron.ipcRenderer.invoke(CONNECTIONS_API_DISCONNECT)
}

(window as any).probeLANStorageConnections = probeLANStorageConnections;
(window as any).connectToLANStorage = connectProjectToLANStorage;
(window as any).disconnectFromLANStorage = disconnectFromLANStorage;

export class SlttAppStorageDisabledError extends Error {
    constructor(apiCall: string, logContext: string) {
        super(`isSlttAppStorageEnabled should be true when invoking api '${apiCall}' from context: ${logContext}`)
        this.name = 'SlttAppStorageDisabledError';
    }
}

export const registerClientUser = async ({ username }: Omit<RegisterClientUserArgs, 'clientId'>, logContext: string): Promise<RegisterClientUserResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('registerClientUser', logContext)
    if (await getHasLostConnection('registerClientUser', logContext)) return null

    const clientId = getClientId()
    const response: RegisterClientUserResponse = await postToApi(CLIENTS_API_REGISTER_CLIENT_USER, { clientId, username })
    log(`${logContext} ${CLIENTS_API_REGISTER_CLIENT_USER} response`, response)
    return response ?? null
}

export const storeBlob = async ({ blobId, blob }: Omit<StoreBlobArgs, 'clientId'>, logContext: string): Promise<StoreBlobResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeBlob', logContext)
    if (await getHasLostConnection('storeBlob', logContext)) return null

    const clientId = getClientId()
    const storeBlobArgs: StoreBlobArgs = { clientId, blobId, blob }
    log(`${logContext} ${BLOBS_API_STORE_BLOB} to windowInSlttApp...`, clientId, blobId, blob)
    const response: StoreBlobResponse = await postToApi(BLOBS_API_STORE_BLOB, storeBlobArgs)
    log(`${logContext} ${BLOBS_API_STORE_BLOB} response`, response)
    return response ?? null
}

export const retrieveBlob = async ({ blobId }: Omit<RetrieveBlobArgs, 'clientId'>, logContext: string): Promise<Blob | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveBlob', logContext)
    if (await getHasLostConnection('retrieveBlob', logContext)) return null

    const clientId = getClientId()
    const retrieveBlobArgs: RetrieveBlobArgs = { clientId, blobId }
    const arrayBuffer: RetrieveBlobResponse = await postToApi(BLOBS_API_RETRIEVE_BLOB, retrieveBlobArgs)
    log(`${logContext} ${BLOBS_API_RETRIEVE_BLOB} from windowInSlttApp`, blobId, arrayBuffer)
    return arrayBuffer ? new Blob([arrayBuffer]) : null
}

export const retrieveAllBlobIds = async (logContext: string): Promise<RetrieveAllBlobIdsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveAllBlobIds', logContext)
    if (await getHasLostConnection('retrieveAllBlobIds', logContext)) return []
    const retrieveAllBlobIdsArgs: RetrieveAllBlobIdsArgs = { clientId: getClientId() }
    const response: RetrieveAllBlobIdsResponse = await postToApi(BLOBS_API_RETRIEVE_ALL_BLOB_IDS, retrieveAllBlobIdsArgs)
    log(`${logContext} ${BLOBS_API_RETRIEVE_ALL_BLOB_IDS} response`, response)
    return response ?? []
}

export const storeVideoCacheRecord = async ({ videoCacheRecord: vcr, batchMaxSize, batchMaxTime }: Omit<StoreVcrArgs, 'clientId'>, logContext: string): Promise<StoreVcrResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeVideoCacheRecord', logContext)
    if (await getHasLostConnection('storeVideoCacheRecord', logContext)) return null
    const clientId = getClientId()
    const serializableVcr = makeObjSerializable(vcr)
    const storeVcrArgs: StoreVcrArgs = { clientId, videoCacheRecord: serializableVcr, batchMaxTime, batchMaxSize }
    const response: StoreVcrResponse = await postToApi(VIDEO_CACHE_RECORDS_API_STORE_VCR, storeVcrArgs)
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_STORE_VCR} response`, response)
    return response ?? null
}

/**
 * Lists all video cache records in the storage.
 * 
 * This function queries the local storage to retrieve a filename list of all cached video records.
 * 
 * @project The project name to list video cache records for. Pass empty string for all projects
 * 
 * @returns {Promise<string[]>} A promise that resolves with an array of video record filenames like (["TESTnm__210629_180535.sltt-vcrs"])
 */
export const listVideoCacheRecordFiles = async ({ project }: Omit<ListVcrFilesArgs, 'clientId'>, logContext: string): Promise<ListVcrFilesResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('listVideoCacheRecordFiles', logContext)
    if (await getHasLostConnection('listVideoCacheRecordFiles', logContext)) return []

    const clientId = getClientId()
    const listVcrArgs: ListVcrFilesArgs = { clientId, project }
    const response: ListVcrFilesResponse = await postToApi(VIDEO_CACHE_RECORDS_API_LIST_VCR_FILES, listVcrArgs)
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_LIST_VCR_FILES} response`, response)
    return response ?? []
}

export const retrieveVideoCacheRecords = async ({ filename }: Omit<RetrieveVcrsArgs, 'clientId'>, logContext: string): Promise<RetrieveVcrsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveVideoCacheRecords', logContext)
    if (await getHasLostConnection('retrieveVideoCacheRecords', logContext)) return {}

    const clientId = getClientId()
    const retrieveVcrArgs: RetrieveVcrsArgs = { clientId, filename }
    const response: RetrieveVcrsResponse = await postToApi(VIDEO_CACHE_RECORDS_API_RETRIEVE_VCRS, retrieveVcrArgs)
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_RETRIEVE_VCRS} response`, response)
    return response ?? {}
}

export const storeRemoteDocs = async (
    { project, seqDocs }: Omit<StoreRemoteDocsArgs<IDBModDoc>, 'clientId'>, logContext: string
): Promise<StoreRemoteDocsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeRemoteDocs', logContext)
    if (await getHasLostConnection('storeRemoteDocs', logContext)) return null

    const clientId = getClientId()
    const storeRemoteDocsArgs: StoreRemoteDocsArgs<IDBModDoc> = { clientId, project, seqDocs }
    const response: StoreRemoteDocsResponse = await postToApi(DOCS_API_STORE_REMOTE_DOCS, storeRemoteDocsArgs)
    log(`${logContext} ${DOCS_API_STORE_REMOTE_DOCS} response`, response)
    return response ?? null
}

export const retrieveRemoteDocs = async (
    { project, spot }: Omit<RetrieveRemoteDocsArgs, 'clientId'>, logContext: string
): Promise<RetrieveRemoteDocsResponse<IDBModDoc> | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveRemoteDocs', logContext)
    if (await getHasLostConnection('retrieveRemoteDocs', logContext)) return null

    const clientId = getClientId()
    const retrieveRemoteDocsArgs: RetrieveRemoteDocsArgs = { clientId, project, spot }
    const response: RetrieveRemoteDocsResponse<IDBModDoc> = await postToApi(DOCS_API_RETRIEVE_REMOTE_DOCS, retrieveRemoteDocsArgs)
    log(`${logContext} ${DOCS_API_RETRIEVE_REMOTE_DOCS} response`, response)
    return response ?? null
}

export const saveRemoteDocsSpots = async (
    { project, spots }: Omit<SaveRemoteSpotsArgs, 'clientId'>, logContext: string
): Promise<SaveRemoteSpotsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('saveRemoteDocsSpots', logContext)
    if (await getHasLostConnection('saveRemoteDocsSpots', logContext)) return null

    const clientId = getClientId()
    const saveRemoteSpotsArgs: SaveRemoteSpotsArgs = { clientId, project, spots }
    const response: SaveRemoteSpotsResponse = await postToApi(DOCS_API_SAVE_REMOTE_SPOTS, saveRemoteSpotsArgs)
    log(`${logContext} ${DOCS_API_SAVE_REMOTE_SPOTS} response`, response)
    return response ?? { ok: false }
}

export const getRemoteSpots = async (
    { project }: Omit<GetRemoteSpotsArgs, 'clientId'>, logContext: string
): Promise<GetRemoteSpotsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('getRemoteSpots', logContext)
    if (await getHasLostConnection('getRemoteSpots', logContext)) return {}

    const clientId = getClientId()
    const getRemoteSpotsArgs: GetRemoteSpotsArgs = { clientId, project }
    const response: GetRemoteSpotsResponse = await postToApi(DOCS_API_GET_REMOTE_SPOTS, getRemoteSpotsArgs)
    log(`${logContext} ${DOCS_API_GET_REMOTE_SPOTS} response`, response)
    return response ?? {}
}

export const storeLocalDocs = async (
    { project, docs }: Omit<StoreLocalDocsArgs<IDBModDoc>, 'clientId'>, logContext: string
): Promise<StoreLocalDocsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeLocalDocs', logContext)
    if (await getHasLostConnection('storeLocalDocs', logContext)) return null

    const clientId = getClientId()
    const storeLocalDocsArgs: StoreLocalDocsArgs<IDBModDoc> = { clientId, project, docs }
    const response: StoreLocalDocsResponse = await postToApi(DOCS_API_STORE_LOCAL_DOCS, storeLocalDocsArgs)
    log(`${logContext} ${DOCS_API_STORE_LOCAL_DOCS} response`, response)
    return response ?? null
}

export const getStoredLocalClientIds = async (
    { project }: Omit<GetStoredLocalClientIdsArgs, 'clientId'>, logContext: string
): Promise<GetStoredLocalClientIdsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('getStoredLocalClientIds', logContext)
    if (await getHasLostConnection('getStoredLocalClientIds', logContext)) return []

    const getStoredLocalClientIdsArgs: GetStoredLocalClientIdsArgs = { project }
    const response: GetStoredLocalClientIdsResponse = await postToApi(DOCS_API_GET_STORED_LOCAL_CLIENT_IDS, getStoredLocalClientIdsArgs)
    log(`${logContext} ${DOCS_API_GET_STORED_LOCAL_CLIENT_IDS} response`, response)
    return response ?? []
}

export const retrieveLocalClientDocs = async (
    { localClientId, project, spot }: Omit<RetrieveLocalClientDocsArgs, 'clientId'>, logContext: string
): Promise<RetrieveLocalClientDocsResponse<IDBModDoc> | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveLocalClientDocs', logContext)
    if (await getHasLostConnection('retrieveLocalClientDocs', logContext)) return null

    const clientId = getClientId()
    const retrieveLocalClientDocsArgs: RetrieveLocalClientDocsArgs = { clientId, localClientId, project, spot }
    const response: RetrieveLocalClientDocsResponse<IDBModDoc> = await postToApi(DOCS_API_RETRIEVE_LOCAL_CLIENT_DOCS, retrieveLocalClientDocsArgs)
    log(`${logContext} ${DOCS_API_RETRIEVE_LOCAL_CLIENT_DOCS} response`, response)
    return response ?? null
}

export const saveLocalSpots = async (
    { project, spots }: Omit<SaveLocalSpotsArgs, 'clientId'>, logContext: string
): Promise<SaveRemoteSpotsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('saveLocalSpots', logContext)
    if (await getHasLostConnection('saveLocalSpots', logContext)) return null

    const clientId = getClientId()
    const saveLocalSpotsArgs: SaveLocalSpotsArgs = { clientId, project, spots }
    const response: SaveLocalSpotsResponse = await postToApi(DOCS_API_SAVE_LOCAL_SPOTS, saveLocalSpotsArgs)
    log(`${logContext} ${DOCS_API_SAVE_LOCAL_SPOTS} response`, response)
    return response ?? { ok: false }
}

export const getLocalSpots = async (
    { project }: Omit<GetLocalSpotsArgs, 'clientId'>, logContext: string
): Promise<GetLocalSpotsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('getLocalSpots', logContext)
    if (await getHasLostConnection('getLocalSpots', logContext)) return {}

    const clientId = getClientId()
    const getLocalSpotsArgs: GetLocalSpotsArgs = { clientId, project }
    const response: GetLocalSpotsResponse = await postToApi(DOCS_API_GET_LOCAL_SPOTS, getLocalSpotsArgs)
    log(`${logContext} ${DOCS_API_GET_LOCAL_SPOTS} response`, response)
    return response ?? {}
}

const makeObjSerializable = <T>(obj: T): T => {
    const stringified = safeStableStringify(obj)
    return stringified && JSON.parse(stringified)
}


const handleLostConnectionError = (error: any, apiContext: string, logContext: string) => {
    const errorMessage = `[${getCurrentProject()}] Failed to connect to LAN storage (${safeStableStringify(getProjectLastConnection())}) when invoking api '${apiContext}' from context: ${logContext}`
    console.error(`${errorMessage}: ${error?.message || 'unknown'}`)
    removeProjectActiveConnectionUrl()
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
        userError(t`Failed to connect to local team storage. Please check your network connection and try again.`)
    } else {
        logError(error, errorMessage)
    }
}


async function getHasLostConnection(apiContext: string, logContext: string) {
    try {
        await connectProjectToLANStorage()
    } catch (e) {
        handleLostConnectionError(e, apiContext, logContext)
        return true
    }
    return !isSlttAppStorageEnabled()
}
