import React, { FC, Component, useRef, useState, useContext } from 'react'
import { t } from 'ttag'
import { OkEditSegmentButton, CancelEditSegmentButton, PlayButton } from '../utils/Buttons'
import { PassageVideo, PassageSegment, Passage, MIN_SEGMENT_LENGTH } from '../../models3/ProjectModels'
// import { observer } from 'mobx-react'
// import { observable } from 'mobx'
import Modal from 'react-bootstrap/lib/Modal'

import { Slider, SliderItem, GetHandleProps, Handles, GetTrackProps, Tracks } from 'react-compound-slider'
import { SegmentPositionAdjustTimeButtons } from '../utils/Buttons'
import VideoPositionViewer from './VideoPositionViewer'
import { displayError } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { VisiblePassageVideo } from '../../models3/VisiblePassageVideo'
import RangeVideoPlayer from '../video/RangeVideoPlayer'
import { RootContext, RootConsumer } from '../app/RootContext'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import { fmt } from '../utils/Fmt'

import _debug from "debug"; let log = _debug('sltt:SegmentPositionDialog') 

function limit(value: number, low: number, high: number) {
    value = Math.max(value, low)
    value = Math.min(value, high)
    return value
}

function fx(position: number) { return position.toFixed(2)}


/**
 * Track of position and endPosition of segment.
 * Enforce 
 *    minPosition <= position <= endPosition-MIN_SEGMENT_LENGTH
 *    endPosition <= maxPosition
 */
class SegmentPositions {
    // min/max positions allowed for this segment
    minPosition = 0
    maxPosition: number

    // current start and endpositions of segment
    position: number
    endPosition: number


    constructor (public passage: Passage, public video: PassageVideo, public segmentIndex: number) {
        let segment = video.segments[segmentIndex]

        if (segment.isPatched) {
            let actualSegment = segment.actualSegment(passage)
            this.maxPosition = this.actualVideo.duration
            this.position = actualSegment.position
            this.endPosition = actualSegment.endPosition
        } else {
            if (segmentIndex > 0) {
                this.minPosition = video.segments[segmentIndex - 1].endPosition
            }

            if (segmentIndex < video.segments.length - 1) {
                let nextSegment = video.segments[segmentIndex + 1]
                this.maxPosition = nextSegment.position
            } else {
                this.maxPosition = video.duration
            }

            this.position = segment.position
            this.endPosition = segment.endPosition
        }
        
        log(`constructor ${fx(this.minPosition)} <= ${fx(this.position)}-${fx(this.endPosition)} <= ${fx(this.maxPosition)}`)
        this.checkValidity()
    }

    checkValidity(throwOnError?: boolean) {
        let { minPosition, position, endPosition, maxPosition } = this

        let _valid = (
            (minPosition >= 0) &&
            (position >= minPosition) &&
            (endPosition >= position + MIN_SEGMENT_LENGTH) &&
            (maxPosition >= endPosition) )

        if (!_valid) {
            log('### invalid positions', fmt({ minPosition, position, endPosition, maxPosition }))
            if (throwOnError) {
                throw new Error('Invalid segment positions')
            }
        }
    }

    get actualVideo() {
        let segment = this.video.segments[this.segmentIndex]
        return segment!.actualVideo(this.passage)!
    }

    /**
     * 
     * @param value: Desired value, will be forced to lie in minPosition..maxPosition
     * @param setStartingPosition: True = set position, false = set endPosition.
     */
    setPosition(value: number, setStartingPosition: boolean) {
        let { minPosition, maxPosition, position, endPosition } = this

        if (setStartingPosition) {
            this.position = Math.min(value, endPosition - MIN_SEGMENT_LENGTH)
        } else {
            // Setting ending position (but not too close to minPosition)
            this.endPosition = Math.max(value, position + MIN_SEGMENT_LENGTH)
        }
        
        this.position = Math.max(this.position, minPosition)
        this.endPosition = Math.min(this.endPosition, maxPosition)

        let newValue = setStartingPosition ? this.position : this.endPosition
        log('setPosition', fmt({ value, newValue, setStartingPosition, minPosition, position, endPosition, maxPosition }))

        return newValue
    }

    async save() {
        try {
            log('save')

            this.checkValidity(true)

            let { video, segmentIndex, passage, actualVideo, position, endPosition } = this

            let segment = video.segments[segmentIndex]
            let actualSegment = segment.actualSegment(passage)

            log(`save [si=${segmentIndex} ${fx(actualSegment.position)}..${fx(actualSegment.endPosition)}] ${fx(position)}..${fx(endPosition)}`)

            await actualSegment.setPositions(position, endPosition, actualVideo)
        } catch (error) {
            displayError(error)
        }
    }
}

function logSaveSegment(label: string, passage: Passage, video: PassageVideo, segmentIndex: number) {
    if (segmentIndex < 0 || segmentIndex >= video.segments.length) return
    let segment = video.segments[segmentIndex]
    log(`!!!save ${label} segment positions`, JSON.stringify(segment.dbg(passage), null, 4))
}

interface ISegmentPositionDialog {
    passage: Passage,
    video: PassageVideo,
    segmentIndex: number,
    editingSegmentPosition: EditingSegmentPosition,
    close: (canceled: boolean, time: number, duration: number) => void,
}

export const SegmentPositionDialog: FC<ISegmentPositionDialog> = 
    ({ passage, video, segmentIndex, editingSegmentPosition: esp, close }) => {

    // setEditingSegmentPosition: (value: number) => void,
    let adjustingBoth = (esp === EditingSegmentPosition.Both)

    let [positions] = useState<SegmentPositions>(new SegmentPositions(passage, video, segmentIndex))

    let [prePositions] = useState<SegmentPositions | null>(
        adjustingBoth && (segmentIndex != 0) ? new SegmentPositions(passage, video, segmentIndex-1) : null)
    
    let [postPositions] = useState<SegmentPositions | null>(
        adjustingBoth && (segmentIndex < video.segments.length-1) ? new SegmentPositions(passage, video, segmentIndex+1) : null)

    function save() {
        async function _save() {
            await positions.save()
            if (prePositions) { await prePositions.save() }
            if (postPositions) { await postPositions.save() }

            // recompute times based on new positions
            video.setSegmentTimes(passage, true)
            let segment = video.segments[segmentIndex].actualSegment(passage)

            logSaveSegment('previous', passage, video, segmentIndex-1)
            logSaveSegment('current', passage, video, segmentIndex)
            logSaveSegment('next', passage, video, segmentIndex+1)

            close(false, segment.time, video.computedDuration)
        }

        _save()
            .catch(displayError)
    }

    let heading = ''

    switch (esp) {
        case EditingSegmentPosition.Starting:
            heading = t`Segment Start`
            break
        case EditingSegmentPosition.Ending:
            heading = t`Segment End`
            break
        case EditingSegmentPosition.Both:
            heading = t`Adjust Segment Start and End` 
            break
    }

    let dialogClassName = adjustingBoth ? "modal-90w" : "modal-20w"

    return (
        <Modal style={{ top: '1%' }} dialogClassName={dialogClassName} show={true} onHide={() => close(true, 0, 0)} >
            <Modal.Header closeButton> {heading} </Modal.Header>
            <Modal.Body>
                <div className="patch-dialog-modal-body">
                    { adjustingBoth && <AdjustingBothDialogBody {...{ positions, prePositions, postPositions }} />}
                    { (esp === 1) && <PositionDialogBody 
                            {...{ positions, editStartingPosition: true }} />}
                    { (esp === 2) && <PositionDialogBody 
                            {...{ positions, editStartingPosition: false}} /> }
                    <div>
                        <OkEditSegmentButton enabled={true} onClick={save} />
                        <CancelEditSegmentButton enabled={true} onClick={() => close(true, 0, 0)} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}


interface IAdjustingBothDialogBody {
    positions: SegmentPositions,
    prePositions: SegmentPositions | null,
    postPositions: SegmentPositions | null,
}

export const AdjustingBothDialogBody: FC<IAdjustingBothDialogBody> = ({  positions, prePositions, postPositions }) => {
    let [playing, setPlaying] = useState(false)

    return (
        <div>
            <div>
                <PlayButton 
                    className="video-play-patch-preview" 
                    enabled={!playing} 
                    selectionPresent={false}
                    onClick={() => { setPlaying(true) }} 
                    tooltip={t`Preview adjustments.`} />
                {playing && <PlayPreview {...{positions, prePositions, postPositions, setPlaying}} />}
            </div>
            <div className="patch-dialog-body">
                {prePositions &&
                    <VideoSegmentPositionEditor 
                        className="video-segment-editor1"
                        heading={t`Segment Before`}
                        positions={prePositions} 
                        editStartingPosition={false} />}
                <VideoSegmentPositionEditor
                    className="video-segment-editor2"
                    heading={t`Current Segment Start`}
                    {...{positions}}
                    editStartingPosition={true} />
                <VideoSegmentPositionEditor
                    className="video-segment-editor3"
                    heading={t`Current Segment End`}
                    {...{ positions }}
                    editStartingPosition={false} />
                {postPositions &&
                    <VideoSegmentPositionEditor
                        className="video-segment-editor1"
                        heading={t`Segment After`}
                        positions={postPositions!}
                        editStartingPosition={true} />}
            </div>
        </div>
    )
}


interface IPlayPreview {
    positions: SegmentPositions,
    prePositions: SegmentPositions | null,
    postPositions: SegmentPositions | null,
    setPlaying: (playing: boolean) => void
}

export const PlayPreview: FC<IPlayPreview> = ({ positions, prePositions, postPositions, setPlaying }) => {
    // Make a copy of the video object using latest patches
    console.clear()
    
    let { passage } = positions
    let video = new VisiblePassageVideo(passage, positions.video, '9999/12/31')
    let rt = useContext(RootContext)

    function setSegmentPositions(_positions: SegmentPositions | null) {
        if (_positions === null) return
        log('setSegmentPositions', fx(_positions!.position), fx(_positions!.endPosition))
        let _segment = video.segments[_positions.segmentIndex].actualSegment(passage)
        _segment.position = _positions!.position
        _segment.endPosition = _positions!.endPosition
    }

    // Use adjusted positions
    setSegmentPositions(positions)
    setSegmentPositions(prePositions)
    setSegmentPositions(postPositions)
    video.setSegmentTimes(passage, true)

    // Patched segment
    let segment = video.segments[positions.segmentIndex].actualSegment(passage)
    let segmentDuration = segment.endPosition - segment.position

    let startTime = Math.max(0, segment.time - 2)
    let endTime = Math.min(segment.time + segmentDuration + 2, video.computedDuration)
    let currentVideos = rt!.currentVideos
    let className = 'video-patch-preview'
    let onEnded = () => { 
        log('onEnded')
        setTimeout(() => setPlaying(false), 300)
    }

    let playbackRate = rt?.playbackRate || 1
    let setPlaybackRate = rt?.setPlaybackRate || ((rate: number) => {})

    return (
        <RangeVideoPlayer {...{ className, passage, video, playbackRate, setPlaybackRate, currentVideos, onEnded, startTime, endTime }} />
    )
}


interface IPositionDialogBody {
    positions: SegmentPositions,
    editStartingPosition: boolean,
}

const PositionDialogBody: FC<IPositionDialogBody> = ({ positions, editStartingPosition }) => {
    return (
        <div className="patch-dialog-body">
            <VideoSegmentPositionEditor
                className="video-segment-editor2"
                heading=""
                {... {positions}}
                editStartingPosition={editStartingPosition} />
        </div>
    )
}


interface IVideoSegmentPositionEditor {
    className: string,
    heading: string,
    positions: SegmentPositions,
    editStartingPosition: boolean,
}

const VideoSegmentPositionEditor: FC<IVideoSegmentPositionEditor> = ({ className, heading, positions, editStartingPosition }) => {
    let viewerRef = useRef<any>()
    let [position, setPosition] = useState(editStartingPosition ? positions.position : positions.endPosition)

    let { minPosition, maxPosition } = positions

    let _setPosition = (_position: number) => {
        let value = positions.setPosition(_position, editStartingPosition)
        setPosition(value)
        viewerRef.current?.setPosition(value)
        return value
    }

    return (
        <div className={className}>
            <div className='video-segment-editor-heading'>{heading}</div>
            <div className=''>
                <VideoPositionViewer
                    ref={viewerRef}
                    position={position}
                    video={positions.actualVideo!}
                />
            </div>
            <div className='segment-dialog-adjustment-buttons'>
                <SegmentPositionAdjustTimeButtons
                    previousSecondEnabled={(position - 1.0) >= minPosition}
                    previousFrameEnabled={(position - (2.0 / 30.0)) >= minPosition}
                    nextFrameEnabled={(position + (2.0 / 30.0)) <= maxPosition}
                    nextSecondEnabled={(position + 1.0) <= maxPosition}
                    adjustCurrentTime={(delta: number) => { 
                        log('!!!', fx(position), fx(delta))
                        _setPosition(position + delta) 
                    }} />
            </div>
            <div className='segment-position-timeline-area'>
                <SegmentPositionTimeline
                    domainStart={minPosition}
                    domainEnd={maxPosition}
                    position={position}
                    setPosition={_setPosition} />
            </div>
        </div>
    )
}


interface ISegmentPositionTimeline {
    domainStart: number,
    domainEnd: number,
    position: number,
    setPosition: (value: number) => number,
}

const SegmentPositionTimeline: FC<ISegmentPositionTimeline> = ({ domainStart, domainEnd, position, setPosition }) => {
    function mode(curr: any[], next: any[]) {
        let value = next[0].val
        next[0].val = setPosition(value)
        return next
    }

    let domain = [domainStart, domainEnd]

    return (
        <Slider
            className='segment-position-timeline'
            domain={domain}
            step={0.1}
            mode={mode}
            values={[position]}
        >
            <div className='segment-position-timeline-rail' />
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div>
                        {handles.map((handle: any) => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks>
                {({ tracks, getTrackProps }) => (
                    <div>
                        {tracks.map(({ id, source, target }) => (
                            <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                            />
                        ))}
                    </div>
                )}
            </Tracks>
        </Slider>
    )
}

interface IHandle {
    handle: SliderItem,
    getHandleProps: GetHandleProps,
}

const Handle: FC<IHandle> = ({ handle, getHandleProps }) => {
    return (
        <div
            className='slider-handle clickable'
            style={{
                left: `${handle.percent}%`,
            }}
            {...getHandleProps(handle.id)}
        />
    )
}

interface ITrack {
    source: SliderItem,
    target: SliderItem,
    getTrackProps: GetTrackProps,
}

const Track: FC<ITrack> = ({ source, target, getTrackProps }) => {
    return (
        <div
            className='slider-track clickable'
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    )
}

