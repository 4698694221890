/**
 * This component can set/play/stop a single video in a ViewableVideoCollection.
 * 
 * The routines setVideo, play, amd stop are directly invoked by
 * the parent in order to initiate those operations.
 * 
 * This component is responsible for setting up <video> element for the main
 * video and all its patches. It ensures the correct video is visible.
 * 
 * This component also polls the currently playing video element in order to inform
 * it's parents about the current position in the video.
 */

import React, { Component, FC, useEffect, useRef, useState } from 'react'
import { observable, makeObservable } from 'mobx';
import {observer} from 'mobx-react'

import { PassageVideo } from '../../models3/ProjectModels'
import { ViewableVideoCollection, ViewableVideo } from './ViewableVideoCollection'
import { VideoPlayerPoller } from './VideoPlayerPoller'
import './Video.css'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { VideoCache } from '../../models3/VideoCache'
import { VideoDownloadingMessage } from './VideoMessage'
import { FullScreenButton } from '../utils/Buttons'

import _debug from "debug"; let log = _debug('sltt:VideoPlayerCore') 

interface IVideoPlayerCore {
    vvc: ViewableVideoCollection,
        // It is the responsibility of the parent component to setup() this
        // and initiate a download() this

    playbackRate: number,
    disablePlay?: boolean,
    className?: string,
    
    onTick: (currentPosition: number) => void,
    onPlayingStatus: (playing: boolean) => void,
    onCanPlayThrough: (video: PassageVideo) => void,
    onStop: () => void,
    onClick: () => void,

    setVideoWidth?: (width: number) => void,
        // Call this when the width of the video changes.
    muted?: boolean,
}

@observer
export default class VideoPlayerCore extends Component<IVideoPlayerCore> {
    @observable visibleVideo?: PassageVideo
    timeout = 0
    poller?: VideoPlayerPoller
    vc?: HTMLVideoElement
    videoWidthObserver?: ResizeObserver

    @observable playing = false
    
    // Mapping from VideoPassage._id to the <video> element that plays it
    videoControls: { [_id: string]: { vc: HTMLVideoElement /*, alreadySet: boolean*/ } } = {}
    
    video: PassageVideo | null = null
        // video being played. Must be in vvc.

    constructor(props: IVideoPlayerCore) {
        super(props)
        makeObservable(this);
        log('constructor')
        this.setVideo = this.setVideo.bind(this)
        this.selectVideo = this.selectVideo.bind(this)
        this.setPlaybackRate = this.setPlaybackRate.bind(this)
    }

    componentWillUnmount() {
        this.poller?.stopUpdater('componentWillUnmount')
        this.videoWidthObserver?.disconnect()
    }

    componentDidMount() {
        this.setupVideoWidthObserver()
    }

    componentDidUpdate() {
        let { playbackRate } = this.props
        this.setPlaybackRate(playbackRate)
        this.setupVideoWidthObserver()
    }

    setupVideoWidthObserver = () => {
        this.videoWidthObserver?.disconnect()
        
        this.videoWidthObserver = new ResizeObserver(entries => {
            // Normally, we would not need to debounce the callback to a ResizeObserver.
            // For some reason, Cypress tests fail if we don't.
            let { timeout } = this
            if (timeout) {
                window.cancelAnimationFrame(timeout)
            }
            
            this.timeout = window.requestAnimationFrame(() => {
                if (entries.length) {
                    let { width } = entries[0].contentRect
                    this.props.setVideoWidth?.(width)
                }
            })
        })
        
        let { visibleVideo, videoControls } = this
        if (visibleVideo) {
            let element = videoControls[visibleVideo._id]
            if (element) {
                let { vc } = element
                this.videoWidthObserver?.observe(vc)
            }
        }
    }

    render() {
        let { vvc, className, children, onClick, muted } = this.props
        let { visibleVideo } = this
        //log('render', fmt({visibleVideo}))

        let { downloaded } = vvc

        if (!downloaded) {
            let videos = vvc.viewableVideos.map(vv => vv.video)
            let urls = videos.map(v => v.url)
            let { creator } = vvc
            return <VideoDownloadingMessage urls={urls} creator={creator} fontSizePt={12} />
        }

        if (!vvc.allSrcsPresent) {
            return (<div className='video-message'>.....</div>)
        }

        let _className = `video-player${className ? ' ' + className : ''}`

        let isHidden = (vv: ViewableVideo) => {
            // log(`isHidden ${vv.video._id}/${visibleVideo?._id} = ${vv.video._id !== visibleVideo?._id}`)
            return vv.video._id !== visibleVideo?._id
        }

        // We have a collection of videos with their sources already loaded.
        // We only want to allow the user to see one of these at a time.

        return (
            <div className={_className}>
                {vvc.viewableVideos.map((v, i) => (
                    <div className='video-player-video' key={v.video._id} hidden={isHidden(v)}>
                        <video
                            className={`video-player-video-video`}
                            ref={vc => this.setVideoControl(v.video._id, vc)}
                            src={v.src}
                            onError={this.onError}
                            onEnded={this.onEnded}
                            onPause={this.onPause}
                            onClick={onClick}
                            onCanPlayThrough={() => this.onCanPlayThroughHandler(v)}
                            hidden={isHidden(v)}
                            muted={muted}
                        />
                        {!isHidden(v) && children}
                    </div>
                ))}
            </div>
        )
    }

    // ----------------------------
    // ---- Events             ----
    // ----------------------------

    fullScreen = () => {
        let { vc } = this
        if (!vc) return

        if (vc.requestFullscreen) {
            vc.requestFullscreen().catch(displayError)
        }
    }

    /* When a video is playing this called on every tick of the clock.
     */
    onTick = (position: number /* position in this video in seconds */) => {
        let { onTick } = this.props
        onTick && onTick(position)
    }

    onPlayingStatus = (playing: boolean) => {
        let { onPlayingStatus } = this.props
        this.playing = playing
        onPlayingStatus(playing)
    }

    onError = (e: any) => {
        // Values for 'error' attribute of element and event
        // 1=MEDIA_ERR_ABORTED, 2=MEDIA_ERR_NETWORK, 3=MEDIA_ERR_DECODE, 4=MEDIA_ERR_SRC_NOT_SUPPORTED
        let { onPlayingStatus } = this.props

        log('###VideoPlayerCore onError', fmt({
                code: e.target?.error?.code, 
                message: e.target?.error?.message }))

        onPlayingStatus(false)
    }

    onEnded = () => {
        log('onEnded')
        let { onPlayingStatus, onStop } = this.props

        //if (fullScreen) this.cancelFullScreen()

        onPlayingStatus(false)
        onStop()
    }

    onPause = () => {
        log('onPause')
        let { onPlayingStatus } = this.props
        onPlayingStatus(false)
    }

    onCanPlayThroughHandler = (vv: ViewableVideo) => {
        let { onCanPlayThrough } = this.props

        vv.canPlayThrough = true

        let _video = vv.video

        log('onCanPlayThrough', fmt({_video}))
        onCanPlayThrough(_video)
    }

    // -------------------------------------
    // ---- EXTERNALLY CALLED FUNCTIONS ----
    // -------------------------------------

    // May be invoked internally or externally.
    // Stops video if current playing.
    // Sets the visible video and i'ts current position
    public setVideo = (video: PassageVideo, position: number) => {
        let { visibleVideo } = this
        log('setVideo (and stop)', fmt({ video, visibleVideo, position }))

        this.stop()

        this.selectVideo(video)

        if (this.vcNotSet('setVideo')) return

        this.vc!.currentTime = position
    }

    public play = async () => {
        let { disablePlay, playbackRate } = this.props
        let { playing, vc, visibleVideo } = this

        if (playing) return

        if (disablePlay) {
            log('play ignored (disablePlay === true)')
            return
        }

        if (this.vcNotSet('play')) return

        log('play', fmt({ visibleVideo }))

        this.setPlaybackRate(playbackRate)
        
        try {
            await vc!.play()
            log('play started')
        } catch(err: any) {
            if (err.name === 'AbortError' && err.message.startsWith('The play() request was interrupted because video-only background media was paused to save power.')) {
                // catch "AbortError: The play() request was interrupted because video-only background media was paused to save power."
                // Probably caused when video is behind another window or minimized
                console.error(err.message)
            } else {
                throw err
            }
        }
        
        this.poller?.startUpdater()
    }

    // Pause video if it is playing.
    // Stop time updater if it is running.
    // Notify onPlayingStatus(false) and onStop()
    public stop = () => {
        let { playing, vc } = this
        let { onPlayingStatus, onStop } = this.props
        log(`stop`, fmt({ playing }))

        playing && vc?.pause()
        this.playing = false

        this.poller?.stopUpdater('VideoPlayerCore stop')

        onPlayingStatus(false)
        onStop && onStop()
    }

    // ----------------------------
    // ---- internal functions ----
    // ----------------------------

    vcNotSet(message?: string) {
        if (this.vc) return false

        log('###checkVc failed', message)
        return true
    }

    setVideoControl(id: string, newVc: HTMLVideoElement | null) {
        //log('setVideoControl', id, newVc)
        if (!newVc) return

        this.videoControls[id] = { vc: newVc }
    }

    setPlaybackRate(rate: number) {
        if (this.vcNotSet('setPlaybackRate')) return

        this.vc!.playbackRate = rate
    }

    selectVideo(video: PassageVideo) {
        let { vc, playing } = this

        this.poller?.stopUpdater('setVisibleVideo')

        playing && vc?.pause()
        this.playing = false

        this.visibleVideo = video
        
        let videoInfo = this.videoControls[video._id]
        this.vc = (videoInfo && videoInfo.vc) || undefined
        if (!this.vc) {
            log('###setupVideo no vc', fmt({video, keys: Object.keys(this.videoControls)}))
            return
        }

        this.poller = new VideoPlayerPoller(
            this.vc,
            this.onTick,
            this.onPlayingStatus
        )
    }

    // public requestFullScreen() {
    //     let { vc } = this
    //     vc && vc.requestFullscreen().then().catch()
    // }

    // public cancelFullScreen() {
    //     document.exitFullscreen().then().catch()
    // }
}