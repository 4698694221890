import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { throttle } from 'underscore'
import { t } from 'ttag'
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import { stringify as safeStableStringify } from 'safe-stable-stringify'

// import { rollbar } from '../../index' //!!! causes entire app to be imported into tests!!!
import { AppRoot } from '../../models3/AppRoot'
import { RollbarSingleton } from './RollbarSingleton'
import { getCurrentAppClient } from './Auth0Utils'
import './Errors.css'

import _debug from "debug"; let log = _debug('sltt:Errors') 

Error.stackTraceLimit = 40

const throttledToastError = throttle(toast.error, RollbarSingleton.throttleWait, { trailing: false })
const throttledToastSuccess = throttle(toast.success, RollbarSingleton.throttleWait, { trailing: false })
const throttledToastWarning = throttle(toast.warn, RollbarSingleton.throttleWait, { trailing: false })
const throttledToastInfo = throttle(toast.info, RollbarSingleton.throttleWait, { trailing: false })

// rollbar.critical("Crash while processing payment")
// rollbar.log("error", "Crash while checking order status")
// rollbar.warning("Facebook API unavailable")
// rollbar.info("User logged in")
// rollbar.debug("Cron job starting")

interface ErrorBoundaryProps {
  fallbackUI?: JSX.Element,
  fallbackAction?: () => void,
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  return (
    <RollbarErrorBoundary 
          fallbackUI={() => props.fallbackUI || (<div></div>)}
          callback={props.fallbackAction}
          extra={(error, info) => getDebugInfo()}
          >
      {props.children}
    </RollbarErrorBoundary>
  )
}

function stringify(err: any) {
  if (typeof err === 'string') return err
  if (err instanceof Error) return err.toString()
  const stringified = safeStableStringify(err)
  if (stringified === '{}') return err.toString()
  return stringified
}

function stringifyStack(err: any) {
  if (typeof err === 'string') return err
  if (err instanceof Error) return (err.stack ?? err.toString())
  return safeStableStringify(err)
}

function getAppRootAndProjectRoot(projectName?: string) {
  const appRoot = (window as any).appRoot as AppRoot
  if (!appRoot) return { appRoot: null, rt: null }
  const rt = projectName && projectName !== appRoot.currentProjectName ?
    (appRoot.rts.find(rt => rt.name === projectName) || null) : (appRoot.rt || null)
  return { appRoot, rt }
}

/**
 * @param syncProject since each root _LevelupDB can keep its own websocket alive in the background, 
 * the syncProject can be different than the current selected project
 * @returns 
 */
function getDebugInfo(syncProject?: string) {
  const { appRoot, rt } = getAppRootAndProjectRoot(syncProject)
  if (!appRoot) return { appRoot: 'NOT SET!!!' }
  if (!rt) return { rt: 'NOT SET!!!' }

  const maxSeqSynced = rt.project?.dbAcceptor?.maxSeqSynced
  const { portion, passage, passageVideo, passageGloss, passageSegment, note, 
    recording, playing, currentTime, selectionStartTime, selectionEndTime } = rt

  const debugInfo = {
    rt: null, // only set this when rt is not available, should never happen
    appRoot: null, // only set this when appRoot is not available, should never happen
    projectSelected: appRoot?.rt?.name,
    project: rt.name,
    projectDisplayName: rt.project.displayName,
    portion: portion?._id, 
    passage: passage?._id, 
    passageVideo: passageVideo?._id, 
    passageGloss: passageGloss?._id, 
    passageSegment: passageSegment?._id, 
    note: note?._id, 
    recording, 
    playing, 
    currentTime, 
    selectionStartTime, 
    selectionEndTime,
    maxSeqSynced,
    /**
     * @deprecated unsyncedDocsCount needs undefined to prevent stale rollbar data
     * We used to track unsyncedDocsCount but then we removed localSeqLast and localSeqBase
     * so we lost a convenient synchronous way to calculate.
     * If needed, we could calculate in an async caller and merge with this.
     */
    unsyncedDocsCount: undefined,
    appClient: getCurrentAppClient()
  }

  return debugInfo
}

export function setRollbarDebugInfo() {
  RollbarSingleton.configure({ payload: { custom: getDebugInfo() } })
}

/* Format message and send to central error log
 */
export function logError(err: any, message = '', preConfiguration = {}, postConfiguration = {}) {
  try {
    if (message) { message += '\n' }
    else { message = '' }

    setRollbarDebugInfo()

    log('###', message)
    RollbarSingleton.error(message + stringifyStack(err), preConfiguration, postConfiguration)
  } catch (error) {
    console.log('logError failed', error, err, message)
  }
}

export function logInfo(message: string, preConfiguration = {}, postConfiguration = {}) {
  setRollbarDebugInfo()

  log('###', message)
  RollbarSingleton.info(message, preConfiguration, postConfiguration)
}

/**
 * Display a non-serious error to the user.
 */
export function displayError(err: any, message?: string) {
  let message2 = message || stringify(err)
  console.log('<ERROR>', err, message2)

  logError(err, message)
  return throttledToastError(message2, { toastId: message2, autoClose: 20000 })
}

/**
 * Display a warning to the user, but don't send to rollbar
 */
export function userError(message: any) {
  const toastId = stringify(message)
  console.log('<USER ERROR>', toastId)
  return throttledToastError(message, { toastId, autoClose: 20000 })
}

/**
 * Report a serious error to logging system, and display a simple error message to user.
 */
export function systemError(err: any) {
  let message2 = stringify(err)
  console.log('<SYSTEM ERROR>', err, message2)

  setRollbarDebugInfo()

  RollbarSingleton.error(err)
  return throttledToastError(SYSTEM_ERROR_MESSAGE, { toastId: message2, autoClose: 20000 })
}

const SYSTEM_ERROR_MESSAGE = t`SLTT has encountered a bug. It has been reported to the developers`

export function displayInfo(message: string, helpId?: string) { 
  let _message: any = message
  if (helpId) {
    _message = (
      <span>{message}
        <a href={`https://s3.amazonaws.com/sltt-help/${helpId}`} target="_blank">
          <span className={'sl-help-icon fas fa-question-circle'} />
        </a>
      </span>
    )
  }

  console.log('<INFO>', message)
  return throttledToastInfo(_message, { toastId: message, autoClose: 60000, position: toast.POSITION.TOP_RIGHT })
}

export function displaySuccess(message: any, throttle = true) {
  const toastId = stringify(message)
  console.log('<SUCCESS>', toastId)
  const fn = throttle ? throttledToastSuccess : toast.success
  return fn(message, {
    toastId,
    className: 'sl-success-toast',
    autoClose: 15000, 
    position: toast.POSITION.TOP_RIGHT 
  })
}

export function displayWarning(message: any) {
  const toastId = stringify(message)
  console.log('<WARNING>', toastId)
  return throttledToastWarning(message, {
    toastId,
    className: 'sl-warning-toast',
    autoClose: 15000,
    position: toast.POSITION.TOP_RIGHT
  })
}

export function dismissDisplay(_id: any) {
  toast.dismiss(_id)
}

/**
 * 
 * @param message 
 * @param docs 
 * @param syncProject since each root _LevelupDB can keep its own websocket alive in the background, 
 * the syncProject can be different than the current selected project
 * @returns 
 */
export function logDocSyncError(error: Error, docs: any, syncProject: string, dbId: string,
    adviseRestart = false,
    willTryAgain = true
) {
  const { message } = error
  const { rt } = getAppRootAndProjectRoot(syncProject)
  const projectDisplayName = rt?.project.displayName
  const addedName = projectDisplayName !== syncProject ? ` (${projectDisplayName})` : ''
  console.error(`<SYNC ERROR> [${dbId}, ${syncProject}${addedName}]`, error)
  log('###', message, docs, syncProject)
  RollbarSingleton.critical(error,
    { payload: { dbId, docs: safeStableStringify(docs), custom: getDebugInfo(syncProject) } },
    { payload: { dbId: null, docs: null /* prevent stale data */ } },
  )
  const recentChangesMessage = docs.length ? ' '+  t`Your recent changes have not been shared.` : ''
  const retryMessage = willTryAgain ? ' ' + t`SLTT will try again in 5 minutes.` : ''
  const refreshPageMessage = adviseRestart ? ' ' + t`Please close and reopen SLTT, if problem persists.` : ''
  const UPSYNC_ERROR_MSG = `[${projectDisplayName}] ` + SYSTEM_ERROR_MESSAGE + '.' 
    + recentChangesMessage + retryMessage + refreshPageMessage
  return throttledToastError(UPSYNC_ERROR_MSG, { toastId: message, autoClose: 20000 })
}

/**
 * capture data right before the user might loose data (e.g. the browser refreshes)
 * @param title 
 * @param data 
 */
export function sendBeacon(title: string, data: any) {
  const fullTitle = `beacon: ${title}`
  console.log('<BEACON>', title, data)
  log('<BEACON>', title, data)
  RollbarSingleton.warning(fullTitle,
    { payload: { title: fullTitle, beacon: data, custom: getDebugInfo()} },
    { payload: { title: null, beacon: null /* prevent stale data */ } }, true)
}

const _window = window as any
// add throttedToast test by calling displayError multiple times in a row

_window.displayErrorStressTest = (count = 1, message = 'displayError stress test', identical = false) => {
  for (let i = 0; i < count; i++) {
    displayError(new Error(message + (identical ? '' : ` ${i}`)))
  }
}

_window.displayInfoStressTest = (count = 1, message = 'displayInfo stress test', identical = false) => {
  for (let i = 0; i < count; i++) {
    displayInfo(message + (identical ? '' : ` ${i}`))
  }
}

_window.displaySuccessStressTest = (count = 1, message = 'displaySuccess stress test', identical = false) => {
  for (let i = 0; i < count; i++) {
    displaySuccess(message + (identical ? '' : ` ${i}`))
  }
}

_window.systemErrorStressTest = (count = 1, message = 'systemError stress test', identical = false) => {
  for (let i = 0; i < count; i++) {
    systemError(new Error(message + (identical ? '' : ` ${i}`)))
  }
}
