import React, { Component, FC, useCallback, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import _ from 'underscore'
import { t } from "ttag";
import { useOnClickOutside } from "../utils/Hooks"

import { Passage, PassageVideo, PassageVideoReference, Project } from '../../models3/ProjectModels'
import { ReferenceInput } from "../utils/ReferenceInput"
import { FineAdjustmentTimeButtons, TrashButton } from "../utils/Buttons"
import { IVideoPosition } from './VideoPositionBar'
import { displayError } from '../utils/Errors'
import { IRoot } from '../../models3/Root';

import _debug from "debug"; let log = _debug('sltt:VerseReferenceEditor')


export async function createVerseReference(rt: IRoot) {
    let { currentTime, passage, passageVideo, iAmConsultant, project } = rt
    if (!passage || !passageVideo || !iAmConsultant) return

    let visibleReferences = passageVideo.getVisibleReferences(passage)
    let tooCloseToReference = visibleReferences.find(ref => Math.abs(currentTime - ref.time) < 0.2)
    if (tooCloseToReference) return
    
    await passageVideo.addNextReferenceInSequence(passage, currentTime)
    rt.resetCurrentTime(currentTime) // update displayed verse in timeline
}


type VerseReferenceProps = {
    videoPosition: IVideoPosition
}

export const VerseReferenceEditor: FC<VerseReferenceProps> = observer((props) => {
    let { verseReference, passage, passageVideo, resetCurrentTime } = props.videoPosition

    const [errored, setErrored] = useState(false)
    const referenceRef = useRef<any>(null)

    const [refs, setRefs] = useState(verseReference ? verseReference.references : [])

    function _close() { props.videoPosition.verseReference = undefined }

    useOnClickOutside(referenceRef, () => {
        // Originally the following lines were wrapped in a useCallback.
        // When I did that it ended up using an old value of refs, so I removed it.
        log('useOnClickOutside', errored, JSON.stringify(refs))
        !errored && verseReference?.setReferences(refs).catch(displayError)
        _close()
    })

    if (verseReference == undefined || passage === null || passageVideo === null) return null

    function _setPosition(delta: number) {
        if (verseReference == undefined) return

        let newTime = verseReference.time + delta
        if (!verseReference!.canChangePositionToTime(newTime, passageVideo!, passage!)) return

        props.videoPosition.resetCurrentTime(newTime)

        passageVideo!.saveReferencePosition(passage!, newTime, verseReference).catch(displayError)
    }

    function _save() {
        log('_save', JSON.stringify(refs))
        verseReference?.setReferences(refs).catch(displayError)
        // update current verse refs shown
        props.videoPosition.dbsRefs = refs
        _close()
    }

    async function _onEscape() {
        /*
         * PREVIOUSLY: if a reference was `newlyCreated` and user hit ESC, we'd delete it.
         * However, we removed this because it resulted in deleting wanted references.
         * Instead, the user can click the trash icon to delete a reference.
        if (verseReference?.newlyCreated) {
            await _deleteReference()
        }
        */
        _close()
    }

    async function _deleteReference() {
        let video = passage?.findVideo(verseReference!._id)
        if (video) {
            await video.removeReference(verseReference!._id)
        }
        resetCurrentTime(verseReference!.time)

        _close()
    }

    let newEarlierTime = verseReference.time - (2.0 / 30.0)
    let newLaterTime = verseReference.time + (2.0 / 30.0)
    let previousFrameEnabled = verseReference.canChangePositionToTime(newEarlierTime, passageVideo, passage)
    let nextFrameEnabled = verseReference.canChangePositionToTime(newLaterTime, passageVideo, passage)

    return (
        <div className='verse-reference-editor' ref={referenceRef}>
            <FineAdjustmentTimeButtons
                previousFrameEnabled={previousFrameEnabled}
                nextFrameEnabled={nextFrameEnabled}
                adjustCurrentTime={_setPosition}
                showShortcut={false}
            />
            <ReferenceInput
                refInput={props.videoPosition}
                refs={refs}
                setRefs={setRefs}
                errored={errored}
                setErrored={setErrored}
                onEnter={_save}
                onEscape={_onEscape}
                autoFocus
            />
            <TrashButton
                onClick={() => _deleteReference()}
                buttonClassName=''
                className='verse-reference-delete-button'
                enabled={true}
                tooltip={t`Delete verse reference`}
            />
        </div>
    )
})

