import debug from 'debug'
import { observable, makeObservable, computed } from 'mobx'
import { fmt } from './Fmt'
import { throttle } from 'underscore'

const log = debug('sltt:LocalStorage')
const dbg = debug('slttdbg:LocalStorage')

export const featureFlagKey = 'featureFlags'

const throttledLog = throttle((message: string, args?: any) => { args ? log(message, args) : log(message) }, 30000)

let previousFeatureFlag = ''

export function featureFlag(flag: string) {
    const featureFlag = localStorage.getItem(featureFlagKey) ?? ''
    
    // Log if the featureFlag has changed
    if (featureFlag !== previousFeatureFlag) {
        log('featureFlag', fmt({ featureFlag }))
        previousFeatureFlag = featureFlag
    }

    const flags = (featureFlag).split('/')
    return flags.includes(flag)
}

if (!window.matchMedia) {
    (window as any).matchMedia = () => false
}

type ThemeTypeDark = 'dark'
type ThemeTypeLight = 'light'
type ThemeType = ThemeTypeDark | ThemeTypeLight

// TODO: create useTheme()
export class Theme {
    static DARK: ThemeTypeDark = 'dark'
    static LIGHT: ThemeTypeLight = 'light'

    static defaultTheme: ThemeType = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light'
    static observableTheme: {
        theme: ThemeType | ''
    } = observable({
        theme: ''
    });

    constructor() {
        makeObservable(Theme, {
            observableTheme: observable,
            theme: computed
        });
    }

    static get theme(): ThemeType {
        return Theme.observableTheme.theme || Theme.defaultTheme;
    }
    
    static set theme( theme: ThemeType ) {
        Theme.observableTheme.theme = theme;
    }
    
    static setupTheme() {
        Theme.setTheme(localStorage.theme || Theme.defaultTheme)
        log('setupTheme', Theme.theme, { localStorageTheme: localStorage.theme, defaultTheme: Theme.defaultTheme })
    }

    static setTheme(theme: ThemeType) {
        Theme.theme = theme || Theme.defaultTheme
        localStorage.theme = theme
        document.documentElement.setAttribute('data-theme', theme)
        log('setTheme', Theme.theme, { theme, localStorageTheme: localStorage.theme })
    }

    static hasThemeSetting = () => 'theme' in localStorage

    static toggleTheme() {
        const currentTheme = localStorage.theme || Theme.theme
        const newTheme = currentTheme === 'light' ? 'dark' : 'light'
        Theme.setTheme(newTheme)
        log('toggleTheme', Theme.theme, { currentTheme, newTheme })
    }

    static getColor(colorWhenLight: string, colorWhenDark: string) {
        // log('getColor', Theme.theme, { colorWhenLight, colorWhenDark })
        return Theme.theme === 'light' ? colorWhenLight : colorWhenDark
    }

    static currentTheme() {
        return Theme.theme
    }
}

Theme.setupTheme() // do once
