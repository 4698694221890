import { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { t } from 'ttag'
import _debug from 'debug'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AppRoot, getSearchParams } from '../../models3/AppRoot'
import API from '../../models3/API'
import { LocaleSelector } from './LocaleSelector'
import { RootConsumer } from './RootContext'
import { Language, getByIso639dash1 } from '../utils/Languages'
import { Root } from '../../models3/Root'
import { SlttHelp } from '../utils/Buttons'
import { Project, ProjectMessage } from '../../models3/ProjectModels'
import _ from 'underscore'
import { HelpLink } from '../utils/Icons'
import { VideoCache } from '../../models3/VideoCache'
import { LogoutButton } from './Login'
import { ToggleThemeButton } from './ToggleThemeButton'
import { Theme } from '../utils/LocalStorage'
import { SlttHelp2 } from '../utils/SLTTHelp2'
import { ServiceStatus } from './ServiceStatus'
import { StorageLimitStatusCheck } from './StorageLimitStatus'
import { version } from '../../versionNumber'
import { DEFAULT_LOCALE, loadTranslation } from '../../models3/utils/loadTranslation'
import { LocalTeamStorageButton } from './LocalTeamStorageButton'
import './NavigationBar.css'
import { getHasElectronContext, startUdp } from '../../models3/SlttAppStorage'


const log = _debug('sltt:NavigationBar')

function unreadProjectMessageCount(rt: Root | null) {
    if (rt === null) return 0

    function unreadMessageCount(_message: ProjectMessage) {
        return (_message.creator !== rt!.username && !_message.viewed) ? 1 : 0
    }

    let count = 0
    let { messages } = rt.project
    for (let message of messages) {
        count += unreadMessageCount(message)
        for (let response of message.responses) {
            count += unreadMessageCount(response)
        }
    }

    return count
}

interface IMessageIcon {
    messageCount: number,
    onClick: () => void,
    selected: boolean,
}

const MessageIcon: FC<IMessageIcon> =
    observer(({ messageCount, onClick, selected }) => {
        return (
            <div onClick={onClick} className='message-icon-container'>
                <i
                    className={classNames("fa-comment-dots", "fas", "fa-2x", "edit-project-settings-button", "icon-hover",
                        { 'fa-border': selected })}
                >
                </i>
                {messageCount === 0 ? '' : <span className="p-n-unread-messages-flag">{messageCount}</span>}
            </div>
        )
    })

interface NavigationIconsInterface {
    appRoot: AppRoot
    selected: string
    selectPage: (page: string) => void
    uiLanguages: any[]
    setChangingLanguage: (status: boolean) => void
    messageCount: number
    isLanguageSelectorFirst?: boolean
    languageCode: string
    setLanguageCode: (languageCode: string) => void
    useMobileNavigation: any
    useMobileLayout: boolean
}
const NavigationIcons: FC<NavigationIconsInterface> = (
    { appRoot, selected, selectPage, uiLanguages, isLanguageSelectorFirst, setChangingLanguage, setLanguageCode, messageCount, languageCode, useMobileNavigation, useMobileLayout }
) => {
    let { iAmRoot, rt } = appRoot
    const hasPortions = (rt: any | null) => (rt?.project.portions.length ?? 0) > 0

    const renderLocaleSelector = () => {
        return (
            <LocaleSelector
                options={uiLanguages}
                currentValue={languageCode}
                chooseOption={async (option) => {
                    setLanguageCode(option)
                    setChangingLanguage(true)
                    await loadTranslation(option)
                    setChangingLanguage(false)
                    rt?.setLocale(option)
                }}
            />
        )
    }
    return (
        <>
            {isLanguageSelectorFirst && renderLocaleSelector()}
            <LaunchDarklyHelloWorld />
            <SlttHelp2 id="passages.html"
                video="a2"
                tooltip={useMobileLayout ? '' : /* translator: important */ t`Play/record video passages.`}
                place={`${useMobileNavigation ? "left": "bottom"}`} >
                <Link to={`/index.html`} className="app-selector-icon video-camera-button">
                    <i
                        className={classNames("fa-video", "fa", "fa-2x", "edit-passages-button", "icon-hover",
                            {
                                'fa-border': selected === '/',
                                'app-selector-disabled': !hasPortions(rt),
                            })}
                        onClick={() => { selectPage('/') }} >
                    </i>
                </Link>
            </SlttHelp2>
            <SlttHelp id="status.html" tooltip={useMobileLayout ? '' : /* translator: important */ t`Edit project status.`} place={`${useMobileNavigation ? "left" : "bottom"}`} >
                <Link to={`/status`} className="app-selector-icon">
                    <i
                        className={classNames("fa-list-ol", "fa", "fa-2x", "edit-status-button", "icon-hover",
                            {
                                'fa-border': selected === '/status',
                                'app-selector-disabled': !hasPortions(rt),
                            })}
                        onClick={() => { selectPage('/status') }} >
                    </i>
                </Link>
            </SlttHelp>

            <SlttHelp id="portions.html" tooltip={useMobileLayout ? '' : /* translator: important */ t`Edit portions.`} place={`${useMobileNavigation ? "left" : "bottom"}`} >
                <Link to={`/portions`} className="app-selector-icon edit-portions-button">
                    <i
                        className={classNames("fa-chart-pie", "fas", "fa-2x", "icon-hover",
                            { 'fa-border': selected === '/portions' })}
                        onClick={() => { selectPage('/portions') }} >
                    </i>
                </Link>
            </SlttHelp>

            <SlttHelp id="settings.html" tooltip={useMobileLayout ? '' : /* translator: important */ t`Edit project settings.`} place={`${useMobileNavigation ? "left" : "bottom"}`} >
                <Link to={`/project-settings`} className="app-selector-icon">
                    <i
                        className={classNames("fa-cog", "fas", "fa-2x", "edit-project-settings-button", "icon-hover",
                            { 'fa-border': selected === '/project-settings' })}
                        onClick={() => { selectPage('/project-settings') }} >
                    </i>
                </Link>
            </SlttHelp>

            <SlttHelp id="messages.html" tooltip={useMobileLayout ? '' : /* translator: important */ t`SLTT and Project Messages.`} place={`${useMobileNavigation ? "left" : "bottom"}`} >
                <Link to={`/messages`} className="app-selector-icon">
                    <MessageIcon
                        messageCount={messageCount}
                        selected={selected === '/messages'}
                        onClick={() => { selectPage('/messages') }}
                    />
                </Link>
            </SlttHelp>

            {/* <Link to={`${routePrefix}/`} className="app-selector-icon">
                            <i
                                className={classNames("fa-sign-out", "fa", "fa-2x")}
                                onClick={() => { 
                                    appRoot.logout()
                                    history.replace(_path('/'))
                                }}
                                data-toggle="tooltip"
                                title={t`Log out.`}>
                            </i>
                        </Link> */}

            {/* <Link to={`${routePrefix}/`} className="app-selector-icon">
                            <i
                                className={classNames("fa-bus", "fa", "fa-2x")}
                                onClick={() => {
                                    openTour && openTour()
                                }}
                                data-toggle="tooltip"
                                title={t`Start tour.`}>
                            </i>
                        </Link> */}

            {iAmRoot && !Root.screenCaptureInProgress() && <Link to={`/database`} className="app-selector-icon">
                <i
                    className={classNames("fa-database", "fas", "fa-2x", "icon-hover",
                        { 'fa-border': selected === '/database' })}
                    onClick={() => { selectPage('/database') }}
                    data-toggle="tooltip"
                    title={useMobileLayout ? '' : t`Database Records.`}>
                </i>
            </Link>}

            {iAmRoot && !Root.screenCaptureInProgress() && <Link to={`/projects`} className="app-selector-icon">
                <i
                    className={classNames("fa-server", "fas", "fa-2x", "icon-hover",
                        { 'fa-border': selected === '/projects' })}
                    onClick={() => { selectPage('/projects') }}
                    data-toggle="tooltip"
                    title={useMobileLayout ? '' : t`Projects on server.`}>
                </i>
            </Link>}

            <HelpLink className='app-selector-icon fa-2x' tooltip={t`Get help`} />
            {Theme.hasThemeSetting() && <div className="app-toggle-theme">
                <ToggleThemeButton />
            </div>}

            {!isLanguageSelectorFirst && renderLocaleSelector()}

            <div className="app-selector-icon2 fa-2x">
                <LogoutButton {...{ appRoot }} />
            </div>

        </>
    )
}

const uiLanguageCodes = ['en', 'es', 'fi', 'fr', 'it', 'ko', 'pt', 'ru', 'sk', 'uk', 'vi'] // These are iso 691-1 codes.

export const uiLanguages: Language[] = uiLanguageCodes
    .map(code => ({ code, fullName: getByIso639dash1(code).local }))
    .sort((a, b) => a.fullName.localeCompare(b.fullName))

const LaunchDarklyHelloWorld: FC = () => {
    const flags = useFlags()
    if (!flags?.helloWorld) {
        return <></>
    }
    return (<i
        className="fa fa-2x fas fa-earth-americas"
        title="Hello World: Launch Darkly is working!"
        style={{ marginLeft: '10px' }}
    />)
}
interface INavigationBar {
    appRoot: AppRoot,
    selectPage: (page: string) => void,
    selected: string,
    openTour: (() => void) | null,
    selectProject: (project: Project) => void,
    setChangingLanguage: (status: boolean) => void,
}

const NavigationBar: FC<INavigationBar> = (
    { selectPage, selected, appRoot, openTour, selectProject, setChangingLanguage }
) => {
    const flags = useFlags()
    const location = useLocation()
    const [languageCode, setLanguageCode] = useState(appRoot?.rt?.uiLanguage || DEFAULT_LOCALE)
    const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false)
    const toggleMobileMenu = () => setisMobileMenuOpen(!isMobileMenuOpen)

    const [useMobileNavigation, setUseMobileNavigation] = useState(window.innerWidth < 1400)

    useEffect(() => {
        const rtLanguageCode = appRoot?.rt?.uiLanguage
        if (rtLanguageCode && rtLanguageCode !== languageCode) {
            log('uiLanguage changed', { appRootUILanguage: rtLanguageCode, languageCode })
            setLanguageCode(rtLanguageCode || DEFAULT_LOCALE)
        }
    }, [appRoot?.rt?.uiLanguage])

    useEffect(() => {
        log('location', {
            selected, location, windowLocation: window.location,
            appRootInitialized: appRoot.initialized,
            rootPortion: appRoot?.rt?.portion?.name,
            rootPassage: appRoot?.rt?.passage?.name,
        })
        const searchParams = getSearchParams()
        searchParams.forEach((value, key) => {
            log(`searchParams: ${key}=${value}`)
            if (key === 'feature') {
                AppRoot.setFeatureFlag(value)
                return
            }
        })
        if (appRoot.initialized && !window.location.hash.startsWith(`#${location.pathname}`)) {
            // in some cases (e.g. after login), window location has is out of sync with react-router location
            // put them back in sync now
            const newTarget = window.location.origin + `/#${location.pathname}`
            log('windowLocation replaceState', { from: window.location.href, to: newTarget })
            window.history.replaceState({}, document.title, newTarget)
            log('windowLocation new state', { windowLocation: window.location.href, location, selected })
        }
        if (appRoot.initialized && !window.location.href.includes(location.search)) {
            // sometimes this can happen but I'm not sure how or why
            // with location.search: "?project=SLTT_ONBOARDING&id=240128_011135/240128_024740/240128_041559&time=0.05"
            // expected window.location.href: "https://dev.sltt-bible.net/#/index.html?project=SLTT_ONBOARDING&id=240128_011135/240128_024740/240128_041559&time=0.05"
            // but got window.location.href: "https://dev.sltt-bible.net/#/index.html"
            // not quite sure what to do about this quite yet, other than to log it
            // syncing may cause problems if it's the wrong context to restore the search parameters
            // e.g. ?project=OtherThanCurrentProject
            log('location.search out of sync with window.location.href', location.search, window.location.href)
        }
        if (appRoot.initialized && location.pathname !== selected) {
            // the location has changed, but the selected page has not been updated
            // this can happen when the user presses the back button
            log('selecting page', { location, selected })
            selectPage(location.pathname)
        }

        log('useMobileLayout', useMobileLayout)
        log('useNarrowWidthLayout', useNarrowWidthLayout)
        log('useMobileNavigation', useMobileNavigation)

    }, [location, selected, appRoot.initialized, appRoot?.rt?.passage])

    useEffect(() => {
        const mobileNavigationListener = window.matchMedia('(max-width: 1400px)')
        const handleMobileNavigationChange = (e: MediaQueryListEvent) => {
            setUseMobileNavigation(e.matches)
        }

        setUseMobileNavigation(mobileNavigationListener.matches)
        mobileNavigationListener.addEventListener('change', handleMobileNavigationChange)

        return () => {
            mobileNavigationListener.removeEventListener('change', handleMobileNavigationChange)
        }
    }, [])

    const hasElectronContext = getHasElectronContext()
    const enableLocalTeamStorage = Boolean(hasElectronContext && (flags?.localTeamStorage || flags?.localTeamStorage === undefined /* offline */))

    useEffect(() => {
        if (enableLocalTeamStorage) {
            startUdp('NavigationBar')
        }
    }, [enableLocalTeamStorage])

    // console.log('NavigationBar', { selected })
    let { iAmRoot, iAmDeveloper, id_token, username, useMobileLayout, useNarrowWidthLayout, rts, rt } = appRoot

    // let isProductionUrl = import.meta.env.BASE_URL === 'https://sltt-bible.net'

    let hasPortions = (rt: Root | null) => (rt?.project.portions.length ?? 0) > 0

    let projects = rts.map(rt => rt.project)

    let messageCount = unreadProjectMessageCount(rt)
    const rewindToKey = localStorage.acceptLocalThruKey
    const projectSelectorEnabled = rt?.canSwitchProject ?? true
    const altTitle = (rewindToKey || API.blockServerUpdates()) ? rt?.project?.dbAcceptor && (`${rt?.project?.dbAcceptor?.maxSeqSynced}/${rt?.project?.db.getRemoteSeq()}`) || '...' : undefined
    const appTitle = altTitle ?? (useMobileNavigation ? t`SLTT (${version})` : /* translator: important */ t`Sign Language Translation Tool (${version})`)
    const basicTitleTooltip = `v${version}/${username}`
    const titleTooltip = altTitle && `${basicTitleTooltip} - API.blockServerUpdates() true` || basicTitleTooltip
    return (
        <nav className="navbar navbar-default app-navbar-bottom-margin">
            <div className='navbar-left'>
                <div className="app-team-storage-status">
                    {enableLocalTeamStorage && rt && <LocalTeamStorageButton
                        appRoot={appRoot}
                        project={rt.project.name}
                        userEmail={rt.username}
                        iAmAdmin={rt.iAmAdmin}
                        projectAdmins={rt.project.members.filter(m => m.role === 'admin').map(m => m.email)}
                        projects={projects.map(p => p.name)}
                    />}
                </div>
                <div className="app-service-status">
                    <ServiceStatus />
                </div>
                <div className="navbar-header-title navbar-header navbar-brand navbar-padding navigation-bar-content"
                    style={altTitle ? { color: 'var(--color-warning)' } : undefined}
                    data-toggle="tooltip"
                    title={titleTooltip}>
                    {appTitle}
                    {rt && <UploadCount />}
                </div>
                <div className="app-storage-limit-status">
                    <StorageLimitStatusCheck />
                </div>
                {rt && <ProjectSelector {... { projects, project: rt.project, selectProject, enabled: projectSelectorEnabled }} />}
            </div>
            {id_token &&
                <div className="navbar-header-icons navbar-header">
                    {!useMobileLayout && !useMobileNavigation && (
                        <>
                            <NavigationIcons
                                appRoot={appRoot}
                                selected={selected}
                                selectPage={selectPage}
                                uiLanguages={uiLanguages}
                                setChangingLanguage={setChangingLanguage}
                                messageCount={messageCount}
                                languageCode={languageCode}
                                setLanguageCode={setLanguageCode}
                                useMobileNavigation={useMobileNavigation}
                                useMobileLayout={false}
                            />
                        </>
                    )}

                    {/* Mobile Menu Icon */}
                    {(useMobileLayout || useMobileNavigation) && (
                        <div className="app-selector-icon2">
                            <button
                                className="mobile-menu-icon icon-hover"
                                onClick={toggleMobileMenu}
                            >
                                <span
                                    className="fa fa-bars"
                                    data-id="mobile-hamburger-menu"
                                    data-toggle="tooltip"
                                    title="Expand Menu"
                                ></span>
                            </button>
                        </div>
                    )}

                    {/* Sliding Menu Overlay */}
                    {(useMobileLayout || useMobileNavigation) && (
                        <div className={`mobile-menu-overlay ${isMobileMenuOpen ? 'open' : ''}`}>
                            <div className="mobile-menu-content">
                                {/* Close Button */}
                                <button
                                    className="mobile-menu-close-button wraparound-button"
                                    data-toggle="tooltip"
                                    title="Close"
                                    onClick={toggleMobileMenu}
                                >
                                    <span className="note-dialog-close-button sl-fa-button far fa-window-close"></span>
                                </button>

                                <NavigationIcons
                                    isLanguageSelectorFirst={true}
                                    languageCode={languageCode}
                                    setLanguageCode={setLanguageCode}
                                    selected={selected}
                                    appRoot={appRoot}
                                    selectPage={(page) => {
                                        selectPage(page)
                                        toggleMobileMenu()
                                    }}
                                    uiLanguages={uiLanguages}
                                    setChangingLanguage={(status) => {
                                        setChangingLanguage(status)
                                        toggleMobileMenu()
                                    }}
                                    messageCount={messageCount}
                                    useMobileNavigation={useMobileNavigation}
                                    useMobileLayout={useMobileLayout}
                                />
                            </div>
                        </div>
                    )}
                </div>
            }
        </nav>
    )
}

type ProjectNameProps = {
    name: string,
    displayName: string,
    description: string,
}

const ProjectName: FC<ProjectNameProps> = ({ name, displayName, description }) => {
    // Display up to the first 30 characters of the first line of
    // the description. If a period is reached before that, display
    // only up to the period.
    const MAX_DESCRIPTION_LENGTH = 30
    
    let element = document.createElement('div')
    element.innerHTML = description
    let firstChild = element.childNodes[0]
    let textContent = firstChild?.textContent
    let innerText = textContent?.slice(0, MAX_DESCRIPTION_LENGTH).trim() || ''
    let indexOfPeriod = innerText.indexOf('.')
    
    // '.' doesn't exist in description. Make it
    // larger than the max index we want so that it does
    // not affect our index calculation
    if (indexOfPeriod < 0) {
        indexOfPeriod = MAX_DESCRIPTION_LENGTH
    }
    
    let index = Math.min(indexOfPeriod, MAX_DESCRIPTION_LENGTH - 1)
    let displayedDescription = innerText.slice(0, index + 1).trim()
    if (innerText.length > displayedDescription.length) {
        displayedDescription = displayedDescription.concat('...')
    }
    let displayedOption = displayName
    if (displayedDescription !== '') {
        displayedOption = `${displayName} / ${displayedDescription}`
    }
    element.remove()

    return (
        <option key={name} value={name}>{displayedOption}</option>
    )
}


type ProjectSelectorProps = {
    project: Project,
    projects: Project[],
    selectProject: (project: Project) => void,
    enabled: boolean,
}

const ProjectSelector: FC<ProjectSelectorProps> = observer(({ project: currentProject, projects, selectProject, enabled }) => {
    function chooseOption(name: string) {
        let option = projects.find(v => v.name === name) || currentProject
        selectProject(option)
    }

    let { name } = currentProject
    projects = _.sortBy(projects, project => project.displayName || project.name)

    if (projects.length > 1) {
        return (
            <div className='project-selector-dropdown'>
                <div>
                    <select
                        className='custom-select'
                        value={name}
                        onChange={e => chooseOption(e.target.value)}
                        disabled={!enabled}
                    >
                        {projects.map(project =>
                            <ProjectName
                                key={project.name}
                                name={project.name}
                                displayName={project.displayName}
                                description={project.description} />)}
                    </select>
                </div>
            </div>
        )
    }

    return (
        <div className='project-selector-dropdown'>
            <div>
                <ProjectName
                    name={currentProject.name}
                    displayName={currentProject.displayName}
                    description={currentProject.description} />
            </div>
        </div>
    )
})

const UploadCount: FC = observer(() => {
    let uploadCount = VideoCache.videoCacheUploader.uploadCount
    if (uploadCount == 0) return null

    return (
        <div className="upload-count">
            <span
                className={`fas fa-cloud-upload-alt upload-count-icon`}
                data-toggle="tooltip"
                title={/* translator: important */ t`Number of video blocks waiting to upload.`} />
            <span className="upload-count-value">{uploadCount}</span>
        </div>
    )
})


export default observer(NavigationBar)
